import { atom } from "recoil";
import DateHelper from "./dateHelper";

const localStorageEffect = key => ({ setSelf, onSet }) => {
    let savedValue = localStorage.getItem(key);

    if (savedValue != null) {
        console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Local data found for ${key}`)
        if (key === "isStaffState" && savedValue === "undefined") savedValue = false;
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        try {
            localStorage.setItem(key, JSON.stringify(newValue));
            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Local data set for ${key}`)
        }
        catch (error) {
            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} ${error}`);
        }
    });
};

const sessionStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);

    if (savedValue != null) {
        console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Session data found for ${key}`)
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        try {
            sessionStorage.setItem(key, JSON.stringify(newValue));
            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Session data set for ${key}`);
        } catch (error) {
            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} ${error}`);
        }
    });
};

export const accessTokenState = atom(
    {
        key: "accessTokenState",
        default: {
            token: null,
            expires: new Date(0),
            effects_UNSTABLE: [sessionStorageEffect("accessTokenState")]
        }
    }
);

export const accountBalanceState = atom({
    key: "accountBalance",
    default: 0,
    effects_UNSTABLE: [sessionStorageEffect("accountBalance")],
});

export const accountState = atom(
    {
        key: "accountState",
        default: null,
        effects_UNSTABLE: [sessionStorageEffect("accountState")]
    }
);

export const accountTransactionsState = atom(
    {
        key: "accountTransactionsState",
        default: null,
        effects_UNSTABLE: [sessionStorageEffect("accountTransactionsState")]
    });

export const billingRowsState = atom(
    {
        key: "billingRowsState",
        default: 10,
        effects_UNSTABLE: [localStorageEffect("billingRows")]
    });

export const codListState = atom({
    key: "codList",
    default: [],
    effects_UNSTABLE: [sessionStorageEffect("codList")],
});

export const controlState = atom(
    {
        key: "controlState",
        default: "md"
    }
);

export const currentPage = atom(
    {
        key: "currentPage",
        default: "Dashboard"
    }
);

export const isStaffState = atom(
    {
        key: "isStaffState",
        default: false,
        effects_UNSTABLE: [localStorageEffect("isStaffState")]
    }
)

export const menuState = atom(
    {
        key: "menuState",
        default: "expanded"
    }
);

export const pageErrors = atom(
    {
        key: "pageErrors",
        default: null
    }
)

export const portalHistoryState = atom(
    {
        key: "portalHistoryState",
        default: null,
        effects_UNSTABLE: [sessionStorageEffect("portalHistoryState")]
    }
)

export const scheduledJobsState = atom(
    {
        key: "scheduledJobsState",
        default: [],
        effects_UNSTABLE: [sessionStorageEffect("scheduledJobsState")]
    });

export const serviceHistoryState = atom(
    {
        key: "serviceHistoryState",
        default: null,
        effects_UNSTABLE: [sessionStorageEffect("serviceHistoryState")]
    });

export const serviceRowsState = atom(
    {
        key: "serviceRowsState",
        default: 10,
        effects_UNSTABLE: [localStorageEffect("serviceRows")]
    });

export const userState = atom(
    {
        key: "userState",
        default: null,
        effects_UNSTABLE: [sessionStorageEffect("userState")]
    }
);
