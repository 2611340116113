import React, { useState, useEffect } from 'react';
import { Prompt, useLocation, useHistory } from 'react-router-dom';
import ModalPrompt from '../components/common/ModalPrompt.Component';


const useUnsavedChangesWarning = (message = "Are you sure you want to leave the page? Any unsaved changes will be discarded.") => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);

    const location = useLocation();
    const history = useHistory();
    const [lastLocation, setLastLocation] = useState(location);
    const [shouldUnload, setShouldUnload] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            setShouldUnload(true);
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, history]);

    useEffect(() => {
        const unload = (event) => {
            if (isBlocked && !shouldUnload) {
                event.returnValue = message;
            }
            if (shouldUnload) {
                event.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", unload);

        return () => window.removeEventListener("beforeunload", unload);
    }, [isBlocked, message, shouldUnload]);

    const closeModal = () => {
        setIsModalOpen(false);
        setShouldUnload(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const showModal = (nextLocation) => {
        openModal();
        setLastLocation(nextLocation);
    };

    const handleBlockedRoute = (nextLocation) => {
        if (!confirmedNavigation && isBlocked) {
            showModal(nextLocation);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = () => {
        closeModal();
        setConfirmedNavigation(true);
    };

    const routerPrompt = (<> <Prompt when message={handleBlockedRoute} />
        <ModalPrompt modalTitle='Leave Page' modalMessage={message} isDisplayed={isModalOpen} handleYes={() => handleConfirmNavigationClick()} handleNo={() => closeModal()} />
    </>);

    return [routerPrompt, () => setIsBlocked(true), () => setIsBlocked(false)];
}

export default useUnsavedChangesWarning;
