class CurrencyHelper {
    static decimalFormat = {
        style: "decimal",
        currency: "AUD",
        maximumFractionDigits: "2",
        minimumFractionDigits: "2"
    }

    static currencyFormat = {
        style: "currency",
        currency: "AUD",
        maximumFractionDigits: "2",
        minimumFractionDigits: "2"
    }

    static formatCurrency(value) {
        if (value < 0) {
            return `-$${(value * -1).toFixed(2)}`;
        }
        else {
            return `$${value.toFixed(2)}`;
        }
    }

    static toDecimalFormat(value) {
        return value.toLocaleString("en-AU", this.decimalFormat)
    }

    static toCurrencyFormat(value) {
        return value.toLocaleString("en-AU", this.currencyFormat)
    }

    static toNumber(value) {
        // Remove any dollar signs and commas from an existing currency value...
        value = value.replace(/,/g, "");
        value = value.replace(/\$/g, "");

        if (!!isNaN(value))
            return 0;
        else
            return Number(value);
    }
}

export default CurrencyHelper;