import React from "react";
import parse from "html-react-parser";

export default class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
      this.state = { hasError: false, error: null, errorInfo: null, location: props.location };
   }

   componentDidCatch(error, info) {
      // Display fallback UI
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
      // TODO 2022-08-02: Log the error (unless the server is down) and get a TraceId to display in the error...

      this.setState({
         hasError: true,
         error: error,
         errorInfo: info
      });
   }

   componentDidUpdate(prevProps) {
      if (prevProps.location !== this.props.location) {
         this.setState({
            hasError: false
         });
      }
   }

   //TODO 2022-08-02: Let's render something that looks a little better for the the user (and make the text a little less formal)...
   render() {
      return (
         <>
            {this.state.hasError && (
               <>
                  <div className="container text-center">
                     <h2 style={{ color: "red" }}>Error Loading Page</h2>
                     <div className="alert alert-danger">{parse("We encountered an error loading the requested page.<br/><br/>Please try refreshing the page and if the error persists contact our Customer Service team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339) for assistance.")}</div>
                  </div>
               </>
            )}
            {!this.state.hasError && this.props.children}
         </>
      );
   }
}