import moment from "moment";

export const transformBillingData = (billingData) => {
    const accountTransactions = { accountBalance: 0, transactions: billingData };

    if (accountTransactions?.transactions.length) {
        accountTransactions.transactions = accountTransactions.transactions.sort((a, b) => {
            return moment(a.transactionDate).diff(b.transactionDate) * -1;
        });

        accountTransactions.transactions = accountTransactions.transactions.map((invoice) => {
            // If a payment has been submitted via the portal (pending) then the balance is equal to the invoice,
            // otherwise calculate the invoice balance from the invoiced amount and the amount paid...
            invoice.balance = invoice.paymentStatus === "Pending" ? invoice.amount : invoice.amount - (invoice.amountPaid || 0);

            switch (invoice.paymentStatus) {
                case "Paid":
                    invoice.paymentStatusDisplay = "Payment";
                    break;
                case "Unpaid":
                    invoice.paymentStatusDisplay = "Invoice";
                    break;
                default:
                    invoice.paymentStatusDisplay = invoice.paymentStatus;
            }

            return invoice;
        });

        // Pending payments are not included when calculating the total account balance...
        const transactionsBalance = accountTransactions.transactions.filter((x) => x.amount > 0 && x.paymentStatus !== "Pending");
        accountTransactions.accountBalance = transactionsBalance.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0);

        // Limit number of records because session storage is limited to 5MB (allowing 500kb per record)...
        if (accountTransactions.transactions.length > 8000) {
            //filter past 12 months based on latest transaction
            const latestTransaction = accountTransactions.transactions.reduce((a, b) => (a.transactionDate > b.transactionDate ? a : b));

            const startDate = new Date(latestTransaction.transactionDate);
            startDate.setFullYear(startDate.getFullYear() - 1);

            const filteredTransactions = accountTransactions.transactions.filter((x) => new Date(x.transactionDate) >= startDate).splice(0, 8000);
            accountTransactions.transactions = filteredTransactions;
        }
    }
    
    return accountTransactions;
}

export const transformSalesOrders = (salesOrdersData) => {
    if (salesOrdersData && salesOrdersData.length > 0) {

        salesOrdersData = salesOrdersData.sort((a, b) => {
            return moment(a.recordDate).diff(b.recordDate) * -1;
        })

        let id = 0;
        salesOrdersData = salesOrdersData.map((salesOrder) => {
            salesOrder.id = ++id;

            salesOrder.displayStatus = salesOrder.status;
            switch (salesOrder.status) {
                case "Pending":
                case "Booked":
                    salesOrder.displayStatus = "Scheduled";
                    break;
                case "Finalized":
                    salesOrder.displayStatus = "Completed";
                    break;
                default:
                    break;
            }

            salesOrder.description = "";

            if (salesOrder.orderDetails.length === 1) {
                salesOrder.description = salesOrder.orderDetails[0].productDescription;
            }
            else if (salesOrder.orderDetails.length > 1) {
                salesOrder.description = "Multiple Services"
            }

            return salesOrder;
        });
    }
    return salesOrdersData;

}