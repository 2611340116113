import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import SiteContainer from "../components/common/SiteContainer.Component"
import ErrorBoundary from "../components/common/ErrorBoundary.Component"

export const PrivateRoute = ({ component, ...rest }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const renderFn = Component => props => {
        if (!!Component && isAuthenticated) {
            // If the user has been authenticated...
            return (
                <SiteContainer>
                    <ErrorBoundary location={props.location}>
                        <Component {...props} />
                    </ErrorBoundary>
                </SiteContainer>
            );
        }
        else if(isAuthenticated){
            //Local Storage doesn't need to be cleared, no sensitive data stored.
            instance.logoutRedirect();
            const isStaffState = localStorage.getItem("isStaffState");
            if (!isStaffState) {
                localStorage.setItem("isStaffState", false);
            }
            sessionStorage.clear();
            return null;
        }
    };
    
    // Bail if an authentication process is running...
    if (inProgress !== InteractionStatus.None) return null;
    
    // Render the component if authenticated otherwise redirect to login...
    if (!isAuthenticated) {
        return <Redirect to="/" />
    } else {
        return <Route {...rest} render={renderFn(component)} />;
    }
};
