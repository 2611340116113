import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, Row, Col } from "reactstrap";

import DataTable from "react-data-table-component";
import moment from "moment";

import { useRecoilState } from "recoil";
import { serviceRowsState } from "../../Shared/atom";

import currencyHelper from "../../Shared/currencyHelper";

const ServiceScheduleTable = ({ jobs, triggerReport }) => {

    // -- Global State ---------------------------------------------------------------------------------------------------------------------
    const [rowsPerPage, rowsPerPageSet] = useRecoilState(serviceRowsState);

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    const columns = [
        {
            name: "Date",
            selector: row => row.recordDate,
            sortable: true,
            cell: (row) => <span>{moment(row.recordDate).format("ddd, D MMM YYYY")}</span>
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true
        },
        {
            name: "Requested By",
            selector: row => row.requestedBy,
            sortable: true
        },
        {
            // row.orderDetails[0] does not contain any data???
            name: "Description",
            selector: row => row.orderDetails.length === 0 ? "" : row.orderDetails.length > 1 ? "Multiple Services" : row.orderDetails[0].productDescription,
            sortable: true
        },
        {
            name: "Total (ex GST)",
            selector: row => row.jobTotalExTax,
            sortable: true,
            cell: (row) => <span>{currencyHelper.toCurrencyFormat(row.jobTotalExTax)}</span>,
            right: true
        },
        {
            name: "Actions",
            sortable: false,
            width: "100px",
            cell: (row) => {
                return <div className="d-flex align-items-center justify-content-end">
                    {(row.status === "Invoiced" || row.status === "Completed") &&
                        <Fragment>
                            {row.hasInvoice &&
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-sm ms-1"
                                        title="Download the Job Docket for the selected service"
                                        onClick={() => triggerReport("JobDocketReport", [row.salesOrderId], row.recordDate)}>
                                        <FontAwesomeIcon icon="clipboard" />
                                    </button>
                                </div>
                            }

                            {row.hasCod &&
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm ms-1"
                                        title="Download the Certificate of Destruction"
                                        onClick={() => triggerReport("CodReport", [row.salesOrderId], row.recordDate)}>
                                        <FontAwesomeIcon icon="file-alt" />
                                    </button>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            },
        }
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.status === "Pending",
            style: { backgroundColor: "#99e1a9" },
        },
    ];

    const ScheduleExpandedComponent = ({ data }) => {
        return (
            <div className="mx-5 my-2" style={{ backgroundColor: "#f5e5bc", padding: "5px 10px" }}>
                {data?.orderDetails?.length === 0 &&
                    <Row className=" pt-2">
                        <Col>
                            <Label className="form-control">There were no service records for this job.</Label>
                        </Col>
                    </Row>
                }

                {data?.orderDetails?.length > 0 &&
                    data.orderDetails.map((orderDetail, idx) => {
                        const labelClass = (idx === 0 ? "" : " d-xs-block d-lg-none");
                        return (
                            <Row key={idx}>
                                <Col xs={12} lg={6}>
                                    <Label className={labelClass}>Product Description</Label>
                                    <Label className="form-control">{orderDetail.productDescription}</Label>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Row>
                                        <Col className="text-right">
                                            <Label className={labelClass}>Quantity</Label>
                                            <Label className="form-control">{orderDetail.quantity}</Label>
                                        </Col>
                                        <Col className="text-right">
                                            <Label className={labelClass}>Cost (ex GST)</Label>
                                            <Label className="form-control">{currencyHelper.toCurrencyFormat(orderDetail.billingPrice)}</Label>
                                        </Col>
                                        <Col className="text-right">
                                            <Label className={labelClass}>Total (ex GST)</Label>
                                            <Label className="form-control">{currencyHelper.toCurrencyFormat(orderDetail.quantity * orderDetail.billingPrice)}</Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    })}
            </div>
        );
    };

    return (
        <div>
            <DataTable
                noHeader
                striped

                highlightOnHover={true}
                columns={columns}
                conditionalRowStyles={conditionalRowStyles}
                data={[...jobs]}

                defaultSortAsc={false}
                defaultSortField="recordDate"

                pagination
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}

                expandableRows
                expandableRowsComponent={ScheduleExpandedComponent}
                expandableRowDisabled={(row) => !row.orderDetails}

                onChangeRowsPerPage={(val) => rowsPerPageSet(val)} />
        </div>
    );
};

export default ServiceScheduleTable;
