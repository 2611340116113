import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";

import { billingRowsState } from "../../Shared/atom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState } from "recoil";

import currencyHelper from "../../Shared/currencyHelper";

import styles from "./BillingDataTable.module.scss"

const BillingDataTable = ({ transactions, handlePayInvoices, handleOptionsShow }) => {
    const [rowsPerPage, rowsPerPageSet] = useRecoilState(billingRowsState);
    const today = new Date();

    const setTableRows = (rows) => {
        rowsPerPageSet(rows);
    };

    const columns =
        [
            {
                name: "Invoice Number",
                selector: row => row.invoiceNumber,
                sortable: true
            },
            {
                name: "Date",
                selector: row => row.transactionDate,
                sortable: true,
                cell: (row) => <span>{moment(row.transactionDate).format("ddd, D MMM YYYY")}</span>
            },
            {
                name: "Due Date",
                selector: row => row.dueDate,
                sortable: true,
                cell: (row) => <span>{((row.transactionType === 2 || row.transactionType === 8) && row.amount > 0) &&
                    moment(row.dueDate).format("ddd, D MMM YYYY")
                }</span>
            },
            {
                name: "Amount",
                selector: row => row.amount,
                sortable: true,
                cell: (row) => <span>{currencyHelper.toCurrencyFormat(row.amount)}</span>,
                right: true
            },
            {
                name: "Date Paid",
                selector: row => row.datePaid,
                sortable: true,
                cell: (row) => (<span>{((row.transactionType === 2 || row.transactionType === 8) && row.amountPaid > 0) && row.paymentStatus !== "Pending" && row.datePaid &&
                    moment(row.datePaid).format("ddd, D MMM YYYY")}
                    {row.paymentStatus === "Pending" && moment(row.datePaid).format("ddd, D MMM YYYY")}
                </span>)
            },
            {
                name: "Amount Paid",
                selector: row => row.amountPaid,
                sortable: true,
                cell: (row) => (<span>{row.amountPaid > 0 && row.paymentStatus !== "Pending" && currencyHelper.toCurrencyFormat(row.amountPaid)}
                    {row.paymentStatus === "Pending" && currencyHelper.toCurrencyFormat(row.amount)}
                </span>),
                right: true
            },
            {
                name: "Reference",
                selector: row => row.paymentReference,
                sortable: true
            },
            {
                name: "Balance",
                selector: row => row.balance,
                sortable: true,
                cell: (row) => (<span>{currencyHelper.toCurrencyFormat(row.balance)}</span>),
                right: true
            },
            {
                name: "Pay",
                selector: row => row.amount,
                cell: (row) => (
                    <div title={row.paymentStatus === "Unpaid" ? "Pay this invoice" : row.paymentStatus === "Pending" ? "Pending payment" : "Invoice paid"}>
                        <button
                            type="button"
                            className={`btn ${row.paymentStatus === "Pending" ? "btn-warning" : "btn-success"} btn-sm`}
                            onClick={() => handlePayInvoices([row])}>
                            <FontAwesomeIcon icon="dollar-sign" />
                        </button>
                    </div>
                ),
                center: true
            },
            {
                name: "",
                cell: (row) => <span>{((row.transactionType === 2 || row.transactionType === 8) && row.amount > 0) &&
                    <div title={row.hasInvoicePdf ? "Download or email the invoice" : "PDF invoice unavailable"}>
                        <button
                            type="button"
                            className={row.hasInvoicePdf ? "btn btn-sm btn-primary" : "btn btn-sm btn-secondary"}
                            disabled={!row.hasInvoicePdf}
                            onClick={() => handleOptionsShow(row.invoiceNumber, row.hasInvoicePdf)}>
                            Invoice
                        </button>
                    </div>
                }
                </span>,
                center: true
            }
        ];

    const conditionalRowStyles = [
        {
            when: (row) => moment(row.dueDate, "YYYY-MM-DD").isBefore(today) && row.balance > 0,
            style: { backgroundColor: "rgba(250, 0, 0, 0.25)" }
        },
        {
            when: (row) => row.paymentStatus === "Pending",
            style: { backgroundColor: "palegoldenrod" }
        }
    ];

    return (
        <div className={styles["billing-datatable"]}>
            <DataTable
                noHeader
                striped

                columns={columns}
                conditionalRowStyles={conditionalRowStyles}
                data={transactions}
                highlightOnHover={true}

                pagination
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}

                onChangeRowsPerPage={(val) => setTableRows(val)} />
        </div>
    );
};

export default BillingDataTable;
