import config from "../app.config";

// -- Application EndPoints ---------------------------------------------------------------------------------------------------------------
const appUrl = config.apiEndpoint;

const accountUrl = `${appUrl}/Account`;
const auditUrl = `${appUrl}/Audit`;
const axUrl = `${appUrl}/Ax`;
const emailUrl = `${appUrl}/Email`;
const jobUrl = `${appUrl}/Job`;
const paymentUrl = `${appUrl}/Payment`;
const portalUrl = `${appUrl}/PortalHistory`;
const reportUrl = `${appUrl}/Reporting`;
const serviceUrl = `${appUrl}/Service`;

// -- ABN Endpoints ----------------------------------------------------------------------------------------------------------------------
export const abrSearch = `https://abr.business.gov.au/json/AbnDetails.aspx?callback=callback&guid=${process.env.REACT_APP_ABR_KEY}`

// -- Account Endpoints -------------------------------------------------------------------------------------------------------------------
export const GetAccount = accountUrl;
export const GetIndustryCodes = `${accountUrl}/GetIndustryCodes`;
export const GetUser = `${accountUrl}/GetUser`;
export const LogTransaction = `${accountUrl}/LogTransaction`;
export const SwitchAccount = `${accountUrl}/SwitchAccount`
export const UpdateAccount = `${accountUrl}/UpdateAccount`;
export const DownloadInvoice = `${accountUrl}/DownloadInvoice`;

// -- Audit Endpoints ---------------------------------------------------------------------------------------------------------------------
export const AddAuditEntry = `${auditUrl}/LogEntry`;

// -- AX Endpoints ------------------------------------------------------------------------------------------------------------------------
export const GetTransactions = `${axUrl}/GetTransactions`;
export const GetInvoiceCount = `${axUrl}/GetInvoiceCount/<AccountNumber>`;
export const GetInvoiceList = `${axUrl}/GetInvoiceList`;

// -- Email Endpoints ----------------------------------------------------------------------------------------------------------------------
export const Email = `${emailUrl}/SendGridEmail`;
export const SendGridEmail = `${emailUrl}/SendGridEmail`;

// -- Job Endpoints -----------------------------------------------------------------------------------------------------------------------
export const GetScheduledJobs = `${jobUrl}/GetScheduledJobs`
export const GetServiceHistory = `${jobUrl}/GetServiceHistory`

// -- Payment Endpoints -------------------------------------------------------------------------------------------------------------------
export const InvoicePayment = `${paymentUrl}/InvoicePayment`;
export const SubmitPendingPayment = `${paymentUrl}/SubmitPendingPayment`

// -- PortalHistory Endpoints -------------------------------------------------------------------------------------------------------------
export const GetPortalHistory = `${portalUrl}/GetPortalHistory`
export const SubmitPortalHistory = `${portalUrl}/SubmitPortalHistory`;

// -- Registration EndPoints --------------------------------------------------------------------------------------------------------------
export const VerifyRegistrationLink = `${appUrl}/Registration/VerifyRegistrationLink`;
export const RegisterUser = `${appUrl}/Registration/RegisterUser`;
export const ResendRegistrationEmail = `${appUrl}/Registration/ResendRegistrationEmail`;

// -- Report Endpoints --------------------------------------------------------------------------------------------------------------------
export const GetCodList = `${reportUrl}/GetCodList`;
export const CodReport = `${reportUrl}/GetCodReport`;
export const EcoReport = `${reportUrl}/GetEcoStatement`;
export const JobReport = `${reportUrl}/GetJobReport`;

// -- Service Endpoints -------------------------------------------------------------------------------------------------------------------
export const GetClosedDates = serviceUrl;
export const RestoreClosedDates = `${serviceUrl}/RestoreClosedDates`;
export const SaveClosedDates = `${serviceUrl}/SaveCloseDates`;
