//cspell:disable
export default {
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
    returnUrl: process.env.REACT_APP_RETURN_URL,
    logoutUrl: process.env.REACT_APP_LOGOUT_URL,
    
    environment: process.env.REACT_APP_ENVIRONMENT,
    staffPath: process.env.REACT_APP_STAFF_PATH,
    version: process.env.REACT_APP_VERSION,

    msalB2cApiId: process.env.REACT_APP_MSAL_API_ID,
    msalB2cApiName: process.env.REACT_APP_MSAL_API_NAME,
    msalB2cAuthority: process.env.REACT_APP_MSAL_B2C_AUTHORITY,
    msalB2cAuthorityDomain: process.env.REACT_APP_MSAL_B2C_AUTHORITY_DOMAIN,
    msalB2cClientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    msalB2cClientName: process.env.REACT_APP_MSAL_CLIENT_NAME,
    msalB2cTokenScopeUrl: process.env.REACT_APP_MSAL_TOKEN_SCOPE_URL,

    msalB2cResetPasswordFlow: process.env.REACT_APP_MSAL_RESET_PASSWORD_FLOW,
    msalB2cSignInFlow: process.env.REACT_APP_MSAL_SIGN_IN_USER_FLOW,
    msalB2cSignInStaffFlow: process.env.REACT_APP_MSAL_SIGN_IN_STAFF_FLOW,

    msalUserApi: process.env.REACT_APP_MSAL_USER_API,
    msalStaffApi: process.env.REACT_APP_MSAL_STAFF_API,
}
