import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Input from "../common/Input.Component";
import emailHelper from "../../Shared/emailHelper";

const InvoiceOptions = ({ showInvoiceOptions, closeOptions, invoiceNumber, ...args }) => {
    // -- Page State ----------------------------------------------------------------------------------------------------------------------
    const [canContinue, canContinueSet] = useState(false);
    const [controlSize] = useState(args?.controlSize ?? "md");
    const [downloadPdf, downloadPdfSet] = useState(false);
    const [emailAddress, emailAddressSet] = useState(null);
    const [emailPdf, emailPdfSet] = useState(false);
    const [isEmailValid, isEmailValidSet] = useState(null);
    const [showEmailError, showEmailErrorSet] = useState(false);

    // -- State Events --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        // If the download and/or email options have been selected and the email address is valid...
        if (downloadPdf || (!emailPdf || (emailPdf && emailAddress && isEmailValid))){
            canContinueSet(true);
        }
        else {
            canContinueSet(false);
        }
    }, [downloadPdf, emailAddress, emailPdf, isEmailValid])

    // -- Form Methods --------------------------------------------------------------------------------------------------------------------
    const handleChange = (evt) => {
        const tgt = evt.target;
        const value = tgt.value.trim();

        if (tgt.id === "emailAddress"){
            emailAddressSet(value);
            validateEmail(value);
            showEmailErrorSet(false);
        }
    }

    const handleContinue = () => {
        const invoiceOptions = { emailAddress: emailAddress, download: { pdf: downloadPdf, csv: false }, email: { pdf: emailPdf, csv: false } };
        closeOptions(invoiceOptions);
    }

    const setEmailError = () => {
        showEmailErrorSet(!emailHelper.isValidEmail(emailAddress));
    }

    const validateEmail = (email) => {
        const isValid = emailHelper.isValidEmail(email);
        isEmailValidSet(isValid);
    }

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    return (
        <Modal backdrop="static" isOpen={showInvoiceOptions} toggle={closeOptions}>
            <ModalHeader toggle={closeOptions}>{`Invoice - ${invoiceNumber}`}</ModalHeader>

            <ModalBody>
                <div style={{ minHeight: "200px" }}>
                    <p style={{ marginTop: "10px", marginBottom: "5px", fontSize: "1.15rem", fontWeight: "600" }}>Options:</p>

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input me-2" id="download.pdf" onChange={() => downloadPdfSet(!downloadPdf)} checked={downloadPdf} />
                        <label className="custom-control-label" htmlFor="download.pdf" style={{ fontWeight: "400" }}>Download PDF</label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input me-2" id="email.pdf" onChange={() => emailPdfSet(!emailPdf)} checked={emailPdf} />
                        <label className="custom-control-label" htmlFor="email.pdf" style={{ fontWeight: "400" }}>Email PDF</label>
                    </div>

                    {emailPdf &&
                        (
                            <section>
                                <p style={{ marginTop: "20px", marginBottom: "5px", fontSize: "1.15rem", fontWeight: "600" }}> Email To:</p>

                                <Input id="emailAddress"
                                    type="email"
                                    value={emailAddress}
                                    placeholder="Email address of recipient"
                                    size={controlSize}
                                    readOnly={!emailPdf}
                                    error={showEmailError ? "This does not appear to be a valid email address" : null}
                                    onChange={handleChange} 
                                    onBlur={setEmailError} />
                            </section>
                        )}
                </div>
            </ModalBody>

            <ModalFooter>
                <button type="button" className="btn btn-sx btn-sm" disabled={!canContinue} onClick={() => (handleContinue())}>Continue</button>
                <button type="button" className="btn btn-secondary btn-sm" onClick={closeOptions}>Cancel</button>
            </ModalFooter>
        </Modal>
    )
}

export default InvoiceOptions;