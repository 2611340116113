import React, { useRef, useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react'; //https://www.digitalocean.com/community/tutorials/how-to-integrate-the-google-maps-api-into-react-applications


const MapComponent = props => {
    const [mapZoomLevel, mapZoomLevelSet] = useState(4);
    const [activeMarker, activeMarkerSet] = useState({});
    const [selectedPlace, selectedPlaceSet] = useState({});
    const markersRefs = useRef([]);

    const centre = { lat: -25.2744, lng: 133.7751 };

    const onZoomChangedHandler = () => {
        const zoomLevel = mapRef.current.map.getZoom()
        mapZoomLevelSet(zoomLevel);
    }

    const markerClickHandler = (props, marker, e) => {
        selectedPlaceSet(props);
        selectBranch(props.param, props);
        activeMarkerSet(marker);
    };

    const selectBranch = (branch, props) => {
        const selectedBranch = branches.find((branch) => branch.selected === true);
        if (selectedBranch) {
            selectedBranch.selected = false;
        }
        branch.selected = true;
    }

    const mapRef = useRef();

    const mapStyle = { position: "static", borderRadius: "5px", width: "600px", height: "600px" };
    //{ position: "static", width: "100%", height: "auto", minHeight: "400px", borderRadius: "5px" }

    if (!!!props.branches) return null;

    const branches = props.branches;

    return (
        <Map
            ref={mapRef}
            center={centre}
            zoom={mapZoomLevel}
            google={props.google}
            style={mapStyle}
            initialCenter={centre}
            onZoomChanged={onZoomChangedHandler}>

            {branches &&
                [...branches]
                    .sort((a, b) => b.id - a.id)
                    .map((branch, idx) => {
                        return <Marker
                            name={"Shred-X " + branch.name}
                            param={branch}
                            position={branch.position}
                            key={branch.id}
                            onClick={markerClickHandler}
                            ref={(el) => markersRefs.current.push(el)} />;
                    })}

            <InfoWindow
                marker={activeMarker}
                visible={true}>
                <div>
                    <div>
                        <b>{selectedPlace.name}</b>
                    </div>

                    {selectedPlace.param && (
                        <address>
                            <div>{selectedPlace.param.address1}</div>
                            {selectedPlace.param.address2 && <div>{selectedPlace.param.address2}</div>}
                            <div>
                                {selectedPlace.param.suburb}&nbsp;{selectedPlace.param.state}&nbsp;{selectedPlace.param.postcode}
                            </div>
                        </address>
                    )}
                </div>
            </InfoWindow>
        </Map>
    )
};

export default GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY })(MapComponent);