import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, Row, Col, CardBody, CardHeader, Label } from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useRecoilState } from "recoil";
import { accountState, accessTokenState, userState } from "../../Shared/atom";

import message from "../../Shared/message";
import restHelper from "../../Shared/restHelper";

import Input from "../common/Input.Component";

import styles from "./ClosedDatesRequest.module.scss";

const ClosedDatesRequest = ({ isOpen, onCloseClick, ...args }) => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [portalAccount] = useRecoilState(accountState);
    const [portalUser] = useRecoilState(userState);

    // -- Page State -----------------------------------------------------------------------------------------------------------------------
    const [closedDates, closedDatesSet] = useState([]);
    const [dateFrom, dateFromSet] = useState(null);
    const [dateTo, dateToSet] = useState(null);
    const [datesLoaded, datesLoadedSet] = useState(false);
    const [filteredDates, filteredDatesSet] = useState([]);
    const [formIsValid, formIsValidSet] = useState(false);
    const [minFromDt, minFromDtSet] = useState(null);
    const [reason, reasonSet] = useState(null);
    const [showAllDates, showAllDatesSet] = useState(false);

    // -- Page Requests -------------------------------------------------------------------------------------------------------------------
    const rowClass = "form-row";
    const [controlSize] = useState(args?.controlSize ?? "md");

    // -- State Changes -------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const loadClosedDates = async () => {
            if (datesLoaded || !isOpen) return;

            if (closedDates.length === 0 && isOpen) {
                let _closedDates = await restHelper.getClosedDates(portalUser.accountId, accessToken.token);
                _closedDates.sort((a, b) => new Date(a.fromDt) - new Date(b.fromDt));
                closedDatesSet(_closedDates);
                datesLoadedSet(true);
            }
        }

        loadClosedDates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        formIsValidSet(dateFrom && reason);
    }, [dateFrom, dateTo, reason]);

    useEffect(() => {
        if (showAllDates)
            filteredDatesSet([...closedDates]);
        else
            filteredDatesSet(closedDates.filter(x => !x.toDt || (new Date(x.toDt).getTime() >= new Date().setHours(0, 0, 0, 0))));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closedDates, showAllDates]);

    // -- Functions -----------------------------------------------------------------------------------------------------------------------
    const dateSortFrom = (rowA, rowB) => {
        const dateA = new Date(rowA.fromDt).getTime();
        const dateB = new Date(rowB.fromDt).getTime();

        return dateA - dateB;
    }

    const dateSortTo = (rowA, rowB) => {
        const dateA = new Date(rowA.toDt).getTime();
        const dateB = new Date(rowB.toDt).getTime();

        return dateA - dateB
    }

    const handleCloseClick = () => {
        datesLoadedSet(false);
        onCloseClick();
    };

    const restoreDates = async (accountId, recordId) => {
        let msg;
        let emailModel = null;
        const CLOSED_DATES = "Closed Dates";
        const CLOSED_DATES_RESTORED = "Closed Dates Restored";

        try {
            const _closedDate = closedDates.find(x => x.id === recordId);
            await restHelper.restoreClosedDate(accountId, recordId, accessToken.token);

            let _closedDates = [...closedDates];
            _closedDates = _closedDates.filter(x => x.id !== recordId);
            closedDatesSet(_closedDates);

            // If the closed dates have expired there is no need to display a message or send an email...
            if (_closedDate.toDt && new Date(_closedDate.toDt) <= new Date()) return;

            if (_closedDate.toDt) {
                if (_closedDate.fromDt === _closedDate.toDt) {
                    msg = `Successfully restored the dates.\n\nYour services for ${moment(_closedDate.fromDt).format("ddd, d MMM yyyy")} will be resumed.`;
                }
                else {
                    msg = `Successfully restored the dates.\n\nYour services between ${moment(_closedDate.fromDt).format("ddd, d MMM yyyy")} and ${moment(_closedDate.toDt).format("ddd, d MMM yyyy")} will be resumed.`;
                }
            }
            else {
                msg = `Successfully restored the date.\n\nYour services will be resumed from ${moment(_closedDate.fromDt).format("ddd, d MMM yyyy")}.`;
            }

            message.Success(CLOSED_DATES_RESTORED, msg);

            // Email customer...
            try {
                emailModel = {
                    templateName: `${CLOSED_DATES_RESTORED} - Customer`,
                    firstName: portalUser.givenName,
                    lastName: portalUser.surname,
                    toAddress: portalUser.email,
                    accountId: portalUser.accountId,
                    formData: {
                        can: portalAccount.accountNumber,
                        client: portalAccount.accountName,
                        fromDt: _closedDate.fromDt,
                        toDt: _closedDate.toDt,
                        submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                        submittedByEmail: portalUser.email,
                        reason: _closedDate.reason,
                    }
                };

                sendEmail(emailModel);
            }

            catch (ex) {
                message.Error(`${CLOSED_DATES_RESTORED} - Customer Confirmation`, ex)
            }

            // Email Customer Service...
            try {
                emailModel = {
                    templateName: `${CLOSED_DATES_RESTORED} - Customer Service`,
                    firstName: "Customer Service",
                    toBranch: portalAccount?.branch,
                    accountId: portalUser.accountId,
                    formData: {
                        can: portalAccount.accountNumber,
                        client: portalAccount.accountName,
                        fromDt: _closedDate.fromDt,
                        toDt: _closedDate.toDt,
                        submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                        submittedByEmail: portalUser.email,
                        reason: _closedDate.reason,
                    }
                };

                sendEmail(emailModel);
            }

            catch (ex) {
                message.Error(`${CLOSED_DATES_RESTORED} - Customer Service Notice`, ex)
            }
        }

        catch (ex) {
            message.Error(`Restore ${CLOSED_DATES}`, ex.message ?? ex);
        }
    }

    const saveDates = async () => {
        let _closedDate = {
            accountId: portalUser.accountId,
            userId: portalUser.userId,
            fromDt: dateFrom,
            toDt: dateTo,
            reason: reason
        };

        let emailModel = null;

        try {
            _closedDate = await restHelper.saveClosedDates(_closedDate, accessToken.token);
            if (!_closedDate) return;

            let _closedDates = [...closedDates];
            _closedDates.push(_closedDate);
            _closedDates = _closedDates.sort((a, b) => new Date(b.fromDt) - new Date(a.fromDt));
            closedDatesSet(_closedDates);

            dateFromSet(null);
            dateToSet(null);
            reasonSet(null);

            // Email customer...
            try {
                emailModel = {
                    templateName: "Closed Dates - Customer",
                    firstName: portalUser.givenName,
                    lastName: portalUser.surname,
                    toAddress: portalUser.email,
                    accountId: portalUser.accountId,
                    formData: {
                        can: portalAccount.accountNumber,
                        client: portalAccount.accountName,
                        fromDt: _closedDate.fromDt,
                        toDt: _closedDate.toDt,
                        submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                        submittedByEmail: portalUser.email,
                        reason: reason,
                    }
                };

                sendEmail(emailModel);
            }

            catch (ex) {
                message.Error("Closed Dates - Customer Confirmation", ex)
            }

            // Email Customer Service...
            try {
                emailModel = {
                    templateName: "Closed Dates - Customer Service",
                    firstName: "Customer Service",
                    toBranch: portalAccount?.branch,
                    accountId: portalUser.accountId,
                    formData: {
                        can: portalAccount.accountNumber,
                        client: portalAccount.accountName,
                        fromDt: _closedDate.fromDt,
                        toDt: _closedDate.toDt,
                        submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                        submittedByEmail: portalUser.email,
                        reason: reason,
                    }
                };

                sendEmail(emailModel);
            }

            catch (ex) {
                message.Error("Closed Dates - Customer Service Notice", ex)
            }

            let msg;

            if (_closedDate.toDt)
                msg = `Successfully saved the dates from ${moment(_closedDate.fromDt).format("ddd, D MMM YYYY")} to ${moment(_closedDate.toDt).format("ddd, D MMM YYYY")}.`;
            else
                msg = `Successfully saved the date from ${moment(_closedDate.fromDt).format("ddd, D MMM YYYY")}.`;

            message.Success("Closed Dates", msg);
        }

        catch (ex) {
            message.Error("Closed Dates", ex.message ?? ex);
        }
    }

    const sendEmail = async (emailModel) => {
        try{
            await restHelper.sendGridEmail(emailModel, accessToken.token)
        }

        catch(ex){
            message.Error(emailModel.templateName, ex.message ?? ex);
        }
    };

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    const columns = [
        {
            name: "From",
            selector: row => row.fromDt,
            sortable: true,
            sortFunction: dateSortFrom,
            cell: (row) => <span>{moment(row.fromDt).format("ddd, D MMM YYYY")}</span>
        },
        {
            name: "To",
            selector: row => row.toDt,
            sortable: true,
            sortFunction: dateSortTo,
            cell: (row) => <span>{row.toDt ? moment(row.toDt).format("ddd, D MMM YYYY") : ""}</span>
        },
        {
            name: "Reason",
            selector: row => row.reason,
            sortable: false
        },
        {
            name: "User",
            selector: row => row.user,
            sortable: true,
            cell: (row) => <span>{portalUser.isStaff ? row.user : row.isStaff ? "Customer Service" : row.user}</span>
        },
        {
            sortable: false,
            width: "75px",
            cell: (row) =>
                <div className="d-flex align-items-center justify-content-end">
                    <button
                        type="button"
                        className="btn btn-danger btn-xs ms-1"
                        onClick={() => restoreDates(row.accountId, row.id)}
                        title="Delete date record">
                        <FontAwesomeIcon icon="trash" />
                    </button>
                </div>
        }
    ];

    if (!minFromDt) {
        let _date  = new Date().setHours(0, 0, 0, 0);
        _date = new Date(_date);
        _date.setDate(_date.getDate() + 3);
        minFromDtSet(_date);
    }

    return (
        <Modal 
            className="modal-xl" 
            backdrop="static" 
            isOpen={isOpen} 
            toggle={handleCloseClick}>
            <ModalHeader>Closed Dates Request</ModalHeader>

            <ModalBody>
                <Card className="mb-0">
                    <CardHeader>Business Closed Dates</CardHeader>

                    <CardBody className={styles["card-body"]}>
                        <Row className={`${rowClass} mb-3`}>
                            <Col className={styles["label-col"] + " mt-xl-2"}>
                                <Label>Our business will be closed between:</Label>
                            </Col>

                            <Col>
                                <Row className={rowClass}>
                                    <Col>
                                        <Input
                                            type="date"
                                            id="dateFrom"
                                            groupClass="mb-0"
                                            size={controlSize}
                                            value={dateFrom}
                                            startDate={dateFrom}
                                            endDate={dateTo}
                                            minDate={minFromDt}
                                            selectsStart={true}
                                            onChange={(date) => dateFromSet(date)} />
                                    </Col>

                                    <Col xs="auto" className="align-self-center">
                                        <Label className="form-label">and</Label>
                                    </Col>

                                    <Col>
                                        <Input
                                            type="date"
                                            id="dateTo"
                                            groupClass="mb-0"
                                            size={controlSize}
                                            value={dateTo}
                                            startDate={dateFrom}
                                            endDate={dateTo}
                                            minDate={dateFrom ?? minFromDt}
                                            selectsEnd={true}
                                            onChange={(date) => dateToSet(date)} />
                                    </Col>

                                    <em style={{ marginTop: "-10px" }}>If you are not sure when the business will be opening again leave the 2nd date blank.</em>
                                </Row>
                            </Col>
                        </Row>

                        <Row className={rowClass + " align-items-center"}>
                            <Col className={styles["label-col"]}>
                                <Label className="form-label">For the following reason:</Label>
                            </Col>

                            <Col>
                                <Input type="select" groupClass="mb-0" value={reason} size={controlSize} onChange={(e) => reasonSet(e.target.value)}>
                                    <option value="">Choose...</option>
                                    <option value="COVID-19 Lockdown">COVID-19 Lockdown</option>
                                    <option value="Public Holiday">Public Holiday</option>
                                    <option value="School Holiday">School Holiday</option>
                                    <option value="Christmas">Christmas</option>
                                    <option value="Other">Other</option>
                                </Input>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </ModalBody>

            <ModalFooter className={styles["service-button-group"]} style={{ paddingTop: "0", backgroundColor: "transparent", borderTop: "0 solid transparent" }}>
                <button
                    type="button" 
                    className="btn btn-success btn-sm"
                    title="Save the closed dates"
                    disabled={!formIsValid}
                    onClick={saveDates}>
                    <FontAwesomeIcon icon="save" /> Save
                </button>

                <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={handleCloseClick} title="Return to the Service Schedule page">
                    <FontAwesomeIcon icon="window-close" /> Close
                </button>
            </ModalFooter>

            <div className={styles["closed-dates-table"]}>
                <div className="form-check form-check-inline" style={{ float: "right", marginBottom: "5px" }}>
                    <input type="checkbox" className="form-check-input" id="showAllDates" checked={showAllDates} onChange={() => showAllDatesSet(!showAllDates)} />
                    <label className="form-check-label" htmlFor="showAllDates">Include expired dates</label>
                </div>

                <DataTable
                    defaultSortField="fromDt"
                    columns={columns}
                    data={filteredDates}
                    defaultSortAsc={false}
                    dense={true}
                    highlightOnHover={true}
                    responsive
                    noHeader
                    striped />
            </div>
        </Modal>
    );
}

export default ClosedDatesRequest;