class CommonHelper {

    static compare(obj1, obj2) {
        return (JSON.stringify(obj1) === JSON.stringify(obj2));
    }

    static hasValue(obj) {
        if (obj === null || obj === undefined) return false;
        if (Array.isArray(obj)) return obj.length > 0;
        if (typeof (obj) === "string") return obj.length > 0;

        // If it is an object then it must have some properties to "have a value"...
        if (typeof (obj) === "object") return Object.keys(obj).length > 0;

        // Anything else is either a boolean, function or number...
        return true;
    }

    static thaw(obj) {
        // See https://github.com/facebookexperimental/Recoil/issues/299 for further details.
        // Recoil "freezes" the state object to prevent it being mutated and the spread operator "{...portalAccount}" and 
        // "Object.assign({}, {...portalAccount})"" make shallow copies that don't "thaw" the copy.  The following creates a
        // deep copy which is able to be mutated...
        if (Object.isFrozen(obj)) return JSON.parse(JSON.stringify(obj));

        if (Array.isArray(obj))
            return [...obj];
        else
            return { ...obj };
    }

    // Creates an exact copy of an object.
    static deepClone(obj){
        return JSON.parse(JSON.stringify(obj));
    }
}

export default CommonHelper;