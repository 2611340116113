import { useCallback, useReducer } from "react";

export const isNotEmpty = (val) => val?.trim() !== "";
export const isNotZero = (val) => +val !== 0;
export const isEmail = (val) => isNotEmpty(val) && val.includes("@") && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.toLowerCase());
export const isDate = (val) => val instanceof Date && !isNaN(val);

const initialInputState = {
    value: "",
    isTouched: false,
};

const inputStateReducer = (state, action) => {
    if (action.type === "INPUT") {
        return { value: action.value, isTouched: state.isTouched };
    } 
    
    else if (action.type === "BLUR") {
        return { isTouched: true, value: state.value };
    } 
    
    else if (action.type === "RESET") {
        return { isTouched: false, value: action.value };
    }
};

const useInput = (validateValueFn, invalidMessage) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, initialInputState);
    const valueIsValid = validateValueFn(inputState.value);
    const hasError = !valueIsValid;
    const errorClass = hasError && inputState.isTouched ? "is-invalid" : "";
    const errorText = hasError && inputState.isTouched ? invalidMessage : "";

    const setValue = useCallback((value) => dispatch({ type: "INPUT", value }), []);

    const valueChangedHandler = (event) => {
        setValue(event.currentTarget.value);
    };

    const inputBlurHandler = (event) => {
        dispatch({ type: "BLUR" });
    };

    const reset = useCallback((value = "") => {
        dispatch({ type: "RESET", value });
    }, []);

    return {
        value: inputState.value,
        setValue,
        hasError,
        errorClass,
        errorText,
        valueChangedHandler,
        inputBlurHandler,
        reset
    };
};

export default useInput;
