import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";

import { useRecoilState } from "recoil";
import { accessTokenState, accountState, scheduledJobsState } from "../../Shared/atom";

import AuthenticateUser from "../common/AuthenticateUser.Component";
import PageLoading from "../common/PageLoading.Component";

import useHttp from "../../Hooks/useHttp";

import * as apiUrls from "../../Shared/endPoints";

import ServiceCalendar from "./ServiceCalendar.Page";
import styles from "./ServiceCalendar.module.scss";

export const ServiceCalendarPage = () => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [portalAccount] = useRecoilState(accountState);
    const [scheduledJobs, scheduledJobsSet] = useRecoilState(scheduledJobsState);

    // -- Page State ----------------------------------------------------------------------------------------------------------------------
    const [pageError, pageErrorSet] = useState(null);

    const { request: postAuditHistory } = useHttp();
    const { request: getScheduledJobs, isLoading: scheduledJobsIsLoading } = useHttp("Scheduled Jobs", true);

    const [calendarLength, calendarLengthSet] = useState(3)

    // -- State Events --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        document.title = "Shred-X: Service Calendar";

        if (accessToken.token) {
            postAuditHistory(apiUrls.AddAuditEntry, "post", JSON.stringify({ "page": "Scheduled Jobs", "action": "View", "detail": null }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    useEffect(() => {
        if (portalAccount && accessToken.token) {
            try {
                if (!scheduledJobs || scheduledJobs.length === 0) {
                    getScheduledJobs(`${apiUrls.GetScheduledJobs}/${portalAccount.accountId}`).then(data => scheduledJobsSet(data));
                }
            } 
            
            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");
                pageErrorSet(parse(errMsg));
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken.token, portalAccount]);

    // -- Render Page ---------------------------------------------------------------------------------------------------------------------
    return (
        <div className={`${styles["service-calendar"]} container`}>
            <AuthenticateUser>
                {pageError && (
                    <div className="text-center">
                        <h2 style={{ color: "red" }}>Error Loading Page</h2>
                        <div className="alert alert-danger">{pageError}</div>
                    </div>
                )}

                {scheduledJobsIsLoading && !pageError && <PageLoading message="Loading your service schedule, please wait..." />}
                
                {!scheduledJobsIsLoading && !pageError && (
                    <>
                        <div className={styles["title"]}>
                            <h3>Service Schedule</h3><br/>
                        </div>

                        <div className={styles["sub-heading"]}>
                            <p className="fs-6 fw-light fst-italic text-danger">Service dates are subject to change. Always refer to this page for up-to-date service dates.</p>
                        </div>

                        <div className={styles["options"]}>
                            <div className="d-inline-flex align-items-center flex-row-reverse hand" onClick={() => { calendarLengthSet(3) }}>
                                <label className="mb-0 ms-1 hand"> 3 Months</label>
                                <input type="radio" className="hand" checked={calendarLength === 3} />
                            </div>

                            <div className="d-inline-flex align-items-center flex-row-reverse mx-2 hand" onClick={() => { calendarLengthSet(6) }}>
                                <label className="mb-0 ms-1 hand"> 6 Months</label>
                                <input type="radio" className="hand" checked={calendarLength === 6} />
                            </div>

                            <div className="d-inline-flex align-items-center flex-row-reverse hand" onClick={() => { calendarLengthSet(12) }}>
                                <label className="mb-0 ms-1 hand"> 12 Months</label>
                                <input type="radio" className="hand" checked={calendarLength === 12} />
                            </div>
                        </div>

                        <div className={styles["content"]}>
                            {[...Array(calendarLength)].map((item, iMonth) => {
                                const month = moment().add(iMonth, "month");
                                const jobs = scheduledJobs ? scheduledJobs.filter((item) => moment(item.serviceDate).isSame(month, "month")) : [];
                                return (
                                    <div className="d-inline-block mx-3 my-2 d-inline-block">
                                        <ServiceCalendar scheduledJobs={jobs} date={month.toDate()} />
                                    </div>
                                );
                            })}
                        </div>

                        <div className={styles["close"]}>
                                <NavLink to="service" type="button" className="btn btn-danger btn-sm">Close</NavLink>
                            </div>
                    </>
                )}
            </AuthenticateUser>
        </div>
    );
};
