import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalPrompt = ({ modalTitle, modalMessage, isDisplayed, handleYes, handleNo }) => {
    return (
        <Modal backdrop="static" isOpen={isDisplayed} centered={false}>
            <ModalHeader>{modalTitle}</ModalHeader>

            <ModalBody>{modalMessage}</ModalBody>

            <ModalFooter>
                <button type="button" className="btn btn-success" onClick={handleYes}><FontAwesomeIcon icon="check-square" />&nbsp;Yes</button>
                <button type="button" className="btn btn-warning" onClick={handleNo}><FontAwesomeIcon icon="window-close" />&nbsp;No</button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalPrompt;