import React from "react"
import { Calendar, momentLocalizer } from 'react-big-calendar' //https://jquense.github.io/react-big-calendar/examples/index.html#intro
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const ServiceCalendar = ({scheduledJobs, date}) => {
   const events = scheduledJobs.map(item => {
      return {title: "", start: item.serviceDate, end: item.serviceDate, allDay: true}
   })

   return <Calendar localizer={localizer} views={["month", "agenda"]} events={events} date={date} startAccessor="start" endAccessor="end" style={{ height: "260px", width: "300px" }} />;
}

export default ServiceCalendar