import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import parse from "html-react-parser";
import { saveAs } from 'file-saver'
import message from "../../Shared/message";

import { useRecoilState } from "recoil";
import { accessTokenState, accountState, controlState, userState, accountTransactionsState, accountBalanceState } from "../../Shared/atom";

import moment from "moment";

import AuthenticateUser from "../common/AuthenticateUser.Component";
import BillingDataTable from "./BillingDataTable.Component";
import DateHelper from "../../Shared/dateHelper";
import Input from "../common/Input.Component";
import InvoiceFilter from "./InvoiceFilter.Component";
import InvoiceOptions from "./InvoiceOptions.Component";
import PageLoading from "../common/PageLoading.Component";
import Payway from "./Payway.Component";

import useFullPageLoader from "../../Hooks/useFullPageLoader";

import currencyHelper from "../../Shared/currencyHelper";
import { currencyFilter, dateFilter, numberFilter } from "../../Shared/enums"

import styles from "./Billing.module.scss";

import useHttp from "../../Hooks/useHttp";
import * as apiUrls from "../../Shared/endPoints";
import * as dataTransformers from "../../Shared/dataTransformers"

var _ = require("lodash");

export const BillingPage = () => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [accBalance, accBalanceSet] = useRecoilState(accountBalanceState);
    const [controlSize] = useRecoilState(controlState);
    const [portalAccount] = useRecoilState(accountState);
    const [portalUser] = useRecoilState(userState);
    const [accountTransactions, accountTransactionsSet] = useRecoilState(accountTransactionsState);

    // -- Local State ---------------------------------------------------------------------------------------------------------------------
    const [balanceOverdue, balanceOverdueSet] = useState(0);
    const [currentAccountId, currentAccountIdSet] = useState(null);
    const [filteredTransactions, filteredTransactionsSet] = useState([]);
    const [filterOptions, filterOptionsSet] = useState({});
    const [invoiceModal, invoiceModalSet] = useState(false);
    const [invoiceNumber, invoiceNumberSet] = useState(null);
    const [pageError, pageErrorSet] = useState(null);
    const [pageLoaded, pageLoadedSet] = useState(null);
    const [paymentTransactions, paymentTransactionsSet] = useState(null);
    const [reloadTransactions, reloadTransactionsSet] = useState(false);
    const [showFilterModal, showFilterModalSet] = useState(false);
    const [showPaywayModal, showPaywayModalSet] = useState(false);

    // -- Page Requests -------------------------------------------------------------------------------------------------------------------
    const { request: downloadInvoice } = useHttp("Download Invoice", false);
    const { request: emailInvoice } = useHttp("Email Invoice", false);
    const { request: getBillingData } = useHttp("Account", true);
    const { request: postAuditHistory } = useHttp();

    // -- Page Loader ---------------------------------------------------------------------------------------------------------------------
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    document.title = "Shred-X: Account";

    // -- State Events --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const loadPage = async () => {
            try {
                postAuditHistory(apiUrls.AddAuditEntry, "post", JSON.stringify({ accountId: portalAccount.accountId, page: "Account", action: "View", detail: null }));

                if (!currentAccountId && accountTransactions) {
                    currentAccountIdSet(portalAccount.accountId);
                }

                else if (currentAccountId !== portalAccount.accountId) {
                    console.log(`${DateHelper.FormatDate(new Date(), 'LogDate')} Setting Account ID`);
                    currentAccountIdSet(portalAccount.accountId);

                    reloadTransactionsSet(true);
                }

                pageLoadedSet(true);
            }

            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");

                pageErrorSet(parse(errMsg));
                pageLoadedSet(false);
            }
        };

        if (portalUser && accessToken.token) loadPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portalAccount]);

    /**
     * Loads the transaction data whenever the portal account changes...
     */
    useEffect(() => {
        const loadTransactions = async () => {
            try {
                if (reloadTransactions) {
                    console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Fetching transactions...`);

                    await getBillingData(`${apiUrls.GetTransactions}?id=${portalAccount.axAccountNumber}`)
                        .then(billingData => {
                            const accountTransactions = dataTransformers.transformBillingData(billingData);
                            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Fetched ${accountTransactions.transactions.length} transactions...`);

                            accBalanceSet(accountTransactions?.accountBalance || 0);
                            accountTransactionsSet(accountTransactions.transactions || []);
                        })
                        .catch(ex =>
                            message.Display({ type: "error", title: "Account Transactions", text: ex.message ?? ex })
                        );
                }
            }

            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");
                pageErrorSet(parse(errMsg));
            }

            finally {
                reloadTransactionsSet(false);
            }
        };

        if (reloadTransactions) loadTransactions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadTransactions]);

    useEffect(() => {
        filteredTransactionsSet(accountTransactions || []);

        // Calculate the total of all overdue invoices...
        const overdueTransactions = accountTransactions?.filter(x => new Date(x.dueDate).getTime() < new Date().getTime() && x.amount - x.amountPaid > 0)
        const _balanceOverDue = overdueTransactions?.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0) || 0;
        balanceOverdueSet(_balanceOverDue);
    }, [accountTransactions])

    // -- Form Functions ------------------------------------------------------------------------------------------------------------------
    const handleFilterChanged = (_filterOptions) => {
        showFilterModalSet(false);

        if (!_.isEqual(filterOptions, _filterOptions)) {
            filterOptionsSet(_filterOptions);

            const _invoices = filterInvoices(_filterOptions);
            filteredTransactionsSet(_invoices);
        }
    };

    const handleFilterClear = () => {
        showFilterModalSet(false);
        filterOptionsSet({});
        filteredTransactionsSet(accountTransactions);
    };

    const handleFilterClose = () => {
        showFilterModalSet(false);
    };

    const handleFilterToggle = () => {
        showFilterModalSet(!showFilterModal);
    };

    const handleOptionsClose = async (invoiceOptions) => {
        invoiceModalSet(false);
        showLoader();

        if (invoiceOptions?.download?.pdf) {
            try {
                const url = `${apiUrls.DownloadInvoice}/${portalAccount.accountId}/${portalAccount.axAccountNumber}/${invoiceNumber}`;

                await downloadInvoice(url, "post")
                    .then(invoicePdfData => {
                        if (invoicePdfData?.size > 0) {
                            saveAs(invoicePdfData, `Invoice_${invoiceNumber}.pdf`);
                            message.Success("Download Invoice", `Invoice "${invoiceNumber}" was successfully downloaded`);
                        }

                        else {
                            message.Information("Download Invoice", parse(`There was a problem downloading  invoice "${invoiceNumber}".\nPlease try again and if the problem persists contact Customer Service on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339) for assistance.`));
                        }
                    })

                    .catch(error => {
                        message.Error("Download Invoice", error)
                    })
            }

            catch (error) {
                message.Error("Download Invoice", error);
            }
        }

        if (invoiceOptions?.email?.pdf && invoiceOptions?.emailAddress) {
            try {
                const emailModel = {
                    templateName: "Email Invoice - Customer",
                    firstName: portalUser.givenName,
                    lastName: portalUser.surname,
                    senderEmail: portalUser.email,
                    toAddress: invoiceOptions.emailAddress,
                    accountId: portalUser.accountId,
                    formData: {
                        accountNumber: portalAccount.axAccountNumber,
                        invoiceNumber: invoiceNumber,
                    },
                };

                await emailInvoice(apiUrls.SendGridEmail, "post", JSON.stringify(emailModel))
                    .then(data => {
                        message.Success("Email Invoice", `The invoice was successfully emailed to "${invoiceOptions.emailAddress}".`)
                    })
                    .catch(error => {
                        message.Error("Email Invoice", error.message ? error.message : error)
                    });
            }

            catch (error) {
                message.Error("Email Invoice", error);
            }
        }

        hideLoader();
    };

    const handleOptionsShow = (invoiceNumber, hasInvoicePdf) => {
        if (!hasInvoicePdf) {
            message.Information("Invoice Options", `A PDF of the invoice "${invoiceNumber}" has not been created.\n\nIf you require an invoice for this transaction please contact our Customer Service team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339) and select option&nbsp;3.`);
            return;
        }

        invoiceNumberSet(invoiceNumber);
        invoiceModalSet(true);
    };

    /**
     * If the user is paying a single invoice then invoices will contain a single record.
     * If the user is paying the total balance then invoices will be undefined and the current transactions will be used to determine which invoices are being paid.
     * @param {*} invoices 
     * @returns 
     */
    const handlePayInvoices = (invoices) => {
        if (invoices && (invoices.length !== 1 || invoices[0].paymentStatus !== "Unpaid")) return;

        paymentTransactionsSet(invoices || accountTransactions.filter((invoice) => invoice.amount > 0 && invoice.paymentStatus === "Unpaid"));
        showPaywayModalSet(true);
    };

    const handlePaymentClose = () => {
        showPaywayModalSet(false);
    };

    const handlePaymentSuccess = (invoices) => {
        const updatedFilteredTransactions = [...filteredTransactions];

        invoices.forEach((inv) => {
            const idx = updatedFilteredTransactions.findIndex((i) => i.invoiceNumber === inv.invoiceNumber);
            updatedFilteredTransactions[idx] = inv;
        });

        filteredTransactionsSet(updatedFilteredTransactions);
        showPaywayModalSet(false);
    };

    // -- Filter Functions ----------------------------------------------------------------------------------------------------------------
    /**
     * AX uses the date "1900-01-01 00:00:00.000" instead of "NULL" so the date filter methods need to use this
     * when comparing data for "no date".
     * NOTE: Credit notes ("CCN" and "SCN") are also given a due date based on the payment terms.
     */

    const filterInvoices = (_filterOptions) => {
        let _transactions = [...accountTransactions];

        if (Object.keys(_filterOptions).length > 0) {
            _transactions = filterByInvoiceNumber(_transactions, _filterOptions);
            _transactions = filterByInvoiceDate(_transactions, _filterOptions);
            _transactions = filterByDueDate(_transactions, _filterOptions);
            _transactions = filterByAmount(_transactions, _filterOptions);
            _transactions = filterByDatePaid(_transactions, _filterOptions);
            _transactions = filterByAmountPaid(_transactions, _filterOptions);
            _transactions = filterByBalance(_transactions, _filterOptions);
        }

        return _transactions;
    };

    const filterByAmount = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.amountOption ? Number(_filterOptions.amountOption) : 0;
        if (filterOption === 0 || !_filterOptions?.amountFilter1) return _transactions;

        ////let _invoices = [...invoices];
        const amount1 = currencyHelper.toNumber(_filterOptions.amountFilter1);

        switch (filterOption) {
            case currencyFilter.GREATER_THAN_OR_EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amount >= amount1);
                break;

            case currencyFilter.LESS_THAN_OR_EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amount <= amount1);
                break;

            case currencyFilter.EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amount === amount1);
                break;

            case currencyFilter.BETWEEN.value:
                if (!_filterOptions?.amountFilter2) break;
                const amount2 = currencyHelper.toNumber(_filterOptions.amountFilter2);
                _transactions = _transactions.filter((x) => x.amount >= amount1 && x.amount <= amount2);
                break;

            // no default
        }

        return _transactions;
    };

    const filterByAmountPaid = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.amountPaidOption ? Number(_filterOptions.amountPaidOption) : 0;
        if (filterOption === 0 || !_filterOptions?.amountPaidFilter1) return _transactions;

        const amount1 = currencyHelper.toNumber(_filterOptions.amountPaidFilter1);

        switch (filterOption) {
            case currencyFilter.GREATER_THAN_OR_EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amountPaid >= amount1);
                break;

            case currencyFilter.LESS_THAN_OR_EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amountPaid <= amount1);
                break;

            case currencyFilter.EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amountPaid === amount1);
                break;

            case currencyFilter.BETWEEN.value:
                if (!_filterOptions?.amountPaidFilter2) break;
                const amount2 = Number(_filterOptions.amountPaidFilter2);
                _transactions = _transactions.filter((x) => x.amountPaid >= amount1 && x.amountPaid <= amount2);
                break;

            case 99:
                _transactions = _transactions.filter((x) => !x.amountPaid);
                break;

            // no default
        }

        return _transactions;
    };

    const filterByBalance = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.balanceOption ? Number(_filterOptions.balanceOption) : 0;
        if (filterOption === 0 || !_filterOptions?.balanceFilter1) return _transactions;

        const amount1 = currencyHelper.toNumber(_filterOptions.balanceFilter1);

        switch (_filterOptions.balanceOption) {
            case currencyFilter.GREATER_THAN_OR_EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amount - (x.amountPaid ?? 0) >= amount1);
                break;

            case currencyFilter.LESS_THAN_OR_EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amount - (x.amountPaid ?? 0) <= amount1);
                break;

            case currencyFilter.EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.amount - (x.amountPaid ?? 0) === amount1);
                break;

            case currencyFilter.BETWEEN.value:
                if (!_filterOptions?.balanceFilter2) break;
                const amount2 = Number(_filterOptions.balanceFilter2);
                _transactions = _transactions.filter((x) => x.amount - (x.amountPaid ?? 0) >= amount1 && x.amount - (x.amountPaid ?? 0) <= amount2);
                break;

            // no default
        }

        return _transactions;
    };

    const filterByDatePaid = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.datePaidOption ? Number(_filterOptions.datePaidOption) : 0;
        if (filterOption === 0 || (filterOption !== 99 && !_filterOptions?.datePaidFilter1)) return _transactions;

        // Don't include credit notes...
        _transactions = _transactions.filter((x) => !(x.invoiceNumber.toLowerCase().startsWith("ccn") || x.invoiceNumber.toLowerCase().startsWith("scn")));

        const date1 = moment(_filterOptions.datePaidFilter1).startOf("day");

        switch (filterOption) {
            case dateFilter.ON_OR_AFTER.value:
                _transactions = _transactions.filter((x) => x.amountPaid > 0 && moment(x.datePaid).isSameOrAfter(date1, "day"));
                break;

            case dateFilter.ON_OR_BEFORE.value:
                _transactions = _transactions.filter((x) => x.amountPaid > 0 && moment(x.datePaid).isSameOrBefore(date1, "day"));
                break;

            case dateFilter.EQUAL_TO.value:
                _transactions = _transactions.filter((x) => x.datePaid && moment(x.datePaid).isSame(date1, "day"));
                break;

            case dateFilter.BETWEEN.value:
                if (!_filterOptions?.datePaidFilter2) break;
                const date2 = moment(_filterOptions.datePaidFilter2).endOf("day");
                _transactions = _transactions.filter((x) => x.datePaid && moment(x.datePaid).isBetween(date1, date2, "day", "[]"));
                break;

            // Not paid...
            case 99:
                _transactions = _transactions.filter((x) => moment(x.datePaid).isSame("1900-01-01"));
                break;

            // no default
        }

        return _transactions;
    };

    const filterByDueDate = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.dueDateOption ? Number(_filterOptions.dueDateOption) : 0;
        if (filterOption === 0 || !_filterOptions?.dueDateFilter1) return _transactions;

        // Don't include credit notes or records where the date is "1900-01-01"...
        _transactions = _transactions.filter((x) => moment(x.dueDate).isSame("1900-01-01") && !(x.invoiceNumber.toLowerCase().startsWith("ccn") || x.invoiceNumber.toLowerCase().startsWith("scn")));

        const date1 = moment(_filterOptions.dueDateFilter1).startOf("day");

        switch (filterOption) {
            case dateFilter.ON_OR_AFTER.value:
                _transactions = _transactions.filter((x) => moment(x.dueDate).isSameOrAfter(date1, "day") && !(x.invoiceNumber.toLowerCase().startsWith("ccn") || x.invoiceNumber.toLowerCase().startsWith("scn")));
                break;

            case dateFilter.ON_OR_BEFORE.value:
                _transactions = _transactions.filter((x) => moment(x.dueDate).isSameOrBefore(date1, "day") && !moment(x.dueDate).isSame("1900-01-01") && !(x.invoiceNumber.toLowerCase().startsWith("ccn") || x.invoiceNumber.toLowerCase().startsWith("scn")));
                break;

            case dateFilter.EQUAL_TO.value:
                _transactions = _transactions.filter((x) => moment(x.dueDate).isSame(date1, "day"));
                break;

            case dateFilter.BETWEEN.value:
                if (!_filterOptions?.dueDateFilter2) break;
                const date2 = moment(_filterOptions.dueDateFilter2).endOf("day");
                _transactions = _transactions.filter((x) => moment(x.dueDate).isBetween(date1, date2, "day", "[]"));
                break;

            // no default
        }

        return _transactions;
    };

    const filterByInvoiceDate = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.dateOption ? Number(_filterOptions.dateOption) : 0;
        if (filterOption === 0 || !_filterOptions?.dateFilter1) return _transactions;

        const date1 = moment(_filterOptions.dateFilter1).startOf("day");

        switch (filterOption) {
            case dateFilter.ON_OR_AFTER.value:
                _transactions = _transactions.filter((x) => moment(x.transactionDate).isSameOrAfter(date1, "day"));
                break;

            case dateFilter.ON_OR_BEFORE.value:
                _transactions = _transactions.filter((x) => moment(x.transactionDate).isSameOrBefore(date1, "day"));
                break;

            case dateFilter.EQUAL_TO.value:
                _transactions = _transactions.filter((x) => moment(x.transactionDate).isSame(date1, "day"));
                break;

            case dateFilter.BETWEEN.value:
                if (!_filterOptions?.dateFilter2) break;
                const date2 = moment(_filterOptions.dateFilter2).endOf("day");
                _transactions = _transactions.filter((x) => moment(x.transactionDate).isBetween(date1, date2, "day", "[]"));
                break;

            // no default
        }

        return _transactions;
    };

    const filterByInvoiceNumber = (_transactions, _filterOptions) => {
        if (_transactions.length === 0) return _transactions;

        const filterOption = _filterOptions.invoiceOption ? Number(_filterOptions.invoiceOption) : 0;
        if (filterOption === 0 || !_filterOptions?.invoiceFilter1) return _transactions;

        let filter1 = (_filterOptions.invoiceFilter1 + "").toLowerCase();

        switch (filterOption) {
            case numberFilter.EQUAL_TO.value:
                if (_filterOptions?.invoiceFilter1) {
                    _transactions = _transactions.filter((x) => x.invoiceNumber.toLowerCase() === filter1);
                }
                break;

            case numberFilter.STARTS_WITH.value:
                if (_filterOptions?.invoiceFilter1) {
                    _transactions = _transactions.filter((x) => x.invoiceNumber.toLowerCase().startsWith(filter1));
                }
                break;

            case numberFilter.ENDS_WITH.value:
                if (_filterOptions?.invoiceFilter1) {
                    _transactions = _transactions.filter((x) => x.invoiceNumber.toLowerCase().endsWith(filter1));
                }
                break;

            case numberFilter.CONTAINS.value:
                if (_filterOptions?.invoiceFilter1) {
                    _transactions = _transactions.filter((x) => x.invoiceNumber.toLowerCase().includes(filter1));
                }
                break;

            case numberFilter.BETWEEN.value:
                let invoice1 = Number(_filterOptions.invoiceFilter1);
                let invoice2 = Number(_filterOptions.invoiceFilter2);

                if (invoice1 > invoice2) {
                    let tmp = invoice2;
                    invoice2 = invoice1;
                    invoice1 = tmp;
                }

                if (_filterOptions?.invoiceFilter1) {
                    if (_filterOptions?.invoiceFilter2) {
                        _transactions = _transactions.filter((x) => Number(x.invoiceNumber) >= invoice1 && Number(x.invoiceNumber) <= invoice2);
                    }
                }
                break;

            // no default
        }

        return _transactions;
    };

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    const today = moment(new Date()).startOf("day");
    const currentPeriod = moment(today).subtract(30, "days");
    const period30Days = moment(today).subtract(60, "days");
    const period60Days = moment(today).subtract(90, "days");

    // -- Current Period (0 -30 days) -----------------------------------------------------------------------------------------------------
    const transactionsCurrent = accountTransactions?.filter((x) => moment(x.transactionDate).startOf("day") >= currentPeriod && x.paymentStatus !== "Pending");
    const balanceCurrent = transactionsCurrent?.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0) || 0;
    const balanceCurrentFilterOption = { dateFilter1: currentPeriod.toDate(), dateFilter2: null, dateOption: dateFilter.ON_OR_AFTER.value, balanceFilter1: "0.01", balanceFilter2: null, balanceOption: currencyFilter.GREATER_THAN_OR_EQUAL_TO.value };

    // -- 31 Days -------------------------------------------------------------------------------------------------------------------------
    const transactions30Days = accountTransactions?.filter((x) => moment(x.transactionDate).startOf("day") >= period30Days && moment(x.transactionDate).startOf("day") < currentPeriod && x.paymentStatus !== "Pending");
    const balance30Days = transactions30Days?.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0) || 0;
    const balance30DaysFilterOption = { dateFilter1: period30Days.toDate(), dateFilter2: currentPeriod.toDate(), dateOption: dateFilter.BETWEEN.value, balanceFilter1: "0.01", balanceFilter2: null, balanceOption: currencyFilter.GREATER_THAN_OR_EQUAL_TO.value };

    // -- 61 Days -------------------------------------------------------------------------------------------------------------------------
    const transactions60Days = accountTransactions?.filter((x) => moment(x.transactionDate).startOf("day") >= period60Days && moment(x.transactionDate).startOf("day") < period30Days && x.paymentStatus !== "Pending");
    const balance60Days = transactions60Days?.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0) || 0;
    const balance60DaysFilterOption = { dateFilter1: period60Days.toDate(), dateFilter2: period30Days.toDate(), dateOption: dateFilter.BETWEEN.value, balanceFilter1: "0.01", balanceFilter2: null, balanceOption: currencyFilter.GREATER_THAN_OR_EQUAL_TO.value };

    // -- 91 Days -------------------------------------------------------------------------------------------------------------------------
    const transactions90Days = accountTransactions?.filter((x) => moment(x.transactionDate).startOf("day") < period60Days && x.paymentStatus !== "Pending");
    const balance90Days = transactions90Days?.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0) || 0;
    const balance90DaysFilterOption = { dateFilter1: period60Days.toDate(), dateFilter2: null, dateOption: dateFilter.ON_OR_BEFORE.value, balanceFilter1: "0.01", balanceFilter2: null, balanceOption: currencyFilter.GREATER_THAN_OR_EQUAL_TO.value };

    // Set the background colour for the Account Balance background...
    let balanceClass = styles["account-balance"];
    if (balance90Days > 0) balanceClass = `${styles["account-balance"]} ${styles["account-balance-red"]}`;
    else if (balance60Days > 0) balanceClass = `${styles["account-balance"]} ${styles["account-balance-orange"]}`;
    else if (balance30Days > 0) balanceClass = `${styles["account-balance"]} ${styles["account-balance-yellow"]}`;

    return (
        <div className="billing-page">
            <AuthenticateUser>
                {pageError && (
                    <div className="text-center">
                        <h2 style={{ color: "red" }}>Error Loading Page</h2>
                        <div className="alert alert-danger">{pageError}</div>
                    </div>
                )}

                {!pageError && reloadTransactions && <PageLoading message="Fetching your account data, please wait..." />}

                {!pageError && !reloadTransactions && pageLoaded && (
                    // {!pageError && pageLoaded && (
                    <div>
                        <Card>
                            <CardHeader>Account Summary</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={3}>
                                        <Input id="accountNumber" label="Account Number" value={portalAccount.accountNumber} size={controlSize} readOnly={true} groupClass={styles["account-number"]} />
                                    </Col>

                                    <Col xs={3}>
                                        <div className={balanceClass}>
                                            <label className="label-sx form-label" htmlFor="accountBalance">Account Balance</label>
                                            <input type="text" id="accountBalance" name="accountBalance" className="form-control form-control-md" title="Pay total amount" value={currencyHelper.toCurrencyFormat(accBalance)} readOnly={true} />
                                            <button type="button" className={"btn btn-light btn-sm"} title="Pay total amount" disabled={accBalance <= 0} onClick={() => handlePayInvoices()}>Pay Now</button>
                                        </div>
                                    </Col>

                                    {balanceOverdue > 0 &&
                                        <Col xs={6}>
                                            <div style={{ height: "80%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "center", color: "red", fontSize: "1.5rem" }}>
                                                <p style={{ marginBottom: "0" }}>Please pay the overdue amount {currencyHelper.toCurrencyFormat(balanceOverdue)}</p>
                                                <div className={styles["flex-break"]} />
                                                <p style={{ fontSize: "1rem", fontStyle: "italic", marginBottom: "0" }}>Alternatively contact our Accounts team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339) (option 3) if you have any questions or to arrange payment.</p>
                                            </div>
                                        </Col>
                                    }
                                </Row>

                                <Row>
                                    <Col>
                                        <Input
                                            id="currentBalance"
                                            label="0-30 Days"
                                            value={currencyHelper.toCurrencyFormat(balanceCurrent)}
                                            size={controlSize}
                                            readOnly={true}
                                            groupClass="current-balance"
                                            append={
                                                <Button style={{ backgroundColor: "var(--branding-sunburst-orange)", border: "1px solid var(--branding-sunburst-orange)" }} onClick={() => handleFilterChanged(balanceCurrentFilterOption)} title="Display transactions for the last 30 days">
                                                    <FontAwesomeIcon icon="filter" />
                                                </Button>
                                            }
                                        />
                                    </Col>

                                    <Col>
                                        <Input
                                            id="balance30Days"
                                            label="31-60 Days"
                                            value={currencyHelper.toCurrencyFormat(balance30Days)}
                                            size={controlSize}
                                            readOnly={true}
                                            groupClass="day-30-balance"
                                            append={
                                                <Button style={{ backgroundColor: "var(--branding-sunburst-orange)", border: "1px solid var(--branding-sunburst-orange)" }} onClick={() => handleFilterChanged(balance30DaysFilterOption)} title="Display transactions for the last 30-60 days">
                                                    <FontAwesomeIcon icon="filter" />
                                                </Button>
                                            }
                                        />
                                    </Col>

                                    <Col>
                                        <Input
                                            id="balance60Days"
                                            label="61-90 Days"
                                            value={currencyHelper.toCurrencyFormat(balance60Days)}
                                            size={controlSize}
                                            readOnly={true}
                                            groupClass="day-60-balance"
                                            append={
                                                <Button style={{ backgroundColor: "var(--branding-sunburst-orange)", border: "1px solid var(--branding-sunburst-orange)" }} onClick={() => handleFilterChanged(balance60DaysFilterOption)} title="Display transactions for the last 60-90 days">
                                                    <FontAwesomeIcon icon="filter" />
                                                </Button>
                                            }
                                        />
                                    </Col>

                                    <Col>
                                        <Input
                                            id="balance90Days"
                                            label="91+ Days"
                                            value={currencyHelper.toCurrencyFormat(balance90Days)}
                                            size={controlSize}
                                            readOnly={true}
                                            groupClass="day-90-balance"
                                            append={
                                                <Button style={{ backgroundColor: "var(--branding-sunburst-orange)", border: "1px solid var(--branding-sunburst-orange)" }} onClick={() => handleFilterChanged(balance90DaysFilterOption)} title="Dis[play transactions older than 90+ days">
                                                    <FontAwesomeIcon icon="filter" />
                                                </Button>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className={styles["account-history"]}>
                            <CardHeader>
                                Account History
                                <button type="button" className="btn btn-sx btn-sm float-end" title="Filter the displayed transactions" onClick={() => handleFilterToggle()} disabled={accountTransactions?.length === 0}>
                                    <FontAwesomeIcon icon="filter" title="Filter invoice records" />
                                    &nbsp;Filter
                                </button>
                                {Object.keys(filterOptions).length > 0 && (
                                    <button type="button" className="btn btn-danger btn-sm float-end me-2" title="Clear all filters" onClick={() => handleFilterClear()}>
                                        <FontAwesomeIcon icon="trash" title="Clear all filters" />
                                        &nbsp;Clear
                                    </button>
                                )}
                            </CardHeader>
                            <CardBody>
                                {filteredTransactions?.length === 0 && Object.keys(filterOptions).length === 0 && <div>There are no current transactions for your account</div>}

                                {filteredTransactions?.length === 0 && Object.keys(filterOptions).length > 0 && <div>There are no transactions matching your criteria. Please modify the filter conditions to display the required transactions.</div>}

                                {filteredTransactions?.length > 0 && <BillingDataTable transactions={filteredTransactions} handlePayInvoice={handlePayInvoices} handleOptionsShow={handleOptionsShow} handlePayInvoices={handlePayInvoices} />}
                            </CardBody>
                        </Card>

                        <InvoiceOptions showInvoiceOptions={invoiceModal} invoiceNumber={invoiceNumber} closeOptions={handleOptionsClose} />

                        <InvoiceFilter showInvoiceFilter={showFilterModal} invoiceFilters={filterOptions} updateFilter={(_filterOptions) => handleFilterChanged(_filterOptions)} closeFilter={() => handleFilterClose()} controlSize={controlSize} />

                        <Payway isOpen={showPaywayModal} onCloseClick={handlePaymentClose} paymentTransactions={paymentTransactions} user={portalUser} accountNumber={portalAccount.axAccountNumber} accessToken={accessToken.token} onSuccess={handlePaymentSuccess} />
                    </div>
                )}
            </AuthenticateUser>
            {loader}
        </div>
    );
}
