import * as apiUrls from "./endPoints"
import moment from "moment"
import message from "./message";

class RestHelper {
    static async getErrorData(response) {
        let data = null;
        let errMsg = "";
        let errors = [];

        const contentType = response.headers.get("content-type");

        if (contentType) {
            if (contentType.includes("json")) {
                data = await response.json();
            }
            else if (contentType.includes("text")) {
                data = await response.text();
            }
        }
        else if (response.statusText) {
            data = response.statusText
        }
        else if (response.type) {
            data = `${response.status}: ${response.type}`;
        }

        if (data.errors) {
            if (data.errors[""]) {
                errors = data.errors[""];
            }

            else if (data.errors["$"]) {
                errors = data.errors["$"];
            }

            else {
                errors = data.errors;
            }

            if (Array.isArray(errors) && errors.length > 0) {
                for (const error of errors) {
                    errMsg += `${error}\n`;
                }
            } else {
                for (const prop in errors) {
                    if (Array.isArray(errors[prop]) && errors[prop].length) {
                        for (const error of errors[prop]) {
                            errMsg += `${error}\n`;
                        }
                    }
                }
            }
        }

        else {
            errMsg = data.message ?? data.title ?? data;
        }

        if (errMsg === "Failed to fetch") return "Failed to connect to the portal server."
        return errMsg;
    }

    static async getResponseData(response) {
        let data = null;

        if (response.status === 204) return data;

        const contentType = response.headers.get("content-type");

        if (contentType) {
            if (contentType.includes("application/json") || contentType.includes("application/problem+json")) {
                data = await response.json();
            }
            else if (contentType.includes("text/plain")) {
                data = await response.text();
            }
            else if (contentType.includes("application/pdf")) {
                data = await response.blob();
            }
        }

        return data;
    }

    static setDefaultHeaders(accessToken = null) {
        if (accessToken) {
            return { "Content-Type": "application/json", "Accept": "application/json", "Authorization": `Bearer ${accessToken}` };
        }
        else {
            return { "Content-Type": "application/json", "Accept": "application/json" };
        }
    }

    static async fetchData(url, type, data, accessToken) {
        try {
            const headers = this.setDefaultHeaders(accessToken);

            const response = await fetch(url, {
                method: type,
                mode: "cors",
                credentials: "omit",
                headers: headers,
                body: data ?? null
            });

            if (response.ok) {
                const data = await this.getResponseData(response);
                return data;
            }

            else {
                const errMsg = await this.getErrorData(response);

                if (response.status === 401)
                    message.Error("Authentication Error", errMsg);

                throw errMsg;
            }
        }

        catch (ex) {
            let errMsg;

            if (ex.message === "Failed to fetch")
                errMsg = "Failed to connect to the portal server.";
            else
                errMsg = ex.message ?? ex;

            throw errMsg;
        }
    }

    // -- Account Controller --------------------------------------------------------------------------------------------------------------
    static async getAccount(accountId, accessToken) {
        const url = `${apiUrls.GetAccount}/${accountId}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    static async getUser(userId, accessToken) {
        const url = `${apiUrls.GetUser}/${userId}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    static async logTransaction(transaction, accessToken) {
        const url = apiUrls.LogTransaction;
        transaction = JSON.stringify(transaction);

        try {
            const data = await this.fetchData(url, "post", transaction, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    static async submitPendingPayment(pendingPayment, accessToken) {
        const url = `${apiUrls.SubmitPendingPayment}`;

        try {
            const data = await this.fetchData(url, "post", JSON.stringify(pendingPayment), accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    static async switchAccount(accountNumber, accessToken) {
        const url = `${apiUrls.SwitchAccount}/${accountNumber}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    static async GetPortalHistory(accountId, accessToken) {
        const url = `${apiUrls.GetPortalHistory}/${accountId}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }
        catch (error) {
            throw error;
        }
    }

    // -- AX Controller -------------------------------------------------------------------------------------------------------------------
    static async getAccountTransactions(crmAccountNum, accessToken) {
        const url = `${apiUrls.GetTransactions}?id=${crmAccountNum}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            const accountTransactions = { accountBalance: 0, transactions: data };

            if (accountTransactions.transactions && accountTransactions.transactions.length) {
                accountTransactions.transactions = accountTransactions.transactions.sort((a, b) => {
                    return moment(a.transactionDate).diff(b.transactionDate) * -1;
                })

                accountTransactions.transactions = accountTransactions.transactions.map((invoice) => {
                    invoice.balance = invoice.paymentStatus === "Pending" ? invoice.amount : invoice.amount - (invoice.amountPaid || 0);

                    switch (invoice.paymentStatus) {
                        case "Paid":
                            invoice.paymentStatusDisplay = "Payment";
                            break;
                        case "Unpaid":
                            invoice.paymentStatusDisplay = "Invoice";
                            break;
                        default:
                            invoice.paymentStatusDisplay = invoice.paymentStatus;
                    }

                    return invoice;
                });

                const transactionsBalance = accountTransactions.transactions.filter(x => x.amount > 0 && x.paymentStatus !== "Pending");
                accountTransactions.accountBalance = transactionsBalance.reduce((total, x) => total + (x.amount - (x.amountPaid || 0)), 0);

                //limit number of records, because session storage is limited 5MB (allowing 500kb per record)
                if (accountTransactions.transactions.length > 8000) {
                    //filter past 12 months based on latest transaction
                    const latestTransaction = accountTransactions.transactions.reduce((a, b) => (a.transactionDate > b.transactionDate ? a : b));
                    const startDate = new Date(latestTransaction.transactionDate);

                    startDate.setFullYear(startDate.getFullYear() - 1);

                    const filteredTransactions = accountTransactions.transactions.filter(x => new Date(x.transactionDate) >= startDate).splice(0, 8000);
                    accountTransactions.transactions = filteredTransactions;
                }
            }

            return accountTransactions;
        }

        catch (error) {
            throw error;
        }
    }

    static async getInvoiceCount(id, accessToken) {
        const url = `${apiUrls.GetInvoiceCount}`.replace("<AccountNumber>", id);

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    static async getInvoiceList(id, accessToken) {
        const url = `${apiUrls.GetInvoiceList}/${id}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    // -- Audit Controller ----------------------------------------------------------------------------------------------------------------
    static async addAuditEntry(page, action, detail, accessToken) {
        const url = apiUrls.AddAuditEntry;

        try {
            const body = JSON.stringify({ "page": page, "action": action, "detail": detail });
            const data = await this.fetchData(url, "post", body, accessToken);
            return data;
        }

        catch (ex) {
            // Fail silently if an error occurs when adding an audit record...
            console.error(ex.message);
        }
    }

    // -- Account (CRM) Controller --------------------------------------------------------------------------------------------------------
    static async getIndustryCodes(accessToken) {
        const url = apiUrls.GetIndustryCodes;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (ex) {
            // Fail silently if an error occurs when adding an audit record...
            console.error(ex.message);
            //throw ex;
        }
    }

    static async updateAccount(account, accessToken) {
        const url = apiUrls.UpdateAccount;

        try {
            const data = await this.fetchData(url, "post", JSON.stringify(account), accessToken);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    static async invoicePayment(payment, user) {
        const url = apiUrls.InvoicePayment;

        try {
            const data = await this.fetchData(url, "post", JSON.stringify(payment), user);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    // -- Email Controller ----------------------------------------------------------------------------------------------------------------
    /**
     * Uses a standard email model to send emails using SendGrid.
     * @param {object} emailModel - The data used to submit an email.
     * @param {string} accessToken - The authorisation token required to authenticate the user.
     */
    static async sendGridEmail(emailModel, accessToken) {
        const url = apiUrls.SendGridEmail;

        try {
            const data = await this.fetchData(url, "post", JSON.stringify(emailModel), accessToken);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    static async submitPortalHistory(portalHistoryModel, token) {
        const url = apiUrls.SubmitPortalHistory;

        try {
            const data = await this.fetchData(url, "post", JSON.stringify(portalHistoryModel), token);
            return data;
        }

        catch (ex) {
            if (ex.message) throw ex.message;
            else throw ex;
        }
    }


    // -- Report Controller ---------------------------------------------------------------------------------------------------------------
    /**
     * Obtains a list of job IDs for those jobs that have a Certificate of Destruction (COD).
     * @param {string} accountId the Guid of the required account
     * @param {string} token the authentication token
     * @returns 
     */
    static async getCodList(accountId, token) {
        let data;

        try {
            const url = `${apiUrls.GetCodList}/${accountId}`;
            data = await this.fetchData(url, "get", null, token);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    static async getEcoReport(url, accountId, dateRange, token) {
        let data;

        try {
            const model = JSON.stringify({ accountId: accountId, dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo });
            data = await this.fetchData(url, "post", model, token);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    static async getServiceReport(url, salesOrderIds, token) {
        let data;

        try {
            const model = JSON.stringify(salesOrderIds);
            data = await this.fetchData(url, "post", model, token);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    // -- Service Controller --------------------------------------------------------------------------------------------------------------

    static async getClosedDates(accountId, token) {
        let data;

        try {
            const url = `${apiUrls.GetClosedDates}/${accountId}`;
            data = await this.fetchData(url, "get", null, token);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    static async getScheduledJobs(accountId, accessToken) {
        const url = `${apiUrls.GetScheduledJobs}/${accountId}`;

        try {
            const data = await this.fetchData(url, "get", null, accessToken);
            return data;
        }

        catch (error) {
            throw error;
        }
    }

    /**
     * Locates the service history (sales order) records beginning from 12 months ago.
     * @param  {String} accountId the Guid of the required account
     * @param  {String} accessToken the authentication token
     * @return {Object} An array of SalesOrderVm objects
     */
    static async getServiceHistory(accountId, accessToken) {
        const url = `${apiUrls.GetServiceHistory}/${accountId}`;

        try {
            let _salesOrders = await this.fetchData(url, "get", null, accessToken);

            if (_salesOrders?.length > 0) {
                let id = 0;

                _salesOrders = _salesOrders.map((salesOrder) => {
                    salesOrder.id = ++id;

                    salesOrder.displayStatus = salesOrder.status;
                    switch (salesOrder.status) {
                        case "Pending":
                        case "Booked":
                            salesOrder.displayStatus = "Scheduled";
                            break;
                        case "Finalized":
                            salesOrder.displayStatus = "Completed";
                            break;
                        default:
                            break;
                    }

                    salesOrder.description = "";

                    if (salesOrder.orderDetails.length === 1) {
                        salesOrder.description = salesOrder.orderDetails[0].productDescription;
                    }

                    else if (salesOrder.orderDetails.length > 1) {
                        salesOrder.description = "Multiple Services"
                    }

                    return salesOrder;
                });
            }

            return _salesOrders;
        }

        catch (error) {
            throw error;
        }
    }

    static async restoreClosedDate(accountId, recordId, token) {
        let data;

        try {
            const url = `${apiUrls.RestoreClosedDates}/${accountId}/${recordId}`;
            data = await this.fetchData(url, "get", null, token);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    static async saveClosedDates(model, token) {
        let data;

        try {
            if (!model.toDt) model.toDt = null;
            data = await this.fetchData(apiUrls.SaveClosedDates, "post", JSON.stringify(model), token);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }

    // -- Registration Controller ---------------------------------------------------------------------------------------------------------
    static async verifyRegistration(registrationId) {
        let data;

        try {
            const url = `${apiUrls.VerifyRegistrationLink}/${registrationId}`;
            data = await this.fetchData(url, "get", null, null);
            return data;
        }

        catch (ex) {
            throw ex.message ?? ex;
        }
    }
}

export default RestHelper;