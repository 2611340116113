import React, { useState } from "react";
import SiteContainer from "../common/SiteContainer.Component";
import { Collapse } from "reactstrap";

export const PrivacyPolicyPage = () => {
    document.title = "Shred-X: Privacy Policy";

    const [section1Open, section1OpenSet] = useState(false);
    const [section2Open, section2OpenSet] = useState(false);
    const [section3Open, section3OpenSet] = useState(false);
    const [section4Open, section4OpenSet] = useState(false);
    const [section5Open, section5OpenSet] = useState(false);
    const [section6Open, section6OpenSet] = useState(false);
    const [section7Open, section7OpenSet] = useState(false);
    const [section8Open, section8OpenSet] = useState(false);
    const [section9Open, section9OpenSet] = useState(false);
    const [section10Open, section10OpenSet] = useState(false);
    const [section11Open, section11OpenSet] = useState(false);

    return (
        <SiteContainer>
            <div style={{ margin: "0 10%" }}>
                <div style={{ textAlign: "left", color: "var(--branding-sunburst-orange)" }}>
                    <h2>PRIVACY POLICY</h2>
                </div>

                <div>
                    <h4 style={{ color: "var(--branding-sunburst-orange)" }}>Policy Statement Overview</h4>

                    <p>This policy sets out how Shred-X Pty Ltd responsibility on collection, utilisation and disclosure of personal information.</p>

                    <p>Our Privacy Policy complies with the Australian Privacy Principles set out in the Privacy Amendment (Enhancing Privacy Protection) Act 2012, and explains how the User’s personal information will be managed when dealing with Shred-X.</p>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section1OpenSet(!section1Open)}>
                                {section1Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Utilisation and Disclosure of Your Personal Information</h4>
                        </section>

                        <Collapse isOpen={section1Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X discloses personal information:
                                    <ul>
                                        <li>for the primary purpose for which it was collected or</li>
                                        <li>where the individual would reasonably expect this or</li>
                                        <li>where the individual has consented or</li>
                                        <li>for direct marketing by Shred-X, but giving individuals the opportunity to opt out of such direct marketing; Shred-X includes its contact details in any direct marketing</li>
                                        <li>Shred-X does not disclose your personal information for any secondary purposes unless your consent has been given or as required by law.</li>
                                        <li>Shred-X will not sell or license any personal information that it collects from you.</li>
                                    </ul>
                                </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section2OpenSet(!section2Open)}>
                                {section2Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Collection of Personal Information</h4>
                        </section>

                        <Collapse isOpen={section2Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X will collect personal information as follows:</p>

                                <ul>
                                    <li>your name, address and contact details</li>
                                    <li>your credit or debit account details</li>
                                    <li>user ID’s and passwords</li>
                                    <li>any goods or services provided to you</li>
                                    <li>records of your communications with Shred-X</li>
                                    <li>website usage information</li>
                                </ul>

                                <p>The primary purpose of collecting your personal information is for Shred-X’s business and marketing operations, which includes providing the user with advice on Shred-X’s goods and services, communication interface, and business innovations. </p>

                                <p>Personal information is only collected:</p>

                                <ul>
                                    <li>if necessary for Shred-X’s operations</li>
                                    <li>by lawful and fair means</li>
                                    <li>where practicable, only from the individual concerned</li>
                                </ul>

                                <p>Shred-X takes all reasonable steps to ensure that you are aware of the following provisions:</p>

                                <ul>
                                    <li>the likely use of the information</li>
                                    <li>the right of access to the information</li>
                                    <li>the identity and contact details of the organisations</li>
                                    <li>any law requiring collection of the information; and</li>
                                    <li>the main consequences of failure to provide the information</li>
                                </ul>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section3OpenSet(!section3Open)}>
                                {section3Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Collection of Information Other than Personal Information</h4>
                        </section>

                        <Collapse isOpen={section3Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <h6 style={{ color: "var(--branding-sunburst-orange)" }}>CUSTOMER PORTAL SITE VISIT INFORMATION </h6>

                                <p>Within the Shred-X customer portal/website, there will be general information about access visits which may include server address, the date and time of access visit, the pages that are accessed, the information that has been downloaded and the type of Internet browser utilised. Shred-X may use this information in anonymous, aggregated form, for statistical purposes only, to assist us in improving the quality and usability of our portal/site. </p>

                                <h6 style={{ color: "var(--branding-sunburst-orange)" }}>COOKIES</h6>

                                <p>A cookie is a small string of information that a website transfers to the browser for identification purposes. The cookies that Shred-X use may identify individual users. </p>

                                <p>Cookies can either be “persistent” or “session” based. Persistent cookies are stored on the user’s computer, contain an expiration date, and are mainly for the user’s convenience. Session cookies are short-lived and are held on your browser’s memory only for the duration of your session; they are used only during a browsing session and expire when you quit your browser. </p>

                                <p>Shred-X may use both session and persistent cookies. This information may be used to personalise the user’s current visit to our portal/websites. Upon closing your browser, the session cookie is destroyed. </p>

                                <p>Most Internet browsers can be set to accept or reject cookies. If the user does not want to accept cookies, they can adjust their Internet browser to reject cookies or to be notified when they are being used. However, rejecting cookies may limit the functionality of our portal/website. </p>

                                <h6 style={{ color: "var(--branding-sunburst-orange)" }}>GOOGLE ANALYTICS COOKIES</h6>

                                <p>Shred-X uses Google Analytics to analyse the use of this portal/website. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users’ computers. The information generated relating to our portal/website is used to create reports about the use of the portal/website. Google will store and use this information. Google’s privacy policy is available at <a href="http://www.google.com/privacypolicy.html" target="blank">http://www.google.com/privacypolicy.html</a>.</p>

                                <h6 style={{ color: "var(--branding-sunburst-orange)" }}>GOOGLE ADWORDS’ REMARKETING</h6>

                                <p>Shred-X publishes Google AdWords’ Remarketing interest-based advertisements on other websites. This portal/website may use a remarketing tag to advertise online. This means that Google and other third-party vendors may show our ads to you on sites across the Internet. These third-party vendors, including Google & DoubleClick, may use cookies to serve ads to you based upon your past visits to our portal/website.</p>

                                <p>If you would like to opt out of Google’s use of cookies, you can visit the company’s Ad Preferences Manager at <a href="https://www.google.com/ads/preferences" target="blank">https://www.google.com/ads/preferences</a>.</p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section4OpenSet(!section4Open)}>
                                {section4Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Accurate and Up-To-Date Information</h4>
                        </section>

                        <Collapse isOpen={section4Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X takes reasonable steps to ensure information is accurate and up to date by updating its records whenever changes to the data come to its attention. Shred-X disregards information which seems likely to be inaccurate or out-of-date by reason of the time which has elapsed since it was collected or by reason of any other information in its possession. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section5OpenSet(!section5Open)}>
                                {section5Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Security of Your Personal Information</h4>
                        </section>

                        <Collapse isOpen={section5Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X protects personal information from misuse or loss by restricting access to the information in electronic format, and by appropriate physical and communications security. Any data destroyed is disposed of in a manner that protects the privacy of information in an appropriate manner. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section6OpenSet(!section6Open)}>
                                {section6Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Dealing With Unsolicited Information</h4>
                        </section>

                        <Collapse isOpen={section6Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X takes all reasonable steps to ensure that all unsolicited information is destroyed immediately. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section7OpenSet(!section7Open)}>
                                {section7Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Access to Your Personal Information</h4>
                        </section>

                        <Collapse isOpen={section7Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X acknowledges that individuals have a general right of access to information concerning them, and to have inaccurate information corrected. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section8OpenSet(!section8Open)}>
                                {section8Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Anonymity When Dealing With Shred-X</h4>
                        </section>

                        <Collapse isOpen={section8Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X allows individuals the option not to identify themselves when dealing with it, where practicable. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section9OpenSet(!section9Open)}>
                                {section9Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Cross Border Disclosure</h4>
                        </section>

                        <Collapse isOpen={section9Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>The User’s personal information may also be processed by or disclosed to employees or other third parties operating outside of Australia who work for Shred-X in countries including New Zealand, or by the representatives and employees of Shred-X’s parent company. Shred-X will take reasonable steps, in the circumstances, before personal information is disclosed to an overseas recipient, to ensure that the overseas recipient does not breach privacy laws in relation to your personal information (‘the Required Steps’).The Required Steps do not apply if the User consents to the disclosure of your personal information to an overseas recipient. By supplying your personal information to Shred-X, you consent to the disclosure of your personal information to an overseas recipient and agree that the Required Steps do not apply. If the User consents to the disclosure of your personal information to an overseas recipient, the overseas recipient will not be accountable under the Privacy Act, and you will not be able to seek redress for breaches under the Privacy Act. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section10OpenSet(!section10Open)}>
                                {section10Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Collecting Sensitive Information</h4>
                        </section>

                        <Collapse isOpen={section10Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>Shred-X does not collect sensitive information, unless it is specifically relevant and necessary for the purpose of Shred-X’s business operation. All sensitive information that is collected is used in accordance with this privacy policy. Shred-X does not use government identifiers (e.g. tax file numbers) to identify individuals. </p>
                            </div>
                        </Collapse>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                        <section className="d-inline-flex flex-row align-items-center">
                            <button className="btn btn-sm btn-sx me-2" onClick={() => section11OpenSet(!section11Open)}>
                                {section11Open ? "-" : "+"}
                            </button>
                            <h4 style={{ marginBottom: "0", color: "var(--branding-sunburst-orange)" }}>Privacy Contact Details</h4>
                        </section>

                        <Collapse isOpen={section11Open}>
                            <div style={{ margin: "10px 10px 10px 40px" }}>
                                <p>All information about privacy issues can be forwarded to: </p>

                                <p>
                                    Privacy Officer<br />
                                    Shred-X Pty Ltd<br />
                                    Email: <a href="mailto:info@shred-x.com.au">info@shred-x.com.au</a>
                                </p>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </SiteContainer>
    )
}

export default PrivacyPolicyPage;