import React from "react";
import SiteContainer from "../common/SiteContainer.Component";

export const TermsOfUsePage = () => {
    document.title = "Shred-X: Terms of Use";

    return (
        <SiteContainer>
            <div style={{ margin: "0 10%" }}>
                <div style={{ textAlign: "left", color: "var(--branding-sunburst-orange)" }}>
                    <h2>TERMS OF USE</h2>
                </div>

                <p>Your use of this website ("Site/Customer Portal") is subject to the following Terms of Use ("Terms")</p>

                <h3 className="mt-2" style={{color: "var(--branding-sunburst-orange)"}}>Acceptance of Terms of Use:</h3>

                <ol>
                    <li>The Site is owned and operated by Shred-X. Your access to and use of the Site is subject to these Terms, our privacy policy (which is incorporated into and forms part of these Terms) and all applicable laws. By accessing or using the Site, you accept the Terms without limitation or qualification. If you do not agree to all the Terms of use, please do not access, or use the Site. </li>
                    <li>Shred-X may, from time to time and in its sole discretion, modify, alter, or otherwise change the Terms or our privacy policy with or without notice to you. All such changes will be effective immediately upon the updating of the page at which the Terms or the privacy policy are contained. You agree to check the Terms and privacy policy and be bound by such terms as are in effect at the time which you access the Site. </li>
                </ol>

                <h3 className="mt-2" style={{color: "var(--branding-sunburst-orange)"}}>PINs/Passwords: </h3>

                <ol>
                    <li>You will be issued log in details for use of the Site.</li>
                    <li>You agree to take all reasonable precautions to protect the security and integrity of your log in details and to prevent its unauthorised use. You agree to be responsible for all actions taken using your log in details. If you become aware of any unauthorised use of your log in, you will immediately notify Shred-X. </li>
                </ol>

                <h3 className="mt-2" style={{color: "var(--branding-sunburst-orange)"}}>Intellectual Property:</h3>

                <ol>
                    <li>Unless expressly stated in terms applicable to specific portions of the Site, no part of the Site may be reproduced, republished, uploaded, posted, stored, distributed, or transmitted in any form or by any means (electronic, mechanical or otherwise), or modified in whole or part, whether in text, graphical, audio, video or executable form, without the express prior written permission from Shred-X. Requests for permission or further information should be sent to <a href="mailto:info@shred-x.com.au" style={{ textDecoration: "underline", color: "var(--branding-sunburst-orange)" }}>info@shred-x.com.au</a>.</li>
                    <li>All trademarks on the Site are registered or unregistered trademarks Shred-X or others and are the property of their respective owners. Nothing contained in the Site is to be construed as granting any license or right of use in respect of any trademark. </li>
                </ol>

                <h3 className="mt-2" style={{color: "var(--branding-sunburst-orange)"}}>External Websites:</h3>

                <ol>
                    <li>You acknowledge and agree that links and references to external websites are provided to you as a convenience only. Shred-X has not reviewed and does not endorse or assume any responsibility for any other websites including material posted on them or products or services offered on those websites unless owned by Shred-X.</li>
                </ol>

                <h3 className="mt-2" style={{color: "var(--branding-sunburst-orange)"}}>No Liability:</h3>
                <ol>
                    <li>Shred-X does not warrant that functions contained in the Site will be uninterrupted or error free or that any defects will be corrected.</li>
                    <li>To the maximum extent allowable by law, Shred-X hereby excludes any and all liability relating to your and other party's use of the Site, including any direct, special, incidental, indirect or consequential damages whatsoever arising out of a breach of the Terms or arising out of your or any other party's use of the Site or integration of the Site or any part or function of the Site into your or any other party's website including any damages resulting from loss of use, data or profits, delays or business interruption, or any reliance on the Site by you or any person.</li>
                    <li>Shred-X reserves the right at any time to change the "call interfaces" to the Site or any other aspect of the Site and does not warrant that new versions of the Site will be compatible with previous versions.</li>
                </ol>

                <h3 className="mt-2" style={{color: "var(--branding-sunburst-orange)"}}>Privacy:</h3>
                <ol>
                    <li>Shred-X respects your rights to privacy. Details of how we use information about you are set out in our <a href="/PrivacyPolicy" target="privacy" style={{ textDecoration: "underline", color: "var(--branding-sunburst-orange)" }}>Privacy Policy</a>.</li>
                </ol>
            </div>
        </SiteContainer>
    )
}

export default TermsOfUsePage;