import EmailHelper from "./emailHelper";
import PhoneHelper from "./phoneHelper";

class DataValid {
    static validateData(dataType, value) {
        if (value) value = value.trim();

        switch (dataType.type) {
            case "text":
                if (dataType.required && !value) return false;
                if (dataType.minLength && value?.length < dataType.minLength) return false;
                if (dataType.maxLength && value?.length > dataType.maxLength) return false;
                return true;

            case "phone":
                if (!PhoneHelper.isValid(value)) return false;
                return true;

            case "email":
                if (!EmailHelper.isValidEmail(value)) return false;
                return true;

            //no default
        }
    }
}

export default DataValid;