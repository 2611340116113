import React from "react";
import parse from "html-react-parser";

// Documentation: https://github.com/fkhadra/react-toastify
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

class Message {
    static replaceNewLine(message) {
        if (!message) return message;

        while (message.includes("\n\r") || message.includes("\r\n")) {
            message = message.replace("\n\r", "<br/>");
            message = message.replace("\r\n", "<br/>");
        }

        while (message.includes("\n") || message.includes("\r")) {
            message = message.replace("\n", "<br/>");
            message = message.replace("\r", "<br/>");
        }

        return message;
    }

    static formatMessage(title, message) {
        while (message.includes("<br/>") || message.includes("<br />")) {
            message = message.replace("<br/>", "\n");
            message = message.replace("<br />", "\n");
        }

        var lines = message.split("\n");

        const Msg = () => (
            <section>
                <h4>{parse(title)}</h4>
                {lines.map((line, index) =>
                    line.length === 0 ? null : <p key={index} style={{ wordBreak: "break-word" }}>{parse(line)}</p>
                )}
            </section>
        );

        return Msg;
    }

    static Display(msg) {
        switch (msg.type.toLowerCase()) {
            case "error":
                this.Error(msg.title, msg.text);
                break;
            case "information":
                this.Information(msg.title, msg.text);
                break;
            case "success":
                this.Success(msg.title, msg.text);
                break;
            default:
                this.Warning(msg.title, msg.text);
                break;
        }
    }

    static Error(title, message) {
        const Msg = this.formatMessage(title, message.message ?? message);
        toast.error(<Msg />, { autoClose: false, position: toast.POSITION.TOP_RIGHT });
    }

    static Information(title, message) {
        const Msg = this.formatMessage(title, message.message ?? message);
        toast.info(<Msg />, { autoClose: 6000, position: toast.POSITION.TOP_RIGHT });
    }

    static Success(title, message) {
        const Msg = this.formatMessage(title, message.message ?? message);
        toast.success(<Msg />, { autoClose: 6000, position: toast.POSITION.TOP_RIGHT });
    }

    static Warning(title, message, toastId = null) {
        if (!toastId || !toast.isActive(toastId)) {
            const Msg = this.formatMessage(title, message.message ?? message);
            toastId = toast.warning(<Msg />, { autoClose: false, position: toast.POSITION.TOP_RIGHT });
        }

        return toastId;
    }

    static Dismiss(toastId) {
        if (!toastId) return;
        toast.dismiss(toastId);
    }

    static IsActive(toastId){
        const isActive = toast.isActive(toastId);
        return isActive;
    }
}

export default Message;