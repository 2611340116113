import { useState, useCallback } from "react";
import { useRecoilState } from "recoil";
import { accessTokenState } from "../Shared/atom";
import message from "../Shared/message";

const RETRY_ERROR = "\nPlease try reloading the page and if the problem persists contact Customer Service on 1300&nbsp;SHREDX (1300&nbsp;747&nbsp;339) for assistance.";

/**
 * Http hook to request data from REST api endpoints
 * 
 * @param {boolean} isCached - flag to ignore http request if/when the data has been cached to local storage
 */

const useHttp = (errorTitle = "Data", showErrorMessage = false) => {
    const [isLoading, isLoadingSet] = useState(false);
    const [responseData, responseDataSet] = useState(null);
    const [accessToken] = useRecoilState(accessTokenState);

    /**
     * @param {string} url
     * @param {string} method - eg. get, put, post, delete
     * @param {any} body - payload (optional)
     * @returns {promise} - returns the response data
     */
    const request = useCallback((url, method = "get", body = null, options) => {
        return new Promise((resolve, reject) => {
            // Guard statements...
            if (!url) {
                reject("Url is missing");
                return;
            }

            if (!options?.noAuth && accessToken && !accessToken.token) {
                reject("Access Token is missing");
                return;
            }

            // Build the headers...
            let headers = null;

            if (options && options.mode && options.mode === "cors") {
                headers = {};
            } else {
                headers = { "Content-Type": options?.contentType || "application/json", Accept: "application/json" };
            }

            if (!options?.noAuth && accessToken && accessToken.token) {
                headers.Authorization = `Bearer ${accessToken.token}`;
            }

            isLoadingSet(true);

            // Make the call...
            fetch(url, {
                method: method,
                mode: options?.mode || "cors",
                credentials: "omit",
                headers: headers,
                body: body,
            })

                .then(response => {
                    // NOTE: While the settled state of a promise can't be changed after it has been resolved or rejected, the remainder of the code will continue to execute so always 
                    // include a return once it has been resolved or rejected...

                    // No content...
                    if (response.status === 204) {
                        isLoadingSet(false);
                        resolve(null);
                        return;
                    }

                    else {
                        let data;
                        const contentType = response.headers.get("content-type");

                        // If there is no content type and the response status is 200...
                        if (!contentType && response.status === 200) {
                            isLoadingSet(false);
                            resolve(true);
                            return;
                        }

                        // Get the data from the content returned...
                        if (contentType) {
                            if (!contentType || contentType.includes("application/json") || contentType.includes("application/problem+json")) {
                                data = response.json();
                            }

                            else if (contentType.includes("text/plain") || contentType.includes("text/javascript")) {
                                data = response.text();
                            }

                            else if (contentType.includes("application/pdf")) {
                                data = response.blob();
                            }
                        }

                        isLoadingSet(false);

                        if (!data) {
                            const params = JSON.stringify({ url, method, body });
                            reject(`${errorTitle} Bad request or content type: ${params}`);
                            return;
                        }

                        data.then(data => {
                            // Call successfully returned data...
                            if (response.ok) {
                                responseDataSet(data);
                                resolve(data);
                                return;
                            }

                            // Determine if any error was returned...
                            else {
                                let errMsg = "";

                                // If the error is a string...
                                if (typeof (data) !== "string") {
                                    if (!response.statusText) {
                                        errMsg = data;
                                    }

                                    else if (response.statusText) {
                                        data = response.statusText;
                                    }

                                    else if (response.type) {
                                        data = `${response.status}: ${response.type}`;
                                    }
                                }

                                // If the error is an object...
                                if (data.errors) {
                                    let errors;

                                    // If the object has an array of errors...
                                    if (data.errors[""]) {
                                        errors = data.errors[""];
                                    }

                                    else if (data.errors["$"]) {
                                        errors = data.errors["$"];
                                    }

                                    else {
                                        errors = data.errors;
                                    }

                                    // If "errors" is an array and has any elements...
                                    if (Array.isArray(errors) && errors.length > 0) {
                                        for (const error of errors) {
                                            errMsg += `${error}\n`;
                                        }
                                    }

                                    // If "errors" has any properties which are themselves arrays...
                                    else {
                                        for (const prop in errors) {
                                            if (Array.isArray(errors[prop]) && errors[prop].length) {
                                                for (const error of errors[prop]) {
                                                    errMsg += `${error}\n`;
                                                }
                                            }
                                        }
                                    }
                                }

                                else {
                                    errMsg = data.message ?? data.title ?? data;
                                }

                                if (response.status === 401) {
                                    errMsg = `Authentication Error: ${errMsg}.`;

                                    if (showErrorMessage) {
                                        message.Display({
                                            type: "error",
                                            title: "Authentication Error",
                                            text: `Failed to ${method} the ${errorTitle}.${RETRY_ERROR}`,
                                        });
                                    }
                                }

                                else if (showErrorMessage) {
                                    message.Display({
                                        type: "error",
                                        title: errorTitle,
                                        text: `Failed to ${method} the ${errorTitle}.${RETRY_ERROR}`
                                    });

                                    throw new Error("type", "message");
                                }

                                // const params = JSON.stringify({ url, method, body });
                                reject(errMsg || `${errorTitle} Failed ${method}ing Page Data`);
                                return;
                            }
                        });
                    }
                })

                .catch((error) => {
                    reject(error);
                });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    return {
        request,
        responseData,
        isLoading
    };
};

export default useHttp;
