export const numberFilter = {
    EQUAL_TO: {value: 1, text: "Equal to"},
    STARTS_WITH: {value: 2, "text": "Starts with"},
    ENDS_WITH: {value: 3, "text": "Ends with"},
    CONTAINS: {value: 4, "text": "Contains"},
    BETWEEN: {value: 5, "text": "Between"},
}

export const dateFilter = {
    ON_OR_AFTER: {value: 1, text: "On or after"},
    ON_OR_BEFORE: {value: 2, text: "On or before"},
    EQUAL_TO: {value: 3, text: "Equal to"},
    BETWEEN: {value: 4, "text": "Between"},
}

export const currencyFilter = {
    GREATER_THAN_OR_EQUAL_TO: {value: 1, text: "Greater than or equal to"},
    LESS_THAN_OR_EQUAL_TO: {value: 2, text: "Less than or equal to"},
    EQUAL_TO: {value: 3, text: "Equal to"},
    BETWEEN: {value: 4, text: "Between"},
}

export const storageKeys = {
    INDUSTRY_CODES: "SxIndustryCodes"
}