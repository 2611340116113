import React from "react"
import Input from "../common/Input.Component";

const FilterFields = ({id, size, type, value1, value2, filterOption, filterCheck, onChange, onBlur, groupClass, controlClass}) => {
    let fields = [];
    const fieldCount = filterOption === filterCheck ? 2 : 1;

    for (let i = 0; i < fieldCount; i++) {
        fields.push(
            <Input 
                key={i}
                id={`${id}${i + 1}`}
                type={type ?? "input"}
                value={i === 0 ? value1 : value2}
                size={size ?? "md"}
                groupClass= {`${groupClass || ""} px-2`}
                controlClass= {`${controlClass || ""} form-control w-100`}
                onChange={onChange}
                onBlur={onBlur ? onBlur : null} />
        )
    }

    return <>{fields}</>;
}

export default FilterFields;