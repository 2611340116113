import React, { useState } from "react";

import { useRecoilState } from "recoil";
import { accessTokenState, serviceHistoryState, scheduledJobsState, accountState, accountTransactionsState, menuState, userState } from "../../Shared/atom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Row, Col } from 'reactstrap';

import logo from "../../Images/SDX-banner-logo.png";
import bannerJoin from "../../Images/banner-join.jpg";

import message from "../../Shared/message";
import restHelper from "../../Shared/restHelper";

import useFullPageLoader from "../../Hooks/useFullPageLoader";
import DateHelper from "../../Shared/dateHelper";

const SiteHeader = () => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [menuStatus, menuStatusSet] = useRecoilState(menuState);
    const [portalAccount, portalAccountSet] = useRecoilState(accountState);
    const [portalUser] = useRecoilState(userState);

    const [, accountTransactionsSet] = useRecoilState(accountTransactionsState);
    const [, scheduledJobsSet] = useRecoilState(scheduledJobsState);
    const [, serviceHistorySet] = useRecoilState(serviceHistoryState);

    // -- Page State ----------------------------------------------------------------------------------------------------------------------
    const [accountNumber, accountNumberSet] = useState('');
    const [dropdownOpen, dropdownOpenSet] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const SWITCH_ACCOUNT = "Switch Account";

    const handleFocus = (evt) => evt.target.select();
    const toggle = () => dropdownOpenSet(prevState => !prevState);

    const onMenuChange = () => {
        switch (menuStatus) {
            case "":
            case "expanded":
                menuStatusSet("collapsed");
                break;

            case "collapsed":
                menuStatusSet("hidden");
                break;

            default:
                menuStatusSet("expanded");
                break;
        }
    }

    const switchAccount = async () => {
        try {
            if (!accountNumber) {
                message.Information(SWITCH_ACCOUNT, "Please enter an account number.");
                return;
            }

            if (portalAccount?.accountNumber === accountNumber) {
                message.Information(SWITCH_ACCOUNT, `"${accountNumber}" is the currently loaded account.`);
                return
            }

            showLoader();

            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Switching to account ${accountNumber}...`);
            const accountId = await restHelper.switchAccount(accountNumber, accessToken.token);

            if (!accountId) throw Error("Failed to locate an account for the specified account number.");

            console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Loading CRM account data...`);
            const _portalAccount = await restHelper.getAccount(accountId, accessToken.token);

            if (_portalAccount) {
                // Clearing the Recoil states will force any page currently displayed to update the data...
                accountTransactionsSet(null);
                serviceHistorySet(null);
                scheduledJobsSet(null);

                console.log(`${DateHelper.FormatDate(new Date(), "LogDate")} Updating portal account...`);
                portalAccountSet(_portalAccount);
            }

            else {
                throw new Error("Failed to locate an account for the specified account ID.")
            }
        }

        catch (ex) {
            const msg = `${(ex.message ?? ex)}\n\nPlease check the account number is valid and try again.\n\nIf the error persists contact Help Desk for assistance.`;
            message.Error(SWITCH_ACCOUNT, msg);
        }

        finally {
            hideLoader();
        }
    }

    const signOut = () => {
        if (isAuthenticated) {
            instance.logoutRedirect();
            sessionStorage.clear();
        }
    }

    const headerAccount = portalAccount?.accountName
        ? `${portalAccount.accountName} (${portalAccount.accountNumber})`
        : "";

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    // When the privacy or terms of use pages are displayed the access token is unavailable as these are "un-authenticated" pages.
    // Consequently the Switch option isn't displayed...
    const switchOption = (
        <>
            <div className='input-group' style={{ "minWidth": "150px" }}>
                <Input placeholder="Account Number" id="portalAccountNumber" value={accountNumber} onFocus={(evt) => handleFocus(evt)} onChange={(e) => accountNumberSet(`${e.target.value.toUpperCase()}`)} onKeyUp={(event) => { if (event.key === 'Enter') { switchAccount(); } }} />
            </div>

            <div className="input-group-append">
                <button type="button" className="btn btn-success me-1" title={SWITCH_ACCOUNT} disabled={!accountNumber || !accessToken?.token} onClick={() => switchAccount()}>
                    <FontAwesomeIcon icon="random" />
                </button>
            </div>
        </>
    )

    return (
        <Row className="page-banner no-gutters flex-sm-nowrap m-0">
            <Col xs="auto" className="align-self-end ms-4 mb-2">
                <FontAwesomeIcon icon="bars" style={{ color: "#fff" }} onClick={onMenuChange} />
            </Col>

            <Col xs="auto">
                <img className="logo align-self-center" src={logo} alt="Shred-X Logo" />
                <h4 className="d-block d-sm-none pl-5 pr-1">{headerAccount}</h4>
            </Col>

            <Col sm="auto" className="d-none d-sm-block p-0">
                <img src={bannerJoin} alt="" />
            </Col>

            <Col className="banner-fill flex-grow-1 d-flex flex-column flex-md-row align-items-end justify-content-end justify-content-md-between">
                <h4 className="d-none d-sm-block align-self-start align-self-md-end pr-1 text-nowrap pb-md-1">{headerAccount}</h4>

                <div className="d-flex align-items-center me-2 py-2 px-1">
                    {portalUser?.isStaff && !!accessToken.token && switchOption}

                    {portalUser &&
                        <div>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle caret>
                                    {portalUser?.displayName ?? ""}
                                </DropdownToggle>

                                <DropdownMenu end>
                                    <DropdownItem onClick={() => signOut()}>Sign out</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    }
                </div>
            </Col>
            
            {loader}
        </Row>
    );
}

export default SiteHeader;
