import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser';

import { useRecoilState } from "recoil";
import { accessTokenState, userState, isStaffState } from "../../Shared/atom";

import PageLoading from "../common/PageLoading.Component";

import * as authHelper from "../../Authentication/authenticationHelper";
import message from "../../Shared/message";
import useHttp from '../../Hooks/useHttp';
import * as apiUrls from "../../Shared/endPoints";
import restHelper from "../../Shared/restHelper";

export const AuthenticateUser = (props) => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken, accessTokenSet] = useRecoilState(accessTokenState);
    const [portalUser, portalUserSet] = useRecoilState(userState);
    const [isStaff, isStaffSet] = useRecoilState(isStaffState);

    // -- Local State ---------------------------------------------------------------------------------------------------------------------
    const [errTitle, errTitleSet] = useState("Authentication Error")
    const [pageError, pageErrorSet] = useState(null);
    const [pageLoaded, pageLoadedSet] = useState(null);

    const { request: submitAuditEntry } = useHttp();

    const CUSTOMER_SERVICE = "Please try logging in again and if unsuccessful contact Customer Service on 1300 SHREDX (1300&nbsp;747&#8209;339) for assistance."

    useEffect(() => {
        const loadPage = async () => {
            try {
                const isAuthenticated = authHelper.isAuthenticated();
                if (!isAuthenticated) throw Error(`Failed to authenticate using the user name and password provided.\n\n${CUSTOMER_SERVICE}`)

                const account = authHelper.getLoginAccount();
                const _accessToken = await authHelper.getAccessToken(accessToken, isStaff);
                if (_accessToken === null) throw Error(`Failed to access the token for your account.\n\n${CUSTOMER_SERVICE}`)

                if (_accessToken.expires !== accessToken?.expires) {
                    _accessToken.sub = account.idTokenClaims.sub;
                    _accessToken.idp = account.idTokenClaims.idp ?? null;

                    // If the identity provider is Freightways set "isStaff" to true...
                    isStaffSet(_accessToken?.idp?.includes("freightways.okta.com"));

                    pageErrorSet(null);

                    accessTokenSet(_accessToken);
                }
            }

            catch (ex) {
                console.error(ex);
                pageErrorSet(ex.message ?? ex);
                pageLoadedSet(false);
            }
        }

        loadPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getUser = async () => {
            if (portalUser?.userId !== accessToken.sub) {
                try {
                    const userData = await restHelper.getUser(accessToken.sub, accessToken.token);
                    if (!userData) throw new Error(`Failed to locate a registered user with the credentials provided.\n\n${CUSTOMER_SERVICE}`);

                    if (!portalUser) { // First time access - add audit entry
                        submitAuditEntry(apiUrls.AddAuditEntry, "post", JSON.stringify({ "page": "Login", "action": "Authentication", "detail": null }));
                    }

                    portalUserSet(userData);
                }

                catch (ex) {
                    errTitleSet("Account Error");
                    pageErrorSet(ex.message ?? ex)
                }
            }

            pageLoadedSet(true);
        };

        if (accessToken && accessToken.sub) getUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            {!pageLoaded && <PageLoading message="Authenticating user credentials..." />}

            {pageLoaded && pageError && (
                <div className="text-center container">
                    <h2 style={{ color: "red" }}>{errTitle}</h2>
                    <div style={{ marginTop: "20px" }} className="alert alert-danger">{parse(message.replaceNewLine(pageError))}</div>
                </div>
            )}

            {pageLoaded && !pageError && props.children}
        </>
    );
}

export default AuthenticateUser;
