export default class SessionStore {
    static setItem = (itemKey, itemValue) => {
        sessionStorage.setItem(itemKey, JSON.stringify(itemValue));
    };
    
    static getItem = (itemKey) => {
        const data = JSON.parse(sessionStorage.getItem(itemKey));
        return data;
    }
    
    static removeItem = (itemKey) => {
        sessionStorage.removeItem(itemKey);
    }
}
