import React from "react"
import Spinner from "../../Images/Infinity.svg";

// SVG was created at https://loading.io/
// Code was based on https://www.youtube.com/watch?v=zY5jOP5v-FY
const FullPageLoader = () => {
    return (
        <div className="fp-container" style={{ zIndex: "99999" }}>
            <img src={Spinner} className="fp-loader" alt="loading" />
        </div>
    );
}

export default FullPageLoader;