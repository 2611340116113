import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { useRecoilState } from "recoil";
import { isStaffState } from "../../Shared/atom";
import { login, loginAsStaff } from "../../Authentication/authenticationHelper";
import config from "../../app.config"

import logo from "../../Images/SDX-banner-logo.png";

import styles from "./Login.module.scss";

export const PublicPage = props => {
    const [isStaff, isStaffSet] = useRecoilState(isStaffState);

    const handleLogin = () => {
        isStaffSet(false);
        login();
    }

    const handleStaffLogin = () => {
        isStaffSet(true);
        loginAsStaff();
    }

    let location = useLocation();
    return (
        <>
            <AuthenticatedTemplate>
                <Redirect to="/dashboard" />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div className={styles.page}>
                    <div className={styles.panel}>
                        <div className={styles.contentHeader}>
                            {/* "&#8209;" is a non-breaking hyphen so the line won't wrap "Shred-X" at the hyphen*/}
                            <h2>Welcome to the Shred&#8209;X Client Portal</h2>

                            <h5>Our secure interactive user&#8209;based booking management platform</h5>
                        </div>

                        <div className={styles.contentDetail}>
                            {(!isStaff && location.pathname !== config.staffPath) &&
                                <button type="button" className="btn btn-dark" onClick={() => handleLogin()}>Login</button>
                            }

                            {(isStaff || location.pathname === config.staffPath) &&
                                <button type="button" className="btn btn-dark" onClick={() => handleStaffLogin()}>Staff Login</button>
                            }
                        </div>

                        <div className={styles.terms}>
                            <p>By logging in you are agreeing to our <a href="/TermsOfUse" target="terms">Terms of Use</a> and <a href="PrivacyPolicy" target="privacy">Privacy Policy</a></p>
                        </div>

                        <div className={styles.contentLogo}>
                            <img height="auto" src={logo} alt="Shred-X Logo" />
                        </div>


                        <div className={styles.contentFooter}>
                            <div className={styles.ftl}></div>
                            <div className={styles.fbl}></div>

                            <div className={styles.footerRight}>
                                <div className={styles.footerRightClip}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </>
    )
};
