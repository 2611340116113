import React from "react";
import { Row, Col } from 'reactstrap';

import Navigation from "./Navigation.Component";
import SiteHeader from "./SiteHeader.Component";

const SiteContainer = props => {
    return (
        <section className="page-layout">
            <SiteHeader />

            <Row className="content-height no-gutters flex-nowrap m-0">
                <Col xs="auto" className="m-0 p-0">
                    <Navigation />
                </Col>

                <Col className="flex-grow-1 m-0 pt-3 px-3">{props.children}</Col>
            </Row>
        </section>
    );
}

export default SiteContainer;