import React from "react";
import SiteContainer from "../common/SiteContainer.Component";

import "./GiveAway.Page.scss";

import prizeBanner from "../../Images/PortalGiveAway.jpg";

export const GiveAway = () => {
    document.title = "Shred-X: Competition";

    return (
        <SiteContainer>
            <div style={{ margin: "0 10% 20px 10%" }}>
                <img className="img-fluid" style={{width: "100%", marginBottom: "10px"}} src={prizeBanner} alt="Prize Banner"/>
                <p
                    className="padding-top: 7pt;padding-left: 7pt;text-indent: 0pt;text-align: left;"
                    style={{ color: "#f60", fontFamily: "Calibri, sans-serif", fontStyle: "normal", fontWeight: "bold", textDecoration: "non", fontSize: "24pt" }}>
                    Shred-X Client Portal Travel Voucher Giveaway
                </p>
                <p>
                    <br />
                </p>
                <p className="s1">Offer: <span style={{ color: "#30849B" }}>
                    Register your secure Shred-X client portal account and update your Profile details (if required) between 4 September – 6 October 2023 for the chance to win a $500 Flight Centre travel voucher!</span></p>
                <p>
                    <br />
                </p>
                <p className="s2">TERMS &amp; CONDITIONS</p>
                <p>
                    <br />
                </p>
                <p>Terms and conditions apply as follows:</p>
                <p>
                    <br />
                </p>
                <ol id="l1">
                    <li data-list-text="1.">
                        <p>*Offer is valid for all new Shred-X client portal accounts registered and updated between 4 September – 6 October 2023.</p>
                    </li>
                    <li data-list-text="2.">
                        <p>Not valid with any other offer.</p>
                    </li>
                    <li data-list-text="3.">
                        <p>Entry to the competition will be provided only to qualified accounts during the promotional period and all registrations must be received by 6 October 2023, 5pm AEST.</p>
                    </li>
                    <li data-list-text="4.">
                        <p>The prize draw is administered as a Trade Promotion (or promotional game) in the State of Queensland (Shred-X Pty Ltd Head Office 73 Lahrs Road, Ormeau 4208) and will be governed by the additional terms and conditions outlined below and overleaf.</p>
                        <p>
                            <br />
                        </p>
                        <p className="s2">PROMOTIONAL GAME - TERMS &amp; CONDITIONS
                        </p>
                        <p>
                            <br />
                        </p>
                    </li>
                    <li data-list-text="5.">
                        <p>Employees and 3<span className="s3">rd</span> parties of the promoter and agencies associated with this promotion (Shred-X Pty Ltd) are ineligible to enter the promotion, as are their immediate families.</p>
                    </li>
                    <li data-list-text="6.">
                        <p>To enter, entrants must have registered a new Shred-X client portal account between 4 September – 6 October 2023.</p>
                    </li>
                    <li data-list-text="7.">
                        <p>Skill plays no part in determining the winner, and there is no entry fee to join the Trade Promotion.</p>
                    </li>
                    <li data-list-text="8.">
                        <p>The Promoter reserves the right to verify the validity of entries via confirmed account registration.</p>
                    </li>
                    <li data-list-text="9.">
                        <p>Winners of the promotion must be 18 years old and over and residents of Australia.</p>
                    </li>
                    <li data-list-text="10.">
                        <p>The promoter reserves the right to withdraw or amend the promotion as necessary due to circumstances outside its control.</p>
                    </li>
                    <li data-list-text="11.">
                        <p>Prize is non-transferable and non-redeemable for cash. Prize cannot be exchanged for any other prize.</p>
                    </li>
                    <li data-list-text="12.">
                        <p>The prize will be awarded randomly (lucky draw).</p>
                    </li>
                    <li data-list-text="13.">
                        <p>The promoter shall not be liable for any loss or damage whatsoever which is suffered (including, but not limited to, indirect or consequential loss) or for personal injury which is suffered or sustained, in connection with the prize, except for any liability which cannot be excluded by laws.</p>
                    </li>
                    <li data-list-text="14.">
                        <p>By entering the Trade Promotion, all entrants will be deemed to have accepted and agreed to be bound by these rules.</p>
                    </li>
                    <li data-list-text="15.">
                        <p>The Promoter is Shred-X Pty Ltd, Head Office: 73 Lahrs Road, Ormeau, QLD 4208. ABN 44 123 767 153</p>
                    </li>
                    <li data-list-text="16.">
                        <p>The information you are asked to provide to Shred-X Pty Ltd is personal information and is protected by the Australian Privacy Act 1988. Shred-X Pty Ltd will not disclose this information to other parties other than in accordance with the Privacy Act 1988.</p>
                    </li>
                    <li data-list-text="17.">
                        <p>Shred-X Pty Ltd may use the winner’s name and drawn prize for commercial purpose, including future promotional marketing and publicity purposes, the company’s social media channels and within the Shred-X website within 30 days of the draw.</p>
                    </li>
                    <li data-list-text="18.">
                        <p>In the event, for any reason whatsoever a winner does not accept the prize at the time stipulated by Shred-X Pty Ltd then the prize will be forfeited by the winner. Cash will not be awarded in lieu of the prize.</p>
                    </li>
                    <li data-list-text="19.">
                        <p>Winner will be notified in writing by email within two (2) working days following the draw.</p>
                    </li>
                </ol>

                <p>If Shred-X Pty Ltd is unable to make contact with the winner within 30 days of the draw and Shred-X Pty Ltd has made every effort to contact them with the information provided by the booking, then Shred-X Pty Ltd will award the prize to another valid booking.</p>
                <p>
                    <br />
                </p>

                <p style={{ fontWeight: "bold" }}>
                    FLIGHTCENTRE GIFT CARDS – TERMS OF USE
                    <span style={{ fontWeight: "normal", textDecoration: "none" }}> can be found online at </span>
                    <a href="https://www.flightcentre.com.au/p/gift-cards#how-do-gift-cards-work" target="_blank" rel="noreferrer">
                        https://www.flightcentre.com.au/p/gift-cards#how-do-gift-cards-work
                    </a>
                </p>
            </div>
        </SiteContainer>
    )
}

export default GiveAway;