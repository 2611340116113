import React, { useState, useEffect, Fragment } from "react";
import { Tooltip } from "reactstrap";
import parse from 'html-react-parser';

import Input from "../common/Input.Component"

import * as apiUrls from "../../Shared/endPoints";
import toastMessage from "../../Shared/message";
import useHttp from "../../Hooks/useHttp";
import useInputValidator, { isNotEmpty, isEmail } from "../../Hooks/useInputValidator";

import styles from "./SignUp.module.scss"

//cspell: ignore vars
export const SignUp = ({ registrationId, accountId, contactEmail, contactFirstName, contactLastName }) => {
    // eslint-disable-next-line no-unused-vars
    const { value: email, setValue: emailSet, hasError: emailHasError, errorText: emailErrorText, valueChangedHandler: emailChangedHandler, inputBlurHandler: emailBlurHandler, reset: emailReset } = useInputValidator(isEmail, "Email must contain a valid email address.");
    // eslint-disable-next-line no-unused-vars
    const { value: firstName, setValue: firstNameSet, hasError: firstNameHasError, errorText: firstNameErrorText, valueChangedHandler: firstNameChangedHandler, inputBlurHandler: firstNameBlurHandler, reset: firstNameReset } = useInputValidator(isNotEmpty, "First Name is a required field.");
    // eslint-disable-next-line no-unused-vars
    const { value: lastName, setValue: lastNameSet, hasError: lastNameHasError, errorText: lastNameErrorText, valueChangedHandler: lastNameChangedHandler, inputBlurHandler: lastNameBlurHandler, reset: lastNameReset } = useInputValidator(isNotEmpty, "Last Name is a required field.");
    // eslint-disable-next-line no-unused-vars
    const { value: displayName, setValue: displayNameSet, hasError: displayNameHasError, errorText: displayNameErrorText, valueChangedHandler: displayNameChangedHandler, inputBlurHandler: displayNameBlurHandler, reset: displayNameReset } = useInputValidator(isNotEmpty, "Display Name is a required field.");

    const [formApiError, formApiErrorSet] = useState();
    const [formIsSubmitted, formIsSubmittedSet] = useState(false);
    const [formIsSubmitting, formIsSubmittingSet] = useState(false);
    const [formIsValid, formIsValidSet] = useState(false);
    const [isRegistered, isRegisteredSet] = useState(false);
    const [tooltipOpen, tooltipOpenSet] = useState(false);

    const { request: sendGridEmail } = useHttp("Email", false);

    useEffect(() => {
        emailSet(contactEmail || "");
        firstNameSet(contactFirstName || "");
        lastNameSet(contactLastName || "");
        displayNameSet(`${contactFirstName || ""} ${contactLastName || ""}`.trim());
    }, [emailSet, firstNameSet, lastNameSet, displayNameSet, contactEmail, contactFirstName, contactLastName]);

    useEffect(() => {
        formIsValidSet(!firstNameHasError && !lastNameHasError && !emailHasError && !displayNameHasError);
    }, [displayNameHasError, emailHasError, firstNameHasError, lastNameHasError]);

    const { request: submitRegistration } = useHttp("Registration Submission", false);

    const formSubmissionHandler = (event) => {
        event.preventDefault();

        formApiErrorSet(null);
        if (!formIsValid) {
            return;
        }

        formIsSubmittingSet(true);

        submitRegistration(apiUrls.RegisterUser, "post", JSON.stringify({ registrationId, email, firstName, lastName, displayName }), { noAuth: true })
            .then((data) => {
                formIsSubmittingSet(false);
                formIsSubmittedSet(true);
                sendRegisteredEmail(data);
                isRegisteredSet(true);
            }, (error) => {
                toastMessage.Error("Register Account", error);
                formIsSubmittingSet(false);
                formIsSubmittedSet(false);
                formApiErrorSet(error);
            });
    };

    const sendRegisteredEmail = (userPassword) => {
        const errMsg = parse("Your account was successfully registered however an error occurred when sending the confirmation email.\n\nPlease contact our Customer Service team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339) for assistance in completing the registration process.");

        try {
            const emailModel = {
                templateName: "Registered Email - Customer",
                accountId: accountId,
                firstName: firstName,
                lastName: lastName,
                toAddress: email,
                formData: { password: userPassword }
            }

            sendGridEmail(apiUrls.SendGridEmail, "post", JSON.stringify(emailModel), { noAuth: true })
                .then(null)
                .catch(ex => {
                    toastMessage.Error("Email Confirmation", errMsg);
                });
        }

        catch (ex) {
            toastMessage.Error("Email Confirmation", errMsg);
        }
    }

    const handleFocus = (evt) => evt.target.select();

    if (isRegistered) {
        return (
            <>
                <h5>Congratulations, your account has been successfully registered.</h5>
                <h5>An email has been sent containing instructions on completing the registration process.</h5>
                <h5>If you have any questions, please don't hesitate to contact our Customer Service team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339).</h5>
            </>
        )
    }

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    return (
        <form noValidate className={styles.signUpForm}>
            <h5 className={styles.heading}>Please enter the following details to complete the registration of your account.</h5>

            <label className={styles.lblFirstName}>First Name:</label>

            <Input
                id="firstName"
                controlClass={styles.txtFirstName}
                placeholder="Enter your first/given name"
                tooltip="Enter your first/given name"
                error={firstNameErrorText}
                value={firstName}
                onBlur={firstNameBlurHandler}
                onChange={firstNameChangedHandler}
                onFocus={handleFocus} />

            <label className={styles.lblLastName}>Last Name:</label>

            <Input
                id="lastName"
                controlClass={styles.txtLastName}
                placeholder="Enter your last/family name"
                tooltip="Enter your last/family name"
                error={lastNameErrorText}
                value={lastName}
                onBlur={lastNameBlurHandler}
                onChange={lastNameChangedHandler}
                onFocus={handleFocus} />

            <label className={styles.lblDisplayName}>Display Name:</label>

            <Input
                id="displayName"
                controlClass={styles.txtDisplayName}
                placeholder="Enter the name you want displayed in the menu"
                tooltip="Enter the name you want displayed in the menu"
                error={displayNameErrorText}
                value={displayName}
                onBlur={displayNameBlurHandler}
                onChange={displayNameChangedHandler}
                onFocus={handleFocus} />

            <label className={styles.lblEmail}>Email:</label>

            <Input
                id="email"
                controlClass={styles.txtEmail}
                placeholder="Enter your email address"
                tooltip="Enter your email address"
                error={emailErrorText}
                value={email} onBlur={emailBlurHandler}
                onChange={emailChangedHandler}
                onFocus={handleFocus} />

            <div className={styles.btnRegister}>
                {(formIsSubmitting || formIsSubmitted) &&
                    <Fragment>
                        {formIsSubmitting && <p>Your registration is being submitted, please wait...</p>}
                        {formIsSubmitted && <p>Your registration has been submitted.</p>}
                        {formApiError && (
                            <div>
                                <p>An error occurred while submitting your registration.</p>
                                <p>{formApiError}</p>
                            </div>
                        )}
                    </Fragment>
                }

                <Fragment>
                    <Tooltip placement="bottom" isOpen={tooltipOpen} target="btnRegister" toggle={() => tooltipOpenSet(!tooltipOpen)}>Register your account with the information entered above</Tooltip>
                    <button id="btnRegister" className="btn btn-dark mt-3 px-5" disabled={!formIsValid} onClick={(evt) => formSubmissionHandler(evt)}>
                        Register
                  </button>
                </Fragment>
            </div>
        </form >
    )
}