import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FilterFields from "./filterField.Component";

import currencyHelper from "../../Shared/currencyHelper";
import { currencyFilter, dateFilter, numberFilter } from "../../Shared/enums"

import styles from "./Filter.module.scss"

const InvoiceFilter = ({ showInvoiceFilter, invoiceFilters, updateFilter, closeFilter, ...args }) => {
    const [filterOptions, filterOptionsSet] = useState(invoiceFilters);

    useEffect(() => {
        filterOptionsSet(invoiceFilters);
    }, [invoiceFilters]);

    const [controlSize] = useState(args?.controlSize ?? "md");

    const handleClearFilter = (filterOption) => {
        let _filterOptions = filterOptions;

        if (filterOption === "all") {
            _filterOptions = {};
            updateFilter(_filterOptions);
        }

        else {
            _filterOptions[`${filterOption}Option`] = 0;
            _filterOptions[`${filterOption}Filter1`] = null;
            _filterOptions[`${filterOption}Filter2`] = null;
        }

        filterOptionsSet({ ..._filterOptions });
    }

    const handleCurrencyFormat = (evt) => {
        let _filterOptions = { ...filterOptions };
        let value = currencyHelper.toNumber(evt.target.value);
        _filterOptions[evt.target.id] = currencyHelper.toDecimalFormat(value);
        filterOptionsSet(_filterOptions);
    }

    const handleDateFilterChanged = (date, id) => {
        let _filterOptions = { ...filterOptions };
        _filterOptions[id] = date;
        filterOptionsSet(_filterOptions);
    }

    const handleFilterChanged = (evt) => {
        let _filterOptions = { ...filterOptions };

        if (evt.target.type === "select-one") {
            _filterOptions[evt.target.id] = Number(evt.target.value);
        }

        else {
            _filterOptions[evt.target.id] = evt.target.value;
        }

        filterOptionsSet(_filterOptions);
    }

    // Set the size of the various controls used on the page...
    const inlineClass = "form-inline d-flex mb-3"

    // Set the options used by the various filters (Before, After, etc) from the defined enums...
    const currencyFilterOptions = Object.keys(currencyFilter).map((key, index) => {
        return (<option key={index} value={currencyFilter[key].value}>{currencyFilter[key].text}</option>);
    })

    const currencyWithNotPaidFilterOptions = [...currencyFilterOptions];
    currencyWithNotPaidFilterOptions.push(<option value={99} key="currencyOption">Not paid</option>)

    const dateFilterOptions = Object.keys(dateFilter).map((key, index) => {
        return (<option key={index} value={dateFilter[key].value}>{dateFilter[key].text}</option>);
    })

    const dateWithNotPaidFilterOptions = [...dateFilterOptions]
    dateWithNotPaidFilterOptions.push(<option value={99} key="dateOption">Not paid</option>)

    const numberFilterOptions = Object.keys(numberFilter).map((key, index) => {
        return (<option key={index} value={numberFilter[key].value}>{numberFilter[key].text}</option>);
    })

    const selectElement = (id, value, placeholderKey, options) => (
        <select type="select" id={`${id}Option`} className={"form-select w-25 align-self-start"} value={value} onChange={(evt) => handleFilterChanged(evt)}>
            <option value={placeholderKey}>Choose...</option>
            {options}
        </select>
    )

    const filterElement = (id, type, value1, value2, filterOption, filterCheck, onChange, onBlur, controlClass) => (
        <FilterFields id={`${id}Filter`} type={type} size={controlSize} value1={value1} value2={value2} filterOption={filterOption} filterCheck={filterCheck} onChange={onChange} onBlur={onBlur} groupClass={styles["flex-item"]} controlClass={controlClass} />
    );

    const trashElement = (id) => (
        <div className={"align-self-center"} xs="auto">
            <div className={styles["filter-clear"]} title="Clear filter options">
                <FontAwesomeIcon icon="trash" onClick={() => handleClearFilter(id)} />
            </div>
        </div>
    )

    return (
        <Modal size="lg" backdrop="static" isOpen={showInvoiceFilter} toggle={closeFilter}>
            <ModalHeader>Filter Options</ModalHeader>

            <ModalBody>
                <h6>Invoice Number</h6>
                <div className={inlineClass} key="filterInvoiceNumber">
                    {selectElement("invoice", filterOptions?.invoiceOption ?? 0, 0, numberFilterOptions)}
                    {filterElement("invoice", "input", filterOptions?.invoiceFilter1, filterOptions?.invoiceFilter2, filterOptions?.invoiceOption ?? 0, numberFilter.BETWEEN.value, handleFilterChanged)}
                    {trashElement("invoice")}
                </div>

                <h6>Invoice Date</h6>
                <div className={inlineClass} key="filterInvoiceDate">
                    {selectElement("date", filterOptions?.dateOption ?? 0, -1, dateFilterOptions)}
                    {filterElement("date", "date", filterOptions?.dateFilter1, filterOptions?.dateFilter2, filterOptions?.dateOption, dateFilter.BETWEEN.value, handleDateFilterChanged, null)}
                    {trashElement("date")}
                </div>

                <h6>Due Date</h6>
                <div className={inlineClass} key="filterInvoiceDueDate">
                    {selectElement("dueDate", filterOptions?.dueDateOption ?? 0, 0, dateFilterOptions)}
                    {filterElement("dueDate", "date", filterOptions?.dueDateFilter1, filterOptions?.dueDateFilter2, filterOptions?.dueDateOption, dateFilter.BETWEEN.value, handleDateFilterChanged, null)}
                    {trashElement("dueDate")}
                </div>

                <h6>Amount</h6>
                <div className={inlineClass} key="filterInvoiceAmount">
                    {selectElement("amount", filterOptions?.amountOption, 0, currencyFilterOptions)}
                    {filterElement("amount", "currency", filterOptions?.amountFilter1, filterOptions?.amountFilter2, filterOptions?.amountOption, currencyFilter.BETWEEN.value, handleFilterChanged, handleCurrencyFormat)}
                    {trashElement("amount")}
                </div>

                <h6>Date Paid</h6>
                <div className={inlineClass} key="filterInvoiceDatePaid">
                    {selectElement("datePaid", filterOptions?.datePaidOption ?? 0, 0, dateWithNotPaidFilterOptions)}
                    {filterElement("datePaid", "date", filterOptions?.datePaidFilter1, filterOptions?.datePaidFilter2, filterOptions?.datePaidOption, dateFilter.BETWEEN.value, handleDateFilterChanged, null)}
                    {trashElement("datePaid")}
                </div>

                <h6>Amount Paid</h6>
                <div className={inlineClass} key="filterInvoiceAmountPaid">
                    {selectElement("amountPaid", filterOptions?.amountOption, 0, currencyWithNotPaidFilterOptions)}
                    {filterElement("amountPaid", "currency", filterOptions?.amountPaidFilter1, filterOptions?.amountPaidFilter2, filterOptions?.amountPaidOption, currencyFilter.BETWEEN.value, handleFilterChanged, handleCurrencyFormat)}
                    {trashElement("amountPaid")}
                </div>

                <h6>Balance</h6>
                <div className={inlineClass} key="filterInvoiceBalance">
                    {selectElement("balance", filterOptions?.amountOption, 0, currencyFilterOptions)}
                    {filterElement("balance", "currency", filterOptions?.balanceFilter1, filterOptions?.balanceFilter2, filterOptions?.balanceOption, currencyFilter.BETWEEN.value, handleFilterChanged, handleCurrencyFormat)}
                    {trashElement("balance")}
                </div>

            </ModalBody>

            <ModalFooter>
                <button type="button" className="btn btn-sx" title="Filter invoice records" onClick={() => updateFilter(filterOptions)}>
                    <FontAwesomeIcon icon="filter" title="Filter invoice records" />&nbsp;Filter
        </button>

                <button type="button" className="btn btn-danger" title="Clear all filters" onClick={() => handleClearFilter("all")}>
                    <FontAwesomeIcon icon="trash" title="Clear all filters" />&nbsp;Clear
        </button>

                <Button color="secondary" onClick={closeFilter}>
                    <FontAwesomeIcon icon="door-closed" title="Discard any changes and close the filter" />&nbsp;Cancel</Button>
            </ModalFooter>
        </Modal>

    )
}

export default InvoiceFilter;