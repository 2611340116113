import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useRecoilState } from "recoil";
import { accountState, accountTransactionsState, accountBalanceState, userState } from "../../Shared/atom";

import Input from "../common/Input.Component";

import useHttp from "../../Hooks/useHttp";

import * as apiUrls from "../../Shared/endPoints";
import currencyHelper from "../../Shared/currencyHelper";
import message from "../../Shared/message";

const Payway = ({ isOpen, onCloseClick, paymentTransactions, axAccountNumber, onSuccess }) => {
    // -- Global State ---------------------------------------------------------------------------------------------------------------------
    const [accountBalance, accountBalanceSet] = useRecoilState(accountBalanceState);
    const [portalUser] = useRecoilState(userState);
    const [portalAccount] = useRecoilState(accountState);
    const [accountTransactions, accTransactionsSet] = useRecoilState(accountTransactionsState);

    // -- Page State ----------------------------------------------------------------------------------------------------------------------
    const [creditCardFrame, creditCardFrameSet] = useState();
    const [invoices, invoicesSet] = useState();
    const [isFormValid, isFormValidSet] = useState(false);
    const [isPaywayValid, isPaywayValidSet] = useState(false);
    const [isProcessingPayment, isProcessingPaymentSet] = useState(false);
    const [paymentAmount, paymentAmountSet] = useState(0);
    const [paywayErrorMessage, paywayErrorMessageSet] = useState();
    const [showInput, showInputSet] = useState(false);

    // -- Fetch Requests ------------------------------------------------------------------------------------------------------------------
    const INVOICE_PAYMENT = "Invoice Payment";

    const { request: sendGridEmail } = useHttp(`${INVOICE_PAYMENT} - Confirmation Email`, false);
    const { request: submitPayment } = useHttp(INVOICE_PAYMENT, true);
    const { request: submitPendingPayment } = useHttp(INVOICE_PAYMENT, true);
    const { request: submitPortalHistory } = useHttp();

    // -- Scope Variables -----------------------------------------------------------------------------------------------------------------
    const PAYMENT_ERROR = "We were unable to process your payment.\n\nPlease contact your bank before trying again.";

    // -- State Changes -------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (invoices) {
            const script = document.createElement("script");
            script.src = "https://api.payway.com.au/rest/v1/payway.js";
            script.async = true;
            script.onload = () => {
                const options = {
                    publishableApiKey: process.env.REACT_APP_PAYWAY_PUBLISHABLE_KEY,
                    tokenMode: "callback",
                    onValid: function () {
                        isPaywayValidSet(true);
                    },
                    onInvalid: function () {
                        isPaywayValidSet(false);
                    },
                    style: {
                        "div.payway-card": { "background-color": "transparent", "border-width": "0", "font-family": "'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", "font-size": "16px", "margin-left": "-24px" },
                        ".payway-card label, .payway-card legend, .payway-card input, .payway-card select": { color: "#000", "font-size": "16px" },
                        ".payway-card input": { border: "1px solid #ccc", "border-radius": "4px", "top": "1.8em!important" },
                        ".payway-card select": { border: "1px solid #ccc", "border-radius": "4px", "margin-top": "5px" },
                        ".payway-creditcard-expirationseparator": { bottom: "2.5em!important" }
                    }
                };

                window.payway.createCreditCardFrame(options, function (err, frame) {
                    if (err) {
                        console.error("Error creating frame: " + err);
                    }
                    else {
                        // Save the created frame which holds the one-time token which is needed for http post.
                        creditCardFrameSet(frame);
                    }
                });

                showInputSet(true);
            }

            document.body.appendChild(script);
            return () => {
                destroyCreditCardFrame();
                document.body.removeChild(script);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]);

    useEffect(() => {
        if (isOpen && paymentTransactions) {
            const _invoices = paymentTransactions
                .map((invoice) => { return { balanceToPay: invoice.amount - (invoice.amountPaid || 0), ...invoice }; });

            invoicesSet(_invoices);

            const amt = _invoices.reduce((sum, { balanceToPay }) => sum + balanceToPay, 0);
            paymentAmountSet(amt.toFixed(2));
        }
        else {
            destroyCreditCardFrame();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, paymentTransactions]);

    useEffect(() => {
        isFormValidSet(isPaywayValid && !Number.isNaN(paymentAmount) && paymentAmount > 0);
    }, [isPaywayValid, paymentAmount]);

    // -- Form Methods --------------------------------------------------------------------------------------------------------------------
    const destroyCreditCardFrame = () => {
        if (creditCardFrame) {
            creditCardFrame.destroy();
            creditCardFrameSet(null);
        }
    }

    const handleOnCloseClick = () => {
        if (!isProcessingPayment) {
            destroyCreditCardFrame();
            onCloseClick();
        }
    }

    const processPayment = () => {
        paywayErrorMessageSet(null);
        isProcessingPaymentSet(true);

        let invoiceNumber;
        let receiptNumber;

        try {
            creditCardFrame.getToken(function (err, data) {
                if (err) {
                    console.error(`${INVOICE_PAYMENT}: ${err.message ?? err}`);
                    throw new Error("An error occurred sending the payment request to Westpac.\n\nPlease try again and if the problem persists contact our Customer Service team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339)");
                }

                else {
                    paywayErrorMessageSet(null);

                    const payment = {
                        invoices: [...invoices],
                        accountId: portalAccount.accountId,
                        accountNumber: axAccountNumber.toString(),
                        paymentAmount: +paymentAmount,
                        singleUseTokenId: data.singleUseTokenId,
                    };

                    submitPayment(apiUrls.InvoicePayment, "post", JSON.stringify(payment))
                        .then(result => {
                            if (result.status === "success") {
                                // If paying a single invoice use the invoice number; otherwise use "00000000" to indicate multiple invoices are being paid....
                                invoiceNumber = payment.invoices.length === 1 ? `${payment.invoices[0].invoiceNumber.padStart(8, "0")}` : "00000000";
                                receiptNumber = result.receipt;

                                const reference = payment.accountNumber + invoiceNumber;

                                const invoices = [];
                                const invoiceDate = new Date();
                                const updatedTransactions = [...accountTransactions];

                                let _accountBalance = accountBalance;

                                payment.invoices.forEach((inv) => {
                                    inv.amountPaid = inv.amount; // Currently the user can only pay a single invoice or the total of all invoices...
                                    inv.datePaid = invoiceDate;
                                    let invoice = { ...inv, paymentStatus: "Pending", paymentStatusDisplay: "Pending", amountPaid: inv.amount, datePaid: Date(), paymentReference: reference, balance: inv.amount };
                                    invoices.push(invoice);
                                    const idx = updatedTransactions.findIndex((i) => i.invoiceNumber === invoice.invoiceNumber);
                                    updatedTransactions[idx] = invoice;
                                    _accountBalance = _accountBalance - inv.amountPaid;
                                });

                                accTransactionsSet(updatedTransactions);
                                accountBalanceSet(parseFloat(_accountBalance.toFixed(2)));
                                onSuccess(invoices);

                                const pendingPayment = {
                                    accountNumber: invoices[0].axAccountNumber,
                                    referenceNumber: reference,
                                    paidDtTm: invoiceDate.toISOString(),
                                    invoices: invoices.map((inv) => {
                                        return { invoiceNumber: inv.invoiceNumber, amount: inv.amountPaid };
                                    }),
                                };

                                const invoiceTotal = payment.invoices.map(inv => inv.amount).reduce((prev, curr) => prev + curr, 0);

                                submitPendingPayment(apiUrls.SubmitPendingPayment, "post", JSON.stringify(pendingPayment))
                                    .then(data => {
                                        const model = JSON.stringify({
                                            accountId: portalAccount.accountId,
                                            userId: portalUser.userId,
                                            page: "Account",
                                            action: "Payment",
                                            details: `Reference: ${reference}, Amount: ${currencyHelper.toCurrencyFormat(invoiceTotal)}`
                                        });

                                        message.Success(INVOICE_PAYMENT, `Your payment for ${currencyHelper.toCurrencyFormat(invoiceTotal)} was successfully processed.`);

                                        submitPortalHistory(apiUrls.SubmitPortalHistory, "post", model)
                                            .then(data => {
                                                //Nothing to do...
                                            })
                                            .catch(ex => {
                                                const msg = `The payment was successfully processed however there was an error recording the payment in the portal history.\n\nPlease report the following error to the Customer Service on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339).\n\n${ex.message ?? ex}`;
                                                message.Warning(INVOICE_PAYMENT, msg);
                                            });
                                    })
                                    .catch((ex) => {
                                        const msg = PAYMENT_ERROR;
                                        message.Error(INVOICE_PAYMENT, msg);
                                    });

                                const emailModel = {
                                    templateName: "Payment Confirmation - Customer",
                                    firstName: portalUser.givenName,
                                    lastName: portalUser.surname,
                                    senderEmail: portalUser.email,
                                    toAddress: portalUser.email,
                                    accountId: portalUser.accountId,
                                    formData: {
                                        contactFirstName: portalUser.givenName,
                                        amount: currencyHelper.toCurrencyFormat(invoiceTotal),
                                        accountNumber: invoices[0].crmAccountNumber,
                                        invoiceNumber: invoiceNumber,
                                        receiptNumber: receiptNumber,
                                    },
                                };

                                sendGridEmail(apiUrls.SendGridEmail, "post", JSON.stringify(emailModel))
                                    .then(null)
                                    .catch((ex) => {
                                        const msg = "The payment was successfully processed however there was an error sending the confirmation email.\n\nPlease report this error to the Customer Service team on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339).";
                                        message.Warning(INVOICE_PAYMENT, msg);
                                    });

                                onCloseClick();
                            }

                            else {
                                paywayErrorMessageSet(PAYMENT_ERROR);
                            }

                            isProcessingPaymentSet(false);
                        })
                        .catch((reason) => {
                            // If the card is stolen or declined the message will read "Pick-up card".
                            // Instead we should just return a generic message to contact their bank...
                            const msg = PAYMENT_ERROR;
                            message.Error(INVOICE_PAYMENT, msg);

                            paywayErrorMessageSet(msg);
                            isProcessingPaymentSet(false);
                        });
                }
            });
        } catch (err) {
            paywayErrorMessageSet(err);
            isProcessingPaymentSet(false);
        }
    };

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    const processingSpinner = isProcessingPayment
        ? <Spinner color="light" type="grow" size="sm" />
        : null;

    return (
        <Modal backdrop="static" centered={true} isOpen={isOpen} toggle={handleOnCloseClick}>
            <ModalHeader>{`Invoice Payment ${!invoices ? "" : invoices.length === 1 ? `- ${invoices[0]?.invoiceNumber}` : `${invoices.length} invoices`}`}</ModalHeader>

            <ModalBody>
                {!showInput && <div><label>Payment Amount: {currencyHelper.toCurrencyFormat(paymentAmount)}</label>&nbsp;<FontAwesomeIcon icon="edit" className="hand" onClick={() => showInputSet(true)} /></div>}
                {showInput && <Input id="paymentAmount" type="currency" label="Payment Amount" value={paymentAmount} onChange={(e) => paymentAmountSet(e.target.value)} disabled={true} />}

                <p style={{ display: creditCardFrame ? "none" : "unset" }}>Connecting to payment gateway, please wait...</p>

                <div id="payway-credit-card"></div>

                {!isProcessingPayment && <div className="text-center alert alert-primary" role="alert">Please note we only accept <strong>Visa</strong> and <strong>Mastercard</strong>.</div>}

                {isProcessingPayment && <div className="text-center alert alert-sx" role="alert">Processing payment, please wait {processingSpinner}</div>}
                {paywayErrorMessage && <div className="text-center alert alert-danger" role="alert">{paywayErrorMessage}</div>}
            </ModalBody>

            <ModalFooter>
                <button type="button" className="btn btn-sx btn-sm" onClick={processPayment} disabled={!isFormValid || isProcessingPayment}>
                    Pay Now
                </button>
                <button type="button" className="btn btn-secondary btn-sm" onClick={handleOnCloseClick} disabled={isProcessingPayment}>
                    Cancel
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default React.memo(Payway);
