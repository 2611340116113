import React, { useState } from "react";
import FullPageLoader from "../components/common/FullPageLoader.Component";

const useFullPageLoader = () => {
    const [loading, loadingSet] = useState(false);

    return [
        loading ? <FullPageLoader /> : null,
        () => loadingSet(true),
        () => loadingSet(false)
    ]   
}

export default useFullPageLoader;