import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";

import { accountState, accountTransactionsState, menuState, serviceHistoryState } from "../../Shared/atom";

import "../../assets/scss/navigation.scss";

import useWindowDimensions from "../../Hooks/useWindowDimensions"

const Navigation = ({ linkColour }) => {
    const [portalAccount] = useRecoilState(accountState);
    const [accountTransactions] = useRecoilState(accountTransactionsState);
    const [serviceHistory] = useRecoilState(serviceHistoryState);

    const [menuStatus, menuStatusSet] = useRecoilState(menuState);

    const _urlPage = window.location.pathname.toLowerCase();

    const { width } = useWindowDimensions();
    const [prevWidth, prevWidthSet] = useState(null);

    useEffect(() => {
        if (prevWidth !== null && prevWidth !== width) {
            if (width <= 768) {
                menuStatusSet("hidden");
            }

            else if (width <= 1400) {
                menuStatusSet("collapsed");
            }

            else {
                menuStatusSet("expanded");
            }
        }

        prevWidthSet(width);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    const navLinks = [
        {
            text: "Dashboard",
            path: "/dashboard",
            icon: "dashboard-icon-06.png",
        },
        {
            text: "Profile",
            path: "/profile",
            icon: "dashboard-icon-02.png",
        },
        {
            text: "Account",
            path: "/account",
            icon: "dashboard-icon-05.png",
        },
        {
            text: "Service Schedule",
            path: "/service",
            icon: "dashboard-icon-03.png",
        },
        {
            text: "Contact Us",
            path: "/contact",
            icon: "dashboard-icon-08.png",
        },
    ];

    let menuClass = "page-navigation responsive-navigation";
    let menuStyle = { display: "block" };
    let menuText = "menu-name";
    let disabledText = "menu-disabled";

    switch (menuStatus) {
        case "collapsed":
            menuClass = `${menuClass} collapsed`;
            menuText = "hide-text";
            disabledText = "hide-text";
            break;

        case "hidden":
            menuStyle = { display: "none" };
            break;
        //no default
    }

    return (
        <nav className={menuClass} style={menuStyle}>
            {navLinks.map((link, index) =>
                <React.Fragment key={index}>
                    {link.text === "Dashboard" && (
                        <NavLink to={link.path} style={{ color: linkColour }} activeClassName="selected-menu">
                            <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                                <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px" }} alt="navigation icon" />
                                <p className={menuText}>{link.text}</p>
                            </div>
                        </NavLink>
                    )}

                    {portalAccount && link.text === "Profile" && (
                        <NavLink to={link.path} style={{ color: linkColour }} activeClassName="selected-menu">
                            <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                                <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px" }} alt="navigation icon" />
                                <p className={menuText}>{link.text}</p>
                            </div>
                        </NavLink>
                    )}

                    {!portalAccount && link.text === "Profile" && (
                        <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                            <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px", opacity: "0.5" }} alt="navigation icon" />
                            <p className={disabledText}>{link.text}</p>
                        </div>
                    )}

                    {accountTransactions && link.text === "Account" && (
                        <NavLink to={link.path} style={{ color: linkColour }} activeClassName="selected-menu">
                            <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                                <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px" }} alt="navigation icon" />
                                <p className={menuText}>{link.text}</p>
                            </div>
                        </NavLink>
                    )}

                    {!accountTransactions && link.text === "Account" && (
                        <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                            <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px", opacity: "0.5" }} alt="navigation icon" />
                            <p className={disabledText}>{link.text}</p>
                        </div>
                    )}

                    {serviceHistory && link.text === "Service Schedule" && (
                        <NavLink to={link.path} style={{ color: linkColour }} activeClassName="selected-menu">
                            <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                                <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px" }} alt="navigation icon" />
                                <p className={menuText}>{link.text}</p>
                            </div>
                        </NavLink>
                    )}

                    {!serviceHistory && link.text === "Service Schedule" && (
                        <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                            <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px", opacity: "0.5" }} alt="navigation icon" />
                            <p className={disabledText}>{link.text}</p>
                        </div>
                    )}

                    {accountTransactions && link.text === "Contact Us" && (
                        <NavLink to={link.path} style={{ color: linkColour }} activeClassName="selected-menu">
                            <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                                <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px" }} alt="navigation icon" />
                                <p className={menuText}>{link.text}</p>
                            </div>
                        </NavLink>
                    )}

                    {!accountTransactions && link.text === "Contact Us" && (
                        <div className={`menu-item ${link.path.toLowerCase() === _urlPage ? "selected-menu" : ""}`}>
                            <img className="menu-icon" src={require(`../../Images/${link.icon}`)} style={{ width: "40px", opacity: "0.5" }} alt="navigation icon" />
                            <p className={disabledText}>{link.text}</p>
                        </div>
                    )}
                </React.Fragment>
            )}

            <div style={{ marginTop: "50px", marginLeft: "20px" }}>
                <a href="/TermsOfUse" style={{ fontSize: "small", textDecoration: "underline", color: "var(--branding-sunburst-orange)" }}>Terms of Use</a>
            </div>

            <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                <a href="/PrivacyPolicy" style={{ fontSize: "small", textDecoration: "underline", color: "var(--branding-sunburst-orange)" }}>Privacy Policy</a>
            </div>
        </nav>
    )
}

export default Navigation;
