import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { Card, CardBody, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { saveAs } from 'file-saver'
import moment from "moment";
import parse from "html-react-parser";

import { useRecoilState } from "recoil";
import { accountState, accessTokenState, serviceHistoryState, userState } from "../../Shared/atom";

import * as apiUrls from "../../Shared/endPoints";
import dateHelper from "../../Shared/dateHelper";
import message from "../../Shared/message";
import restHelper from "../../Shared/restHelper";

import useFullPageLoader from "../../Hooks/useFullPageLoader";
import useHttp from "../../Hooks/useHttp";

import AuthenticateUser from "../common/AuthenticateUser.Component";
import PageLoading from "../common/PageLoading.Component";

import ClosedDatesRequest from "./ClosedDatesRequest.Component";
import JobReportDates from "./JobReportDates.Component";
import RequestService from "./RequestService.Component";
import ServiceFilter from "./ServiceFilter.Component";
import ServiceScheduleTable from "./ServiceSchedule.Component"

import DateHelper from "../../Shared/dateHelper"

import styles from "./Service.module.scss"

export const ServicePage = () => {
    // -- Global State ---------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [portalAccount] = useRecoilState(accountState);
    const [serviceHistory, serviceHistorySet] = useRecoilState(serviceHistoryState);
    const [portalUser] = useRecoilState(userState);

    // -- Page State -----------------------------------------------------------------------------------------------------------------------
    const [currentAccountId, currentAccountIdSet] = useState(null);
    const [filterFrom, filterFromSet] = useState();
    const [filteredSalesOrders, filteredSalesOrdersSet] = useState([]);
    const [filterStatus, filterStatusSet] = useState();
    const [filterTo, filterToSet] = useState();
    const [pageError, pageErrorSet] = useState(null);
    const [pageLoaded, pageLoadedSet] = useState(null);
    const [reloadServiceHistory, reloadServiceHistorySet] = useState(false);
    const [serviceReport, serviceReportSet] = useState(null);

    const [showClosedDates, showClosedDatesSet] = useState(false);
    const [showJobReportDates, showJobReportDatesSet] = useState(false);
    const [showRequestService, showRequestServiceSet] = useState(false);
    const [showServiceFilter, showServiceFilterSet] = useState(false);

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    // -- Page Requests -------------------------------------------------------------------------------------------------------------------
    const { request: submitEmail } = useHttp();
    const { request: submitPortalHistory } = useHttp();
    const { request: postAuditHistory } = useHttp();

    const SERVICE_REQUEST = "Service Request";

    document.title = "Shred-X: Service Schedule";

    // -- State Changes -------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const loadPage = async () => {
            try {
                postAuditHistory(apiUrls.AddAuditEntry, "post", JSON.stringify({ page: "Service", action: "View", detail: null }));

                if (!currentAccountId && serviceHistory){
                    currentAccountIdSet(portalAccount.accountId);
                }

                else if (currentAccountId !== portalAccount.accountId) {
                    console.log(`${DateHelper.FormatDate(new Date(), 'LogDate')} Setting Account ID`);
                    currentAccountIdSet(portalAccount.accountId);

                    reloadServiceHistorySet(true);
                }

                pageLoadedSet(true);
            }

            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");

                pageErrorSet(parse(errMsg));
                pageLoadedSet(false);
            }

        }
        if (accessToken.token) {
        }

        if (portalAccount && portalUser && accessToken.token) loadPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portalAccount, portalUser, accessToken]);

    useEffect(() => {
        const loadServiceHistory = async () => {
            if (!portalAccount?.accountNumber || !accessToken.token) return;

            try {
                console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetching service schedule...`);
                const data = await restHelper.getServiceHistory(portalAccount.accountId, accessToken.token);
                console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetched ${data.length} service records...`);

                if (data) {
                    data.sort((a, b) => dateHelper.Sort(new Date(a.recordDate), new Date(b.recordDate), true))
                }

                serviceHistorySet(data);
            }

            catch (ex) {
                let errMsg = ex.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");
                pageErrorSet(parse(errMsg));
            }

            finally {
                reloadServiceHistorySet(false);
            }
        };

        if (portalAccount && reloadServiceHistory) loadServiceHistory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadServiceHistory]);

    useEffect(() => {
        if (serviceHistory) {
            let salesOrdersToFilter = [...serviceHistory];

            if (filterStatus) {
                salesOrdersToFilter = salesOrdersToFilter.filter((item) => item.displayStatus === filterStatus);
            }

            if (filterFrom) {
                const from = moment(filterFrom, 'DD/MM/YYYY');
                salesOrdersToFilter = salesOrdersToFilter.filter((item) => new moment(item.recordDate) >= from);
            }

            if (filterTo) {
                const to = moment(filterTo, 'DD/MM/YYYY');
                salesOrdersToFilter = salesOrdersToFilter.filter((item) => new moment(item.recordDate) <= to);
            }

            filteredSalesOrdersSet(salesOrdersToFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFrom, filterStatus, filterTo, serviceHistory]);

    useEffect(() => {
        if (!serviceReport) return;
        showJobReportDatesSet(true);
    }, [serviceReport])

    // -- Functions -----------------------------------------------------------------------------------------------------------------------
    const cancelServiceRequest = () => {
        showRequestServiceSet(false);
    }

    const getReportByDate = async (report, dateRange) => {
        let url, reportName;

        try {
            // Set the "to"" date to today if it wasn't set...
            if (!dateRange.dateTo) dateRange.dateTo = new Date();

            // The "to"date needs to be midnight of the following day...
            const toDate = new Date(dateRange.dateTo).setHours(24, 0, 0, 0);

            // Get all of the invoiced/completed jobs for the selected date range...
            let includedJobs = serviceHistory.filter((salesOrder) => (salesOrder.status === 'Completed' || salesOrder.status === 'Invoiced') && (new Date(salesOrder.recordDate).getTime() >= dateRange.dateFrom.getTime() && new Date(salesOrder.recordDate).getTime() <= toDate));

            if (includedJobs.length === 0) {
                message.Information("Select Report Dates", "There are no Invoiced or Completed jobs in the selected date range.");
                return;
            }

            if (report === "CodReport") {
                includedJobs = includedJobs.filter(x => x.hasCod === true);

                if (includedJobs.length === 0) {
                    message.Information("Select Report Dates", "There are no jobs in the selected date range that currently have a COD.");
                    return;
                }
            }

            if (includedJobs.length > 50) {
                let msg;

                if (report === "EcoReport") {
                    msg = `You have selected ${includedJobs.length} jobs to be included in the Eco Statement.\n\nTo avoid lengthy delays the number of jobs is restricted to 50.\n\nPlease adjust the dates to return fewer jobs.`;
                }
                else {
                    msg = `The number of reports for the selected dates would generate ${includedJobs.length} reports.\n\nTo avoid lengthy delays the number of reports is restricted to 50.\n\nPlease adjust the dates to return fewer reports.`;
                }

                message.Information("Select Report Dates", msg);
                return;
            }

            const salesOrderIds = includedJobs.map(job => job.salesOrderId);

            showLoader();

            switch (report) {
                case "CodReport":
                    url = apiUrls.CodReport;
                    reportName = "Certificates of Destruction";
                    break;

                case "EcoReport":
                    url = apiUrls.EcoReport;
                    reportName = "Eco Statement";
                    break;

                case "JobDocketReport":
                    url = apiUrls.JobReport;
                    reportName = "Job Dockets";
                    break;

                default:
                    let msg;

                    if (report)
                        msg = `The requested report "${report}" is not valid.`;
                    else
                        msg = "The name of the report was not provided.";

                    message.Warning("Invalid Report", msg);
                    return;
            }

            let data;

            // eslint-disable-next-line default-case
            switch (report) {
                case "CodReport":
                case "JobDocketReport":
                    data = await restHelper.getServiceReport(url, salesOrderIds, accessToken.token);
                    break;

                case "EcoReport":
                    data = await restHelper.getEcoReport(url, portalAccount.accountId, dateRange, accessToken.token);
                    break;
            }

            if (data && data.size > 0) {
                let fromDate = moment(dateRange.dateFrom).format("YYYY-MM-DD");
                let toDate = moment(dateRange.dateTo).format("YYYY-MM-DD");
                let fileName = `${reportName} ${fromDate} to ${toDate}.pdf`;

                saveAs(data, fileName);

                message.Success("Service Report", "The requested report has been successfully downloaded.");

                fromDate = moment(dateRange.dateFrom).format("dddd, MMM D YYYY");
                toDate = moment(dateRange.dateTo).format("dddd, MMM D YYYY");
                const model = JSON.stringify({ accountId: portalAccount.accountId, page: "Service", action: `Download ${reportName}`, details: `Requested By: ${portalUser.givenName} ${portalUser.surname}, From: ${fromDate}, To: ${toDate}` });
                submitPortalHistory(apiUrls.SubmitPortalHistory, "post", model);
            }
            else {
                message.Error("Service Report", "The file download failed to start.\n\nPlease try again, and if the error persists contact Customer Service for assistance.");
            }
        }

        catch (ex) {
            message.Error("Service Report", ex);
        }

        finally {
            serviceReportSet(null);
            hideLoader();
        }
    };

    const getSingleReport = async (report, salesOrderIds, jobDate) => {
        let url, reportName;

        if (!report) {
            message.Warning("Job Report", "The required report was not provided.");
            return;
        }

        if (!salesOrderIds || salesOrderIds.length === 0) {
            message.Warning("Job Report", "The Job ID was not provided.");
            return;
        }

        if (!jobDate) {
            message.Warning("Job Report", "The Job date was not provided.");
            return;
        }

        try {
            showLoader();

            switch (report) {
                case "CodReport":
                    url = apiUrls.CodReport;
                    reportName = "Certificate of Destruction";
                    break;

                case "JobDocketReport":
                    url = apiUrls.JobReport;
                    reportName = "Job Docket";
                    break;

                default:
                    let msg;

                    if (report)
                        msg = `The requested report "${report}" is not a valid report.`;
                    else
                        msg = "The name of the required was not provided.";

                    message.Warning("Invalid Report", msg);
                    return;
            }

            const data = await restHelper.getServiceReport(url, salesOrderIds, accessToken.token);

            if (data && data.size > 0) {
                let fileName = `${reportName} - ${moment(jobDate).format("YYYY-MM-DD")}.pdf`;
                saveAs(data, fileName);

                const model = JSON.stringify({ accountId: portalAccount.accountId, page: "Service", action: `Download ${reportName}`, details: `Requested By: ${portalUser.givenName} ${portalUser.surname}, Date: ${moment(jobDate).format("dddd, MMM D YYYY")}` });
                submitPortalHistory(apiUrls.SubmitPortalHistory, "post", model);
                message.Success(`${reportName}`, "The requested report has been successfully downloaded.");
            }

            else {
                message.Error(`${reportName}`, "The file download failed to start.\n\nPlease try again, and if the error persists contact Customer Service for assistance.");
            }
        }

        catch (ex) {
            message.Error(`${reportName ? `${reportName}` : "Job Report"}`, ex);
        }

        finally {
            serviceReportSet(null);
            hideLoader();
        }
    }

    const handleApplyFilter = data => {
        filterFromSet(data.dateFrom)
        filterToSet(data.dateTo)
        filterStatusSet(data.status)
        showServiceFilterSet(false);
    }

    const handleCancelJobDates = () => {
        showJobReportDatesSet(false);
        serviceReportSet(null);
    }

    const handleJobReportDates = async (jobReportDates) => {
        showJobReportDatesSet(false);
        getReportByDate(serviceReport, jobReportDates)
    };

    /**
     * Logs the service request in the Application Logs table and sends the necessary emails for the service request.
     * 1) Creates a record for the request in the Application Logs table.
     * 2) Notice to the customer acknowledging the request.
     * 3) Notice to Customer Service to action the request.
     * 4) If a contact other than the customer has been nominated then advise the contact we will be in touch.
     * @param {object} serviceRequest - The details (name, email, request,etc.) of the service request.
     */
    const sendServiceRequest = async (serviceRequest) => {
        showRequestServiceSet(false);
        showLoader();

        let emailsSent = true;

        // 1) Create application log...
        try {
            let details = {
                contactName: `${serviceRequest.contactFirstName} ${serviceRequest.contactLastName}`,
                contactEmail: serviceRequest.contactEmail,
                contactPhone: serviceRequest.contactPhone,
                siteAddress: serviceRequest.serviceAddress,
                description: serviceRequest.serviceDescription,
                submittedBy: serviceRequest.submittedBy,
                submittedByEmail: serviceRequest.submittedByEmail,
                submittedByDate: serviceRequest.submittedDt
            };

            // details = JSON.stringify(details);

            let apiModel = { can: portalAccount.accountNumber, logType: "information", category: SERVICE_REQUEST, details: details };
            await restHelper.logTransaction(apiModel, accessToken.token);
        }

        catch (ex) {
            message.Error(SERVICE_REQUEST, ex.message ?? ex);
            hideLoader();
            return;
        }

        // 2) Email customer...
        let emailModel = {
            templateName: `${SERVICE_REQUEST} - Customer`,
            firstName: portalUser.givenName,
            lastName: portalUser.surname,
            toAddress: portalUser.email,
            accountId: portalUser.accountId,
            formData: { contactFirstName: portalUser.givenName }
        };

        await submitEmail(apiUrls.Email, "post", JSON.stringify(emailModel))
            .then(null)
            .catch(ex => {
                emailsSent = false;
                message.Error("Email Confirmation", ex.message ?? ex)
            });

        // 3) Email customer care...
        const _address = { ...serviceRequest.serviceAddress };

        emailModel = {
            templateName: "Service Request - Customer Service",
            firstName: "Customer Service",
            lastName: "",
            toBranch: portalAccount?.branch,
            accountId: portalUser.accountId,
            formData: {
                can: portalUser.accountNumber,
                client: portalUser.accountName,
                contact: `${serviceRequest.contactFirstName} ${serviceRequest.contactLastName}`,
                email: serviceRequest.contactEmail,
                phone: serviceRequest.contactPhone,
                address: `${_address.line1}, ${_address.line2}, ${_address.suburb} ${_address.state} ${_address.postcode}`,
                request: serviceRequest.serviceDescription,
                submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                submittedByEmail: portalUser.contactEmail,
                submittedDt: serviceRequest.submittedDt,
            }
        };

        await submitEmail(apiUrls.Email, "post", JSON.stringify(emailModel)).then(null, rejected => {
            emailsSent = false;
            message.Error("Email Customer Service", rejected)
        });

        // 4) Email customer contact...
        if (portalUser.email !== serviceRequest.contactEmail) {
            const _address = { ...serviceRequest.serviceAddress };

            emailModel = {
                templateName: "Service Request - Contact",
                firstName: portalUser.givenName,
                lastName: portalUser.surname,
                toAddress: portalUser.email,
                can: portalUser.accountNumber,
                accountId: portalUser.accountId,
                formData: {
                    contact: `${serviceRequest.contactFirstName} ${serviceRequest.contactLastName}`,
                    email: serviceRequest.contactEmail,
                    address: `${_address.line1}, ${_address.suburb} ${_address.state} ${_address.postcode}`,
                    request: serviceRequest.serviceDescription,
                    submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                    submittedByEmail: portalUser.contactEmail,
                }
            };

            await submitEmail(apiUrls.Email, "post", JSON.stringify(emailModel)).then(() => {
                const model = JSON.stringify({ accountId: portalAccount.accountId, page: "Service", action: "Request", details: `Requested By: ${serviceRequest.contactFirstName} ${serviceRequest.contactLastName}, Description: ${serviceRequest.serviceDescription}` });
                submitPortalHistory(apiUrls.SubmitPortalHistory, "post", model);
            }, rejected => {
                emailsSent = false;
                message.Error("Email Contact", rejected)
            });
        }

        hideLoader();

        if (emailsSent)
            message.Success(SERVICE_REQUEST, "Your service request has been submitted.\n\n One of our Customer Service team will contact you within 1 business day to confirm the details.");
    }

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    return (
        <div className={styles["service-page"]}>
            <AuthenticateUser>
                {
                    (pageError) &&
                    <div className="text-center">
                        <h2 style={{ color: "red" }}>Error Loading Page</h2>
                        <div className="alert alert-danger">
                            {pageError}
                        </div>
                    </div>
                }

                {
                    (reloadServiceHistory && !pageError) &&
                    <PageLoading message="Fetching Service history, please wait..." />
                }

                {
                    (!pageError && !reloadServiceHistory && pageLoaded) &&
                    <>
                        <Card className="mt-0">
                            <CardHeader>
                                <div className={styles.header}>
                                    <div className={styles["header-title"]}>
                                        Service Schedule
                                    </div>

                                    <div className={styles["header-options"]}>
                                        <div>
                                            <button
                                                type="button"
                                                className={styles["btn-closed-dates"] + " btn btn-sm btn-sx me-1"}
                                                title="Advise dates the business will be closed"
                                                onClick={() => { showClosedDatesSet(true); }}>
                                                <FontAwesomeIcon icon="calendar-alt" /> Closed Dates
                                            </button>
                                        </div>

                                        <div className="dropdown">
                                            <button
                                                type="button"
                                                id="serviceReports"
                                                className="btn btn-success btn-sm dropdown-toggle"
                                                title="Display the available reports"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <FontAwesomeIcon icon="file-alt" /> Reports
                                            </button>

                                            <ul className="dropdown-menu" aria-labelledby="serviceReports">
                                                <li
                                                    className="dropdown-item"
                                                    title="Download the Job Dockets report"
                                                    onClick={() => serviceReportSet("JobDocketReport")}>
                                                    <FontAwesomeIcon icon="clipboard" style={{ color: "#ffca2c" }} /> Job Docket
                                                </li>

                                                <li
                                                    className="dropdown-item"
                                                    title="Download the Certificates of Destruction report"
                                                    onClick={() => serviceReportSet("CodReport")}>
                                                    <FontAwesomeIcon icon="file-alt" style={{ color: "#bb2d3b" }} /> Certificate of Destruction
                                                </li>

                                                <li
                                                    className="dropdown-item"
                                                    title="Download the Eco Statement"
                                                    onClick={() => serviceReportSet("EcoReport")}>
                                                    <FontAwesomeIcon icon="leaf" style={{ color: "#198754" }} /> Eco Statement
                                                </li>

                                                <li>
                                                    <Link
                                                        className="dropdown-item"
                                                        to="scheduled-jobs">
                                                        <FontAwesomeIcon icon="calendar-alt" style={{ color: "#0b5ed7" }} /> Job Calendar
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <div>
                                            <button type="button" className="btn btn-sx btn-sm ms-1" onClick={() => showServiceFilterSet(true)} title="Filter the displayed services"><FontAwesomeIcon icon="filter" /> Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody>
                                {filteredSalesOrders?.length === 0 &&
                                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                                        <h5>There are no service records to display</h5>
                                        <p>If you have applied a filter try changing the criteria to display your service records</p>
                                    </div>
                                }

                                {filteredSalesOrders?.length > 0 &&
                                    <ServiceScheduleTable jobs={[...filteredSalesOrders]} triggerReport={getSingleReport} />
                                }
                            </CardBody>
                        </Card>

                        {showRequestService &&
                            <RequestService
                                isOpen={showRequestService}
                                onSendClick={(serviceRequest) => sendServiceRequest(serviceRequest)}
                                onCancelClick={() => cancelServiceRequest()} />
                        }

                        {showServiceFilter &&
                            <ServiceFilter
                                isOpen={showServiceFilter}
                                closeFilter={() => showServiceFilterSet(false)}
                                applyFilter={(data) => handleApplyFilter(data)} />
                        }

                        {showClosedDates &&
                            <ClosedDatesRequest
                                controlSize="sm"
                                isOpen={showClosedDates}
                                onCloseClick={() => showClosedDatesSet(false)}
                            />
                        }

                        {showJobReportDates &&
                            <JobReportDates
                                isOpen={showJobReportDates}
                                report={serviceReport}
                                onOkClick={(closedJobReportDates) => handleJobReportDates(closedJobReportDates)}
                                onCloseClick={() => handleCancelJobDates()} />
                        }
                    </>
                }

                {loader}
            </AuthenticateUser>
        </div>
    );
};
