import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import { useRecoilState } from "recoil";
import { accessTokenState, accountState, userState } from "../../Shared/atom";

import dataValid from "../../Shared/dataValid";
import emailHelper from "../../Shared/emailHelper";
import message from "../../Shared/message";
import phoneHelper from "../../Shared/phoneHelper";
import restHelper from "../../Shared/restHelper";

import logo from "../../Images/SDX-banner-logo.png";

import Map from "../common/Map.component";
import useFullPageLoader from "../../Hooks/useFullPageLoader";

import Input from "../common/Input.Component"

import "./ContactUs.scss";

const ContactUsPage = props => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [portalAccount] = useRecoilState(accountState);
    const [portalUser] = useRecoilState(userState);

    // -- Page State ----------------------------------------------------------------------------------------------------------------------
    const [branches, branchesSet] = useState(false);

    const [contact, contactSet] = useState({
        "firstName": portalAccount.billingContact.firstName,
        "lastName": portalAccount.billingContact.lastName,
        "email": portalAccount.billingContact.email,
        "phone": portalAccount.billingContact.preferredNumber ? phoneHelper.format(portalAccount.billingContact.preferredNumber) : null,
        "errors": []
    });

    const [archiveBoxes, archiveBoxesSet] = useState(false);
    const [documentDestruction, documentDestructionSet] = useState(false);
    const [eWasteDestruction, eWasteDestructionSet] = useState(false);
    const [eWasteRecycling, eWasteRecyclingSet] = useState(false);
    const [otherService, otherServiceSet] = useState(false);
    const [paperRecycling, paperRecyclingSet] = useState(false);
    const [printersWaste, printersWasteSet] = useState(false);
    const [relocationService, relocationServiceSet] = useState(false);
    const [uniformDestruction, uniformDestructionSet] = useState(false);

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    document.title = "Shred-X: Contact Us";
    const CONTACT_US = "Contact Us";

    // -- State Events --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        // TODO 2022-06-23: Need to get this data from the CRM...
        import(`./branches.json`)
            .then(data => {
                branchesSet(data.default)
            });

        if (accessToken.token) {
            restHelper.addAuditEntry(CONTACT_US, "View", null, accessToken.token);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken.token]);

    // -- Functions -----------------------------------------------------------------------------------------------------------------------
    const submitHandler = async (event) => {
        event.preventDefault();

        if (!isFormValid()) {
            message.Warning(CONTACT_US, "Please correct the data as shown before submitting.");
            return;
        }

        let requestedInfo = "";
        let customerPosted = true;
        let servicePosted = true;

        if (archiveBoxes) requestedInfo = "Archive box removal";
        if (documentDestruction) requestedInfo += ", Secure document destruction";
        if (eWasteDestruction) requestedInfo += ", e-Waste destruction";
        if (eWasteRecycling) requestedInfo += ", e-Waste recycling";
        if (otherService) requestedInfo += ", Other services";
        if (paperRecycling) requestedInfo += ", Paper recycling";
        if (printersWaste) requestedInfo += ", Printer's waste solutions";
        if (relocationService) requestedInfo += ", Office clean-out and relocation services";
        if (uniformDestruction) requestedInfo += ", Product, textile or uniform destruction";

        if (requestedInfo) {
            if (requestedInfo.substring(0, 2) === ", ")
                requestedInfo = requestedInfo.substring(2);
        }
        else {
            requestedInfo = null;
        }

        let emailModel = {
            templateName: `${CONTACT_US} - Customer`,
            firstName: portalUser.givenName,
            lastName: portalUser.surname,
            senderEmail: portalUser.email,
            toAddress: contact.email,
            accountId: portalUser.accountId,
            formData: {
                can: portalAccount.accountNumber,
                client: portalAccount.accountName,
                contact: `${contact.firstName} ${contact.lastName}`,
                email: contact.email,
                phone: contact.phone,
                comment: contact.comment,
                requestedInfo,
                submittedBy: `${portalUser.givenName} ${portalUser.surname}`,
                submittedByEmail: portalUser.email,
                submittedDt: new Date(),
            },
        };

        try {
            showLoader();
            await restHelper.sendGridEmail(emailModel, accessToken.token)
        }

        catch (ex) {
            message.Error(`${CONTACT_US} - Customer Email`, ex.message ?? ex);
            customerPosted = false;
        }

        finally {
            hideLoader();
        }

        try {
            showLoader();
            emailModel.templateName += " Service";
            await restHelper.sendGridEmail(emailModel, accessToken.token)
        }
        catch (ex) {
            message.Error(`${CONTACT_US} - Customer Service Email`, ex.message ?? ex);
            servicePosted = false;
        }
        finally {
            hideLoader();
        }

        if (customerPosted && servicePosted){
            archiveBoxesSet(false);
            documentDestructionSet(false);
            eWasteDestructionSet(false);
            eWasteRecyclingSet(false);
            otherServiceSet(false);
            paperRecyclingSet(false);
            printersWasteSet(false);
            relocationServiceSet(false);
            uniformDestructionSet(false);
            
            let _contact = {...contact};
            _contact.comment = "";
            contactSet(_contact);

            message.Information(CONTACT_US, "Your post has been successfully submitted.");
        }
    };

    const isFormValid = () => {
        let dataType;
        let evt;
        let isValid = true;

        // If a field has been edited it's error entry will either equal "null" if the data is valid or an error message.
        // If it equals "undefined" the user hasn't edited it so we need to ensure it contains valid data...
        if (contact.errors.firstName === undefined) {
            evt = { target: { id: "firstName", value: contact.firstName } }
            validateInput(evt, false);
        }

        dataType = { type: "text", required: true, minLength: 2, maxLength: 25 }
        if (!dataValid.validateData(dataType, contact.firstName)) isValid = false;

        if (contact.errors.lastName === undefined) {
            evt = { target: { id: "lastName", value: contact.lastName } }
            validateInput(evt, false);
        }

        dataType = { type: "text", required: true }
        if (!dataValid.validateData(dataType, contact.lastName)) isValid = false;

        dataType = { type: "text", minLength: 2 }
        if (!dataValid.validateData(dataType, contact.lastName)) isValid = false;

        dataType = { type: "text", maxLength: 25 }
        if (!dataValid.validateData(dataType, contact.lastName)) isValid = false;

        if (contact.errors.email === undefined) {
            evt = { target: { id: "email", value: contact.email } }
            validateInput(evt, false);
        }

        dataType = { type: "text", required: true }
        if (!dataValid.validateData(dataType, contact.email)) isValid = false;

        dataType = { type: "email" }
        if (!dataValid.validateData(dataType, contact.email)) isValid = false;

        if (contact.errors.phone === undefined) {
            evt = { target: { id: "phone", value: contact.phone } }
            validateInput(evt, false);
        }

        dataType = { type: "text", required: true }
        if (!dataValid.validateData(dataType, contact.phone)) isValid = false;

        dataType = { type: "phone" }
        if (!dataValid.validateData(dataType, contact.phone)) isValid = false;

        if (contact.errors.comment === undefined) {
            evt = { target: { id: "comment", value: contact.comment } }
            validateInput(evt, false);
        }

        dataType = { type: "text", required: true }
        if (!dataValid.validateData(dataType, contact.comment)) isValid = false;

        return isValid;
    }

    const validateInput = (evt) => {
        const tgt = evt.target;
        let value = tgt.value;
        let _contact = { ...contact };

        _contact.errors[tgt.id] = null;

        switch (tgt.id) {
            case "comment":
                if (!value)
                    _contact.errors[tgt.id] = "This is a required field";
                else if (value.length < 20)
                    _contact.errors[tgt.id] = "This field requires a minimum of 20 characters to ensure sufficient detail is provided";
                break;

            case "firstName":
            case "lastName":
                if (!value)
                    _contact.errors[tgt.id] = "This is a required field";
                else if (value.length < 2 || value.length > 25)
                    _contact.errors[tgt.id] = "Names must contain between 2 and 25 characters";

                break;

            case "email":
                if (!value)
                    _contact.errors[tgt.id] = "This is a required field";
                else if (!emailHelper.isValidEmail(value))
                    _contact.errors[tgt.id] = "This does not appear to be a valid email address";

                break;

            case "phone":
                if (!value)
                    _contact.errors[tgt.id] = "This is a required field";
                else if (!phoneHelper.isValid(value))
                    _contact.errors[tgt.id] = "This does not appear to be a valid phone number";

                break;

            default:
                break;
        }

        _contact[tgt.id] = value;
        contactSet(_contact);
    };

    const handleFocus = (evt) => evt.target.select();

    const handleChange = (evt) => {
        const tgt = evt.target;
        let _contact = { ...contact };

        _contact[tgt.id] = tgt.value;
        contactSet(_contact);
    };

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    const commentError = contact.errors.comment ?? null;
    const emailError = contact.errors.email ?? null;
    const firstNameError = contact.errors.firstName ?? null;
    const lastNameError = contact.errors.lastName ?? null;
    const phoneError = contact.errors.phone ?? null;

    return (
        <div className="contact-us">
            <div className="contact-form">
                <div className="header text-center mb-3 logo">
                    <img src={logo} alt="Shred-X Logo" />
                </div>

                <form noValidate>
                    <div className="content">
                        <label className="col-form-label">Phone:</label>
                        <label className="col-form-label">1300 SHREDX (1300&nbsp;747&#8209;339)</label>

                        <label className="col-form-label">Email:</label>
                        <a href="mailto:info@shred-x.com.au">info@shred-x.com.au</a>

                        <label className="col-form-label text-nowrap mandatory">First Name:</label>
                        <Input
                            id="firstName"
                            error={firstNameError}
                            value={contact.firstName}
                            onBlur={validateInput}
                            onChange={handleChange}
                            onFocus={handleFocus} />

                        <label className="col-form-label text-nowrap mandatory">Last Name:</label>
                        <Input
                            id="lastName"
                            error={lastNameError}
                            value={contact.lastName}
                            onBlur={validateInput}
                            onChange={handleChange}
                            onFocus={handleFocus} />

                        <label className="col-form-label mandatory">Email:</label>
                        <Input
                            id="email"
                            error={emailError}
                            value={contact.email}
                            onBlur={validateInput}
                            onChange={handleChange}
                            onFocus={handleFocus} />

                        <label className="col-form-label mandatory">Phone:</label>
                        <Input
                            id="phone"
                            error={phoneError}
                            value={contact.phone}
                            onBlur={validateInput}
                            onChange={handleChange}
                            onFocus={handleFocus} />
                    </div>

                    <Input
                        id="comment"
                        type="textarea"
                        rows="5"
                        placeholder="How can we help?"
                        error={commentError}
                        value={contact.comment}
                        onBlur={validateInput}
                        onChange={handleChange}
                        onFocus={handleFocus} />

                    <div className="row options-row">
                        <p>I am also interested in;</p>

                        <div className="form-options">
                            <div className="col1">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="eWasteDestruction"
                                        className="form-check-input me-2"
                                        checked={eWasteDestruction}
                                        onChange={() => eWasteDestructionSet(!eWasteDestruction)} />

                                    <label className="form-check-label" htmlFor="eWasteDestruction" style={{ fontWeight: "400" }}>e-Waste Destruction</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="eWasteRecycling"
                                        className="form-check-input me-2"
                                        checked={eWasteRecycling}
                                        onChange={() => eWasteRecyclingSet(!eWasteRecycling)} />

                                    <label className="form-check-label" htmlFor="eWasteRecycling" style={{ fontWeight: "400" }}>e-Waste Recycling</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="documentDestruction"
                                        className="form-check-input me-2"
                                        checked={documentDestruction}
                                        onChange={() => documentDestructionSet(!documentDestruction)} />

                                    <label className="form-check-label" htmlFor="documentDestruction" style={{ fontWeight: "400" }}>Secure Document Destruction</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="relocationService"
                                        className="form-check-input me-2"
                                        checked={relocationService}
                                        onChange={() => relocationServiceSet(!relocationService)} />

                                    <label className="form-check-label" htmlFor="relocationService" style={{ fontWeight: "400" }}>Office Clean-out &amp; Relocation Destruction Services</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="uniformDestruction"
                                        className="form-check-input me-2"
                                        checked={uniformDestruction}
                                        onChange={() => uniformDestructionSet(!uniformDestruction)} />

                                    <label className="form-check-label" htmlFor="uniformDestruction" style={{ fontWeight: "400" }}>Products, Textiles or Uniform Destruction</label>
                                </div>
                            </div>

                            <div className="col2">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="paperRecycling"
                                        className="form-check-input me-2"
                                        checked={paperRecycling}
                                        onChange={() => paperRecyclingSet(!paperRecycling)} />

                                    <label className="form-check-label" htmlFor="paperRecycling" style={{ fontWeight: "400" }}>Paper Recycling Services</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="archiveBoxes"
                                        className="form-check-input me-2"
                                        checked={archiveBoxes}
                                        onChange={() => archiveBoxesSet(!archiveBoxes)} />

                                    <label className="form-check-label" htmlFor="archiveBoxes" style={{ fontWeight: "400" }}>Archive Box Removal</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="printersWaste"
                                        className="form-check-input me-2"
                                        checked={printersWaste}
                                        onChange={() => printersWasteSet(!printersWaste)} />

                                    <label className="form-check-label" htmlFor="printersWaste" style={{ fontWeight: "400" }}>Printer's Waste Solutions</label>
                                </div>

                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="otherService"
                                        className="form-check-input me-2"
                                        checked={otherService}
                                        onChange={() => otherServiceSet(!otherService)} />

                                    <label className="form-check-label" htmlFor="otherService" style={{ fontWeight: "400" }}>Other</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Row className="mb-3 mb-lg-0 justify-content-end">
                        <Col xs={"auto"}>
                            <button className="btn btn-primary" onClick={(evt) => submitHandler(evt)} title="Submit the form">
                                Submit
                            </button>
                        </Col>
                    </Row>
                </form>
            </div >

            <div className="contact-links">
                <div className="contact-services">
                    <div className="header">Guaranteed protection of your proprietary and sensitive information</div>
                    <a className="service1" href="https://www.shred-x.com.au/document-destruction/" target="ShredX"><img src="/images/paper.png" alt="Paper and Document Destruction" /></a>
                    <a className="service2" href="https://www.shred-x.com.au/e-waste/" target="ShredX"><img src="/images/ewaste.png" alt="Electronic Waste" /></a>
                    <a className="service3" href="https://www.shred-x.com.au/product-uniform-destruction/" target="ShredX"><img src="/images/textile.png" alt="Products and Textiles" /></a>
                    <a className="service4" href="https://www.shred-x.com.au/product-uniform-destruction/" target="ShredX"><img src="/images/recycle.png" alt="Recycling" /></a>
                    <div className="separator" />
                </div>

                <div className="contact-certifications">
                    <img className="cert1" src="/images/ISO9001.png" alt="ISO 9001 Certification" />
                    <img className="cert2" src="/images/ISO14001.png" alt="ISO 14001 Certification" />
                    <img className="cert3" src="/images/ISO45001.png" alt="ISO 45001 Certification" />
                    <img className="cert4" src="/images/ISO27001.png" alt="ISO 27001 Certification" />
                    <img className="cert5" src="/images/NAID-AAA-Logo.png" alt="NAID AAA Certified" />
                </div>
            </div>

            <div className="contact-map">
                <Map branches={branches} />
            </div>

            {loader}
        </div >
    );
};

export default ContactUsPage;
