import commonHelper from "./commonHelper";

class PhoneHelper {

    // Removes all characters other than numbers and letters from the phone number and converts letters to upper case...
    static cleanNumber(phoneNumber) {
        if (!commonHelper.hasValue(phoneNumber)) return phoneNumber;

        // Remove any non-numeric characters...
        phoneNumber = phoneNumber.replace(/[-+()\s[A-Za-z]/g, "");
        phoneNumber = phoneNumber.trim();
        return phoneNumber;

    }

    // Confirms the number contains valid characters for a phone number NOT if the phone number is valid.
    // For example; "04" is valid, "04#" is not, "1300-SHREDX" is also valid...
    static hasValidCharacters = (number) => {
        // If the number contains the standard characters for a phone number...
        if ((/^[-+()\s\d]+$/).test(number)) return true;

        // 2023-08-29: Don't allow alphabetic characters as the driver app won't be able to dial them...
        // 1800, 1300 and 13 number can also contain letters...
        // if (number.startsWith("1800") || number.startsWith("13")) {
        //     if ((/^[-+()\s0-9A-Z]+$/).test(number.toUpperCase())) return true;
        // }

        return false;
    }

    // Confirms the phone number is a valid number for use in Australia.
    // Allowed values are numbers such as;
    // National: 
    //      0[2,3,7,8] - area codes
    // Mobile:
    //      0[4,5] - mobile numbers
    // Local Rate and Freecall: 
    //      1800 & 1300 eg 1800 111-111 or 1300&nbsp;SHREDX (note these numbers only have 6 characters following but many companies add characters to form a word)
    //      13 eg 13 1111 or 13 SHREDX (note that 13 numbers only have 4 characters following but many companies add characters to form a word)
    // International: 
    //      0011 & "+"" e.g. "0011 61 411 111 111" or "+61 2 1111 1111" or "+61 (0)2 1111 1111" are all valid international numbers
    static isValid(phoneNumber) {
        // Remove any non-numeric characters...
        let _number = this.cleanNumber(phoneNumber);
        if (_number.length === 0) return false;

        // 1800 and 1300 numbers...
        if (_number.startsWith("1800") || _number.startsWith("1300")) {
            // If it only contains number...
            if ((/^[\d]+$/).test(_number)) {
                return (_number.length === 10);
            }

            // If the number contains letters...
            // else if ((/^[0-9A-Z]+$/).test(_number)) {
            //     // It must still be a minimum of 10 characters...
            //     return (_number.length >= 10);
            // }
        }

        // 13 numbers...
        else if (_number.startsWith("13")) {
            // If it only contains number...
            if ((/^[\d]+$/).test(_number)) {
                return (_number.length === 6);
            }

        //     // If the number contains letters...
        //     else if ((/^[0-9A-Z]+$/).test(_number)) {
        //         return (_number.length >= 6);
        //     }
        }

        // Mobile numbers...
        else if (/[0][4,5]/.test(_number.substr(0, 2))) {
            return (_number.length === 10);
        }

        // National numbers...
        else if (/[0][2,3,7,8]/.test(_number.substr(0, 2))) {
            return (_number.length === 10);
        }

        else if (_number.startsWith("+") || _number.startsWith("0011")) {
            return true;
        }

        return false;
    }

    static format(phoneNumber) {
        if (!phoneNumber) return phoneNumber;

        phoneNumber = this.cleanNumber(phoneNumber);
        if (!phoneNumber || phoneNumber.length === 0) return phoneNumber;

        if (phoneNumber.startsWith("1800") || phoneNumber.startsWith("1300")) {

            // If it only contains number...
            if ((/^[\d]+$/).test(phoneNumber)) {
                if (phoneNumber.length === 10)
                    phoneNumber = `${phoneNumber.substr(0, 4)} ${phoneNumber.substr(4, 3)}-${phoneNumber.substr(7, 3)}`;
            }

            // If the number contains letters...
            // else if ((/^[0-9A-Z]+$/).test(phoneNumber)) {
            //     if (phoneNumber.length >= 10)
            //         phoneNumber = `${phoneNumber.substr(0, 4)} ${phoneNumber.substr(4, phoneNumber.length - 4)}`;
            // }
        }

        else if (phoneNumber.startsWith("13")) {
            // If it only contains number...
            if ((/^[\d]+$/).test(phoneNumber)) {
                if (phoneNumber.length === 6)
                    phoneNumber = `${phoneNumber.substr(0, 2)} ${phoneNumber.substr(2, phoneNumber.length - 2)}`;
            }

            // If the number contains letters...
            // else if ((/^[0-9A-Z]+$/).test(phoneNumber)) {
            //     if (phoneNumber.length >= 6)
            //         phoneNumber = `${phoneNumber.substr(0, 2)} ${phoneNumber.substr(2, phoneNumber.length - 2)}`;
            // }
        }

        else if (/[0][4,5]/.test(phoneNumber.substr(0, 2))) {
            if (phoneNumber.length === 10)
                phoneNumber = `(${phoneNumber.substr(0, 4)}) ${phoneNumber.substr(4, 3)}-${phoneNumber.substr(7, 3)}`;
        }

        else if (/[0][2,3,7,8]/.test(phoneNumber.substr(0, 2))) {
            if (phoneNumber.length === 10)
                phoneNumber = `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(2, 4)}-${phoneNumber.substr(6, 4)}`;
        }

        return phoneNumber;
    }
}

export default PhoneHelper;