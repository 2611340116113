import React, { Fragment, useState, useEffect } from "react"
import { Redirect, useParams } from "react-router-dom"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react"
import parse from "html-react-parser";

import * as apiUrls from "../../Shared/endPoints"
import { Button } from "reactstrap";
import toastMessage from "../../Shared/message";
import { SignUp } from "./SignUp.Component";
import useHttp from "../../Hooks/useHttp"

import logo from "../../Images/SDX-banner-logo.png";

import styles from "./Register.module.scss"
import RestHelper from "../../Shared/restHelper";

export const RegisterPage = (props) => {
    // -- Page State ----------------------------------------------------------------------------------------------------------------------
    const [appMessage, appMessageSet] = useState(null)
    const [contactAccountId, contactAccountIdSet] = useState(null)
    const [contactEmail, contactEmailSet] = useState(null)
    const [contactFirstName, contactFirstNameSet] = useState(null)
    const [contactLastName, contactLastNameSet] = useState(null)
    const [linkVerified, linkVerifiedSet] = useState(null)
    const [resendEmail, resendEmailSet] = useState(false)
    const [showLogin, showLoginSet] = useState(false)

    // -- Page Requests -------------------------------------------------------------------------------------------------------------------
    const { request: submitResendRegistrationEmail } = useHttp("Resend Registration Email", false)

    // -- Scope Variables -----------------------------------------------------------------------------------------------------------------
    const isAuthenticated = useIsAuthenticated()
    const { registrationId } = useParams()

    // -- State Events ---------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (!isAuthenticated && registrationId) {
            const verifyRegistration = async () => {
                try {
                    const data = await RestHelper.verifyRegistration(registrationId)

                    linkVerifiedSet(data.isVerified);
                    resendEmailSet(data.isExpired);
                    showLoginSet(!data.isVerified && !data.isExpired);
                    appMessageSet(data.message ? parse(toastMessage.replaceNewLine(data.message)) : null);
                    contactAccountIdSet(data.accountId)
                    contactEmailSet(data.contactEmail)
                    contactFirstNameSet(data.contactFirstName)
                    contactLastNameSet(data.contactLastName)
                }

                catch (ex) {
                    // toastMessage.Error("Verify Registration Link", ex);
                    appMessageSet(parse("The registration link is invalid.<br/><br/>If your registration link had expired and you resent the registration email, please ensure you used the link from the latest email to register.<br/><br/>If you need further assistance, please contact Customer Service on 1300&nbsp;SHREDX (1300&nbsp;747&#8209;339) for assistance in completing your registration."));
                    showLoginSet(true);
                    linkVerifiedSet(false);
                }
            }

            verifyRegistration();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -- Form Methods --------------------------------------------------------------------------------------------------------------------
    const handleResendEmail = () => {
        submitResendRegistrationEmail(`${apiUrls.ResendRegistrationEmail}/${registrationId}`, "post", null, { noAuth: true }).then(() => {
            resendEmailSet(false);
            showLoginSet(false);
            appMessageSet("A new registration link has been sent to your email address.");
        })
        return;
    }

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    return (
        <Fragment>
            <AuthenticatedTemplate>
                <Redirect to="/dashboard" />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div className={styles.page}>
                    <div className={styles.panel}>
                        <div className={styles.contentHeader}>
                            {/* "&#8209;" is a non-breaking hyphen so the line won't wrap "Shred-X" at the hyphen*/}
                            <h2>Welcome to the Shred&#8209;X Client Portal</h2>
                        </div>

                        <div className={styles.contentDetail}>
                            {linkVerified === null && <p>Verifying your registration link, please wait...</p>}

                            {!linkVerified !== null && !linkVerified &&
                                <div className="text-center">
                                    <p>{appMessage}</p>

                                    {resendEmail &&
                                        <Button className="btn btn-dark px-5" onClick={handleResendEmail}>Resend Registration Email</Button>
                                    }

                                    {showLogin &&
                                        <>
                                            <p>If you know your user name and password then you can login <a href="/" style={{ color: "black" }}>here</a>.</p>
                                        </>
                                    }
                                </div>
                            }

                            {linkVerified && !appMessage && <SignUp registrationId={registrationId} accountId={contactAccountId} contactEmail={contactEmail} contactFirstName={contactFirstName} contactLastName={contactLastName} />}
                        </div>

                        <div className={styles.contentLogo}>
                            <img height="auto" src={logo} alt="Shred-X Logo" />
                        </div>

                        <div className={styles.contentFooter}>
                            <div className={styles.ftl}></div>
                            <div className={styles.fbl}></div>

                            <div className={styles.footerRight}>
                                <div className={styles.footerRightClip}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </Fragment>
    )
}
