import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader, Row, Col, Label, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useRecoilState } from "recoil";
import { accessTokenState, accountBalanceState, accountState, serviceHistoryState, accountTransactionsState, portalHistoryState, userState } from "../../Shared/atom";

import moment from "moment";
import parse from "html-react-parser";

import AuthenticateUser from "../common/AuthenticateUser.Component";
import PageLoading from "../common/PageLoading.Component";

import Spinner from "../../Images/Infinity.svg";
// import holidayClosure from "../../Images/HolidayClosure.gif"
import introVideo from "../../Images/IntroVideo.jpg";
import accountProfileIcon from "../../Images/dashboard-icon-02.png";
import billingIcon from "../../Images/dashboard-icon-05.png";
import serviceScheduleIcon from "../../Images/dashboard-icon-03.png";
import accountHistoryIcon from "../../Images/dashboard-icon-09.png";

import CurrencyHelper from "../../Shared/currencyHelper";
import dateHelper from "../../Shared/dateHelper";
import restHelper from "../../Shared/restHelper";

import ClosedDatesRequest from "../Service/ClosedDatesRequest.Component"
import Payway from "../Billing/Payway.Component";

import useHttp from "../../Hooks/useHttp";

import * as apiUrls from "../../Shared/endPoints";
import * as dataTransformers from "../../Shared/dataTransformers";
import message from "../../Shared/message";

import styles from "./Dashboard.module.scss";

export const DashboardPage = () => {
    // -- Global State --------------------------------------------------------------------------------------------------------------------
    const [accessToken] = useRecoilState(accessTokenState);
    const [portalUser] = useRecoilState(userState);

    const [accBalance, accBalanceSet] = useRecoilState(accountBalanceState);
    const [accountTransactions, accTransactionsSet] = useRecoilState(accountTransactionsState);
    const [paymentTransactions, paymentTransactionsSet] = useState(null);
    const [portalAccount, portalAccountSet] = useRecoilState(accountState);
    const [portalHistoryData, portalHistoryDataSet] = useRecoilState(portalHistoryState)
    const [serviceHistory, serviceHistorySet] = useRecoilState(serviceHistoryState);

    // -- Local State --------------------------------------------------------------------------------------------------------------------
    const [currentAccountId, currentAccountIdSet] = useState(null);
    const [hideClosedDates, hideClosedDatesSet] = useState(false);
    const [loadAccount, loadAccountSet] = useState(false);
    const [pageErrors, pageErrorsSet] = useState(false);
    const [pageLoaded, pageLoadedSet] = useState(false);
    const [reloadPortalHistory, reloadPortalHistorySet] = useState(false);
    const [reloadServiceHistory, reloadServiceHistorySet] = useState(false);
    const [reloadTransactions, reloadTransactionsSet] = useState(false);
    const [showClosedDates, showClosedDatesSet] = useState(false);
    const [showPaywayModal, showPaywayModalSet] = useState(false);

    //    const { request: getBillingData, isLoading: loadingAccountData } = useHttp("Accounts", false);
    const { request: getBillingData } = useHttp("Accounts");
    const { request: getPortalHistoryData, responseData: portalHistory } = useHttp("Portal History");
    const { request: postAuditHistory } = useHttp();

    document.title = "Shred-X: Dashboard";

    const CUSTOMER_SERVICE = "Please try logging in again and if unsuccessful contact Customer Service on 1300 SHREDX (1300&nbsp;747&#8209;339) for assistance."

    // -- Page Effects --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const loadPage = async () => {
            try {
                let _pageErrors;

                if (!portalUser.isStaff && !portalAccount && portalUser.accountId !== portalAccount?.accountId) {
                    try {
                        loadAccountSet(true);

                        let accountData = await restHelper.getAccount(portalUser.accountId, accessToken.token);

                        // TODO 2024-03-15: Modify the CrmApi and SDxPortal code to throw an error through to here rather than handle as no content...
                        if (!accountData) {
                            _pageErrors += "Failed to locate your business account in the portal.\n"
                        }

                        const portalData = { "accountId": portalUser.accountId, "userId": portalUser.userId, "userName": `${portalUser.givenName} ${portalUser.surname}`, "page": "Login", "action": "Authenticated" };
                        await restHelper.submitPortalHistory(portalData, accessToken.token);

                        //TODO 2024-03-15: Check what we need the "notFound" value for...
                        if (accountData?.siteAddress) {
                            accountData.siteAddress.notFound = false;
                        }

                        portalAccountSet(accountData);
                    }

                    catch (ex) {
                        _pageErrors += `${ex.message ?? ex}\n\n${CUSTOMER_SERVICE}`;
                    }

                    finally {
                        loadAccountSet(false);
                    }
                }

                if (!currentAccountId && accountTransactions && serviceHistory) {
                    currentAccountIdSet(portalAccount.accountId);
                    reloadPortalHistorySet(true);
                }

                else if (portalAccount && currentAccountId !== portalAccount.accountId) {
                    postAuditHistory(apiUrls.AddAuditEntry, "post", JSON.stringify({ accountId: portalAccount.accountId, page: "Account", action: "View", detail: null }));

                    console.log(`${dateHelper.FormatDate(new Date(), 'LogDate')} Setting Account ID to: ${portalAccount.accountId}`);
                    currentAccountIdSet(portalAccount.accountId);

                    reloadPortalHistorySet(true);
                    reloadServiceHistorySet(true);
                    reloadTransactionsSet(true);
                }

                // -- Closed Dates Banner -------------------------------------------------------------------------------------------------
                // Used to hide the "Updated closed dates banner" on the Thursday before Easter...
                const today = new Date();
                const cutOff = new Date("2024-03-28");

                if (today.getTime() > cutOff.getTime()) hideClosedDatesSet(true);
                // ------------------------------------------------------------------------------------------------------------------------

                if (_pageErrors) {
                    throw new Error(_pageErrors)
                }

                else {
                    pageLoadedSet(true);
                }
            }

            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");

                pageErrorsSet(parse(errMsg));
                pageLoadedSet(false);
            }
        };

        if (portalUser && accessToken.token) loadPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, portalAccount, portalUser]);

    useEffect(() => {
        const loadPortalData = async () => {
            console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetching portal history...`);

            await getPortalHistoryData(`${apiUrls.GetPortalHistory}/${portalAccount.accountId}`);

            if (portalHistory) {
                portalHistoryDataSet(portalHistory);
                console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetched ${portalHistory.length} portal records...`);
            }

            reloadPortalHistorySet(false);
        }

        if (portalAccount && reloadPortalHistorySet) loadPortalData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPortalHistory]);

    useEffect(() => {
        const loadServiceHistory = (async () => {
            try {
                console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetching service history...`);
                let data = await restHelper.getServiceHistory(portalAccount.accountId, accessToken.token);

                if (data) {
                    data.sort((a, b) => dateHelper.Sort(new Date(a.recordDate), new Date(b.recordDate), true))
                }

                else {
                    // Need to set the data to an empty array so the UI is updated correctly...
                    data = [];
                }

                console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetched ${data.length} service records...`);

                serviceHistorySet(data);
            }

            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");
                pageErrorsSet(parse(errMsg));
            }

            finally {
                reloadServiceHistorySet(false);
            }
        })

        if (portalAccount && loadServiceHistory) loadServiceHistory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadServiceHistory]);

    useEffect(() => {
        const loadTransactions = (async () => {
            try {
                console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetching transactions...`);

                await getBillingData(`${apiUrls.GetTransactions}?id=${portalAccount.axAccountNumber}`)
                    .then(data => {
                        const accountTransactions = dataTransformers.transformBillingData(data);
                        console.log(`${dateHelper.FormatDate(new Date(), "LogDate")} Fetched ${accountTransactions.transactions.length} transactions...`);

                        accTransactionsSet(accountTransactions.transactions || []);
                        accBalanceSet(accountTransactions.accountBalance || 0);
                        reloadTransactionsSet(false);
                    })
                    .catch(ex => {
                        message.Display({ type: "error", title: "Account Transactions", text: ex.message ?? ex })
                        reloadTransactionsSet(false);
                    });
            }

            catch (ex) {
                let errMsg = ex?.message ?? ex;
                errMsg = errMsg.replace("\n", "<br/>");
                pageErrorsSet(parse(errMsg));
            }
        })

        if (portalAccount && reloadTransactions) loadTransactions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadTransactions]);

    // -- Page Methods --------------------------------------------------------------------------------------------------------------------

    /**
     * Displays the payment form if there are any unpaide invoices.
      */
    const handlePayInvoices = () => {
        const _unpaidInvoices = (accountTransactions.filter((invoice) => invoice.amount > 0 && invoice.paymentStatus === "Unpaid"));

        if (_unpaidInvoices?.length === 0) {
            message.Information("Invoice Payment", "There are no unpaid invoices.");
            return;
        }

        paymentTransactionsSet(_unpaidInvoices);
        showPaywayModalSet(true);
    };

    const handlePaymentClose = () => {
        showPaywayModalSet(false);
    };

    const handlePaymentSuccess = () => {
        showPaywayModalSet(false);
    };

    const nextServices = serviceHistory?.length > 0 && serviceHistory.filter((data) => data.displayStatus === "Scheduled" && moment(data.recordDate).isSameOrAfter(moment()));
    const nextServiceDate = nextServices?.length > 0 && nextServices.slice(-1)[0].recordDate;

    const lastServices = serviceHistory?.length > 0 && serviceHistory.filter((data) => data.displayStatus !== "Scheduled" && data.displayStatus !== "Cancelled");
    const lastServiceDate = lastServices?.length > 0 && lastServices[0].recordDate;

    // -- Render --------------------------------------------------------------------------------------------------------------------------
    return (
        <AuthenticateUser>
            {pageErrors && (
                <div className="text-center">
                    <h2 style={{ color: "red" }}>Error Loading Page</h2>
                    <div className="alert alert-danger">{pageErrors}</div>
                </div>
            )}

            {loadAccount && (
                <PageLoading message="Loading account data, please wait..." />
            )}

            {!pageErrors && portalUser?.isStaff && !portalAccount && (
                <div className="text-center container">
                    <h2 style={{ color: "var(--branding-sunburst-orange)" }}>Select Account</h2>
                    <div style={{ marginTop: "20px" }} className="text-center">Please enter an account number to display the account in the dashboard</div>
                </div>
            )}

            {!pageErrors && pageLoaded && portalAccount && (

                <div className="portal-dashboard">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {!hideClosedDates && (
                                <div className={`${styles.alert} alert alert-secondary d-flex align-items-center justify-content-between`}>
                                    <div>If your business will be closed over the Easter period (other than public holidays) please update your closed dates.</div>

                                    <button
                                        type="button"
                                        id="btnClosedDates"
                                        className="btn btn-sx"
                                        title="Advise dates the business will be closed"
                                        onClick={() => showClosedDatesSet(true)}>
                                        <FontAwesomeIcon icon="calendar-alt" /> Update Closed Dates
                                    </button>

                                    <ClosedDatesRequest
                                        controlSize="sm"
                                        isOpen={showClosedDates}
                                        onCloseClick={() => showClosedDatesSet(false)}
                                    />
                                </div>
                            )}

                            <Row className="form-row">
                                <Col xl={6}>
                                    {/* -- Profile Card ----------------------------------------------------------------------------------- */}
                                    <Card className={styles["card-stretch"]}>
                                        <CardHeader className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <img className={styles.icon} src={accountProfileIcon} alt="Account Profile Icon"></img>
                                                <span className={styles["card-title"]}>Profile</span>
                                            </div>

                                            <NavLink to="profile" title="View profile">
                                                <FontAwesomeIcon icon="eye" />
                                            </NavLink>
                                        </CardHeader>

                                        <CardBody className={styles["card-body"] + " " + styles["card-body-content"]}>
                                            <Row className="mb-1">
                                                <Col xs={4} lg={3}>
                                                    <Label className="col-form-label pt-0">ABN</Label>
                                                </Col>

                                                <Col xs="auto">{portalAccount?.abn}</Col>
                                            </Row>

                                            <Row className="">
                                                <Col xs={4} lg={3}>
                                                    <Label className="col-form-label pt-0">Site Contact</Label>
                                                </Col>

                                                <Col xs="auto">{portalAccount?.siteContact.name}</Col>
                                            </Row>

                                            <Row className="mb-2">
                                                <Col xs={4} lg={3}>
                                                    <Label className="col-form-label pt-0">Site Address</Label>
                                                </Col>

                                                <Col xs="auto">
                                                    <div>{portalAccount?.siteAddress.line1}</div>
                                                    <div>{portalAccount?.siteAddress.line2}</div>
                                                    <div>
                                                        {portalAccount?.siteAddress.suburb} {portalAccount?.siteAddress.state} {portalAccount?.siteAddress.postcode}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mb-1">
                                                <Col xs={4} lg={3}>
                                                    <Label className="col-form-label pt-0">Bin Access Times</Label>
                                                </Col>

                                                <Col xs="auto">
                                                    {!portalAccount?.opening && !portalAccount?.closingTime && parse("<strong>Not provided</strong>")}
                                                    {portalAccount?.openingTime && moment(portalAccount?.openingTime, "HH:mm").format("h:mma")}
                                                    {portalAccount?.closingTime && " to " + moment(portalAccount?.closingTime, "HH:mm").format("h:mma")}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xl={6}>
                                    {/* -- Account Card ----------------------------------------------------------------------------------- */}
                                    <Card className={styles["card-stretch"]}>
                                        <CardHeader className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <img className={styles.icon} src={billingIcon} alt="Billing Icon"></img>
                                                <span className={styles["card-title"]}>Account</span>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <button className="btn btn-success btn-sm me-2" disabled={accBalance <= 0} onClick={handlePayInvoices} title="Pay current balance">
                                                    Pay Now
                                                </button>

                                                <NavLink to="account" title="View account">
                                                    <FontAwesomeIcon icon="eye" />
                                                </NavLink>
                                            </div>
                                        </CardHeader>

                                        <CardBody className={styles["card-body"]}>
                                            {reloadTransactions && (
                                                <>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <img src={Spinner} alt="Loading Account Transactions" />
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <small><em>Loading your account transactions, please wait...</em></small>
                                                    </div>
                                                </>
                                            )}

                                            {!reloadTransactions && accountTransactions?.length === 0 && (
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <small><em>There are currently no account transactions to display</em></small>
                                                </div>
                                            )}

                                            {!reloadTransactions && accountTransactions?.length > 0 && (
                                                <div className={styles["card-body-content"]}>
                                                    <Row className="mb-1">
                                                        <Col xs="auto">
                                                            <Label className="col-form-label pt-0">Current Balance</Label>
                                                        </Col>

                                                        <Col xs="auto">{CurrencyHelper.toCurrencyFormat(accBalance)}</Col>
                                                    </Row>

                                                    <Table className="table-striped table-sm">
                                                        <tbody>
                                                            {accountTransactions &&
                                                                accountTransactions.slice(0, 4).map((data, idx) => {
                                                                    return (
                                                                        <tr key={idx}>
                                                                            <td>{moment(data.transactionDate).format("ddd, DD MMM YYYY")}</td>
                                                                            <td>{data.paymentStatusDisplay}</td>
                                                                            <td style={{ textAlign: "end" }}>{CurrencyHelper.toCurrencyFormat(data.amount)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="form-row">
                                <Col xl={6}>
                                    {/* -- Service Schedule Card -------------------------------------------------------------------------- */}
                                    <Card className={styles["card-stretch"]}>
                                        <CardHeader className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <img className={styles.icon} src={serviceScheduleIcon} alt="Service Schedule Icon"></img>
                                                <span className={styles["card-title"]}>Service Schedule</span>
                                            </div>

                                            <NavLink to="service" title="View service schedule">
                                                <FontAwesomeIcon icon="eye" />
                                            </NavLink>
                                        </CardHeader>

                                        <CardBody className={styles["card-body"]}>
                                            {reloadServiceHistory && (
                                                <>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <img src={Spinner} alt="Loading Service Data" />
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <small><em>Loading your service schedule, please wait...</em></small>
                                                    </div>
                                                </>
                                            )}

                                            {!reloadServiceHistory && serviceHistory?.length === 0 && (
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <small><em>There are currently no service records to display</em></small>
                                                </div>
                                            )}

                                            {!reloadServiceHistory && serviceHistory?.length > 0 && (
                                                <div className={styles["card-body-content"]}>
                                                    <Row className="mb-1">
                                                        <Col xs={4} lg={3}>
                                                            <Label className="col-form-label pt-0">Next Service</Label>
                                                        </Col>

                                                        <Col xs="auto">{nextServiceDate && moment(nextServiceDate).format("ddd, DD MMM YYYY")}</Col>
                                                    </Row>
                                                    <Row className="mb-1">
                                                        <Col xs={4} lg={3}>
                                                            <Label className="col-form-label pt-0">Last Service</Label>
                                                        </Col>

                                                        <Col xs="auto">{lastServiceDate && moment(lastServiceDate).format("ddd, DD MMM YYYY")}</Col>
                                                    </Row>

                                                    <Table className="table-striped table-sm">
                                                        <tbody>
                                                            {!!lastServices && nextServices && nextServices
                                                                .slice(0, 3)
                                                                .map((data, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ width: "25%" }}>{moment(data.recordDate).format("ddd, DD MMM YYYY")}</td>
                                                                            <td style={{ width: "50%", textAlign: "start" }}>{data.description}</td>
                                                                            <td style={{ width: "25%", textAlign: "end" }}>{data.status.replace("Finalized", "Completed")}</td>
                                                                        </tr>
                                                                    );
                                                                })}

                                                            {!!nextServices && lastServices && lastServices
                                                                .slice(0, 3)
                                                                .map((data, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ width: "25%" }}>{moment(data.recordDate).format("ddd, DD MMM YYYY")}</td>
                                                                            <td style={{ width: "50%", textAlign: "start" }}>{data.description}</td>
                                                                            <td style={{ width: "25%", textAlign: "end" }}>{data.status.replace("Finalized", "Completed")}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xl={6}>
                                    {/* -- Portal History Card ---------------------------------------------------------------------------- */}
                                    <Card className={styles["card-stretch"]}>
                                        <CardHeader className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <img className={styles.icon} src={accountHistoryIcon} alt="Account History Icon"></img>
                                                <span className={styles["card-title"]}>Portal History</span>
                                            </div>
                                        </CardHeader>

                                        <CardBody className={styles["card-body"]}>
                                            {reloadPortalHistory && (
                                                <>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <img src={Spinner} alt="Loading Portal History" />
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <small><em>Loading your portal history, please wait...</em></small>
                                                    </div>
                                                </>
                                            )}

                                            {!reloadPortalHistory && portalHistoryData?.length === 0 && (
                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                    <small><em>There are currently no portal history records to display</em></small>
                                                </div>
                                            )}

                                            {!reloadPortalHistory && portalHistoryData && !!portalHistoryData.length && (
                                                <div className={styles["card-body-content"]}>
                                                    <Table className="table-striped table-sm">
                                                        <tbody>
                                                            {portalHistoryData &&
                                                                portalHistoryData.slice(0, 7).map((data, idx) => {
                                                                    return (
                                                                        <tr key={idx}>
                                                                            <td>{moment(data.loggedOn).format("ddd, DD MMM YYYY h:mma")}</td>
                                                                            <td>{data.userName}</td>
                                                                            <td style={{ textAlign: "start" }}>{data.action === "Update" ? `${data.action} ${data.page}` : data.action}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        <div className="ms-4 d-none d-sm-block">
                            {/* <img className="d-block img-fluid" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "10px" }} src={holidayClosure} alt="holidayClosureDates"></img> */}
                            <a href="https://youtu.be/0wfi75T50r8 " target="IntroVideo"><img className="d-block img-fluid" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "10px" }} src={introVideo} alt="Introductory video"></img></a>
                        </div>
                    </div>
                </div>
            )}

            <Payway
                accessToken={accessToken.token}
                axAccountNumber={portalAccount?.axAccountNumber}
                isOpen={showPaywayModal}
                paymentTransactions={paymentTransactions}
                user={portalUser}
                onCloseClick={handlePaymentClose}
                onSuccess={handlePaymentSuccess} />
        </AuthenticateUser>
    );
};
