import * as moment from "moment";
// Don't remove this line - even though it shows it is not used - it is...
// eslint-disable-next-line
import duration from "moment-duration-format";

class DateHelper {
    static ConvertFromUtc(utcDate) {
        let dt;

        if (typeof utcDate === "string") {
            dt = new Date(`${utcDate}Z`);
        }

        else if (this.IsDate(utcDate)) {
            dt = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60 * 1000))
        }

        return dt;
    }

    static Duration(elapsed) {
        const duration = moment.duration(elapsed, "milliseconds").format("d [days] h [hrs] m [mins ago]");
        return duration;
    }

    static Elapsed(dateStart, dateEnd) {
        const start = moment(dateStart);
        const end = moment(dateEnd);
        const elapsed = moment(end).diff(moment(start));
        return elapsed;
    }

    static FormatDate(dateValue, dateFormat) {
        switch (dateFormat) {
            case "LogDate":
                return moment(dateValue).format("[[]ddd, D MMM yyyy hh:mm:ss [GMT]ZZ[]] :");

            case "LongDate":
                return moment(dateValue).format("ddd, D MMM yyyy");

            case "LongDateTime":
                return moment(dateValue).format("ddd, D MMM yyyy h:mm:ss a");

            case "ShortDate":
                return moment(dateValue).format("D/MM/yyyy");

            case "ShortDateTime":
                return moment(dateValue).format("D/MM/yyyy hh:mm:ss");

            case "ShortTime":
                return moment(dateValue).format("h:mm a");

            default:
                throw new Error(`Unknown date format "${dateFormat}".`);
        }
    }

    static IsDate(dt) {
        if (dt instanceof Date && !isNaN(dt.valueOf())) return true;
        return false;
    }

    static Sort(date1, date2, desc=false){
        if (!this.IsDate(date1) || !this.IsDate(date2)) return 0;

        let result = date1.getTime() - date2.getTime();
        if (desc) result *= -1;
        return result;
    }
}

export default DateHelper;