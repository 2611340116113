import { PublicClientApplication, EventType, InteractionType } from "@azure/msal-browser";
import { msalConfig, b2cPolicies, protectedResources, loginRequestUser, loginRequestStaff } from "./authConfig";
import config from "../app.config";

let msalInstance = new PublicClientApplication(msalConfig); //https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications;

/**
* Using the event API, you can register an event callback that will do something when an event is emitted. 
* When registering an event callback in a react component you will need to make sure you do 2 things.
* 1) The callback is registered only once
* 2) The callback is unregistered before the component unmounts.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
*/
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
            if (event.interactionType === InteractionType.Redirect) {
                msalInstance.loginRedirect(b2cPolicies.authorities.resetPassword);
            }

            else if (event.interactionType === InteractionType.Popup) {
                msalInstance.loginPopup(b2cPolicies.authorities.resetPassword)
                    .catch(e => { return; }
                    );
            }
        }
    }

    else if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event?.payload) {
            if (event.payload.idTokenClaims.tfp === config.msalB2cSignInStaffFlow) {
                msalInstance.config.auth.authority = b2cPolicies.authorities.signInStaff.authority;
                msalInstance.config.auth.postLogoutRedirectUri = config.logoutUrl + config.staffPath;
            }
            /**
             * We need to reject id tokens that were not issued with the default sign-in policy.
             * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            // if (event.payload.idTokenClaims.tfp === b2cPolicies.names.resetPassword) {
            //     window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
            //     return msalInstance.logoutRedirect();
            // } else if (event.payload.idTokenClaims.tfp === b2cPolicies.names.editProfile) {
            //     window.alert("Profile has been edited successfully. \nPlease sign-in again.");
            //     return msalInstance.logoutRedirect();
            // }
        }
    }
});

export const getMsalInstance = () => msalInstance;

export const login = () => {
    msalInstance.config.auth.authority = b2cPolicies.authorities.signInUser.authority;
    msalInstance.loginRedirect(loginRequestUser);
}

export const loginAsStaff = () => {
    msalInstance.config.auth.authority = b2cPolicies.authorities.signInStaff.authority;
    msalInstance.loginRedirect(loginRequestStaff);
}

export const getAccessToken = async (accessToken, isStaff) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length === 0) return null;

    const _account = accounts[0];

    let _accessToken = { ...accessToken };

    if (_accessToken.token == null || _accessToken.expires <= Date()) {
        let response;
        const _scopes = isStaff ? protectedResources.portalApiStaff.scopes.access_staff : protectedResources.portalApiUser.scopes.access_user;

        try {
            response = await msalInstance.acquireTokenSilent({
                account: _account,
                scopes: _scopes
            });
        }

        catch (ex) {
            if (ex.Name === "InteractionRequiredAuthError") {
                response = await msalInstance.acquireTokenPopup({
                    account: _account,
                    scopes: _scopes
                });
            }
        }

        if (response?.accessToken) {
            // The expiration date provided in the response is represented as an epoch time...
            const expiryDt = new Date(0).setUTCSeconds((response.idTokenClaims.exp));
            _accessToken = { token: response.accessToken, expires: expiryDt };
        }

        else if (response?.idTokenClaims?.idp_access_token) {
            // The expiration date provided in the response is represented as an epoch time...
            const expiryDt = new Date(0).setUTCSeconds((response.idTokenClaims.exp));
            _accessToken = { token: response.idTokenClaims.idp_access_token, expires: expiryDt };
        }

        else {
            console.warn("Get Access Token: ", "Response does not contain a token")
        }
    }

    return _accessToken;
}

export const getLoginAccount = () => {
    const accounts = msalInstance.getAllAccounts();
    return accounts?.length > 0 ? accounts[0] : null;
}

// See node_modules\@azure\msal-react\dist\msal-react.cjs.development.js...
export const isAuthenticated = () => {
    const accounts = msalInstance.getAllAccounts();
    return accounts?.length > 0 ? true : false;
}
