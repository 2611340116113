import React, { Fragment, useState } from "react"
// The ReactStrap Inpot control defaults to "form-control" and does not provide a method to add classes which is required by app...
import { FormGroup, InputGroup, InputGroupText, Label, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaskedInput from "react-text-mask";

import DatePicker, { registerLocale } from "react-datepicker";
import enAU from "date-fns/locale/en-AU";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("enAU", enAU);

const InputComponent = ({ id, onChange, onFocus, ...args }) => {
    const error = args.error ?? null;
    const name = args.name ? args.name : id;
    const readOnly = args.readOnly ? args.readOnly : false;
    const placeholder = args.placeholder && !readOnly ? args.placeholder : null;
    const tooltip = args.tooltip ?? null;
    const ttPlacement = "auto";
    const type = args.type ?? "text";
    const value = args.value ? args.value : "";

    const endDate = args.endDate ?? null;
    const minDate = args.minDate ?? null;
    const startDate = args.startDate ?? null;
    
    const [tooltipOpen, tooltipOpenSet] = useState(false);

    let groupClass = args.groupClass ?? "";
    let controlClass = args?.controlClass ? `form-control ${args.controlClass}` : "form-control";
    let labelStyle = { fontSize: "1rem" };

    if (args.size) {
        switch (args.size) {
            case "sm":
                groupClass = `form-group-sm ${groupClass}`
                controlClass = `${controlClass} form-control-sm`;
                labelStyle = { fontSize: "0.85rem" };
                break;

            case "lg":
                groupClass = `form-group-lg ${groupClass}`
                controlClass = `${controlClass} form-control-lg`;
                labelStyle = { fontSize: "1.15rem" };
                break;

            default:
                groupClass = `form-group-md ${groupClass}`
                controlClass = `${controlClass} form-control-md`;
                break;
        }
    }

    let labelClass = args.labelClass || "label-sx";

    if (args?.mandatory){
        labelClass = `${labelClass} mandatory`;
    }
    else if (args?.oneOf){
        labelClass = `${labelClass} one-of`;
        }

    const label = args.label
        ? <Label htmlFor={id} className={labelClass} style={labelStyle}>{args.label}</Label>
        : null;

    // If there is an error and also an "append" input group then the error must be included in the InputGroup otherwise it is included in the Input...
    let inputError = null;
    let inputGroupError = null;

    if (args?.error) {
        if (args?.append) {
            inputGroupError = <div className="alert alert-danger alert-error" role="alert" style={{ fontStyle: "italic", fontSize: "0.85rem", marginTop: "-5px", zIndex: "10" }}>{error}</div>;
        }
        else {
            inputError = <div className="alert alert-danger alert-error" role="alert" style={{ fontStyle: "italic", fontSize: "0.85rem", marginTop: "-5px", zIndex: "10" }}>{error}</div>;
        }
    }

    let input = null;

    switch (type) {
        case "currency":
            input = (
                <Fragment>
                    <InputGroup size={args.size ?? "md"} style={{ flexWrap: "nowrap", width: "100%" }}>
                        <InputGroupText>$</InputGroupText>

                        {tooltip &&
                            <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                        }

                        <input
                            type="text"
                            id={id}
                            name={name}
                            className={controlClass}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            value={value}
                            required={args?.mandatory ? true : false}
                            style={{zIndex: "100"}}
                            onFocus={onFocus ? (evt) => onFocus(evt) : null}
                            onChange={(evt) => onChange(evt)}
                            onBlur={args?.onBlur}
                            disabled={args?.disabled} />
                    </InputGroup>

                    {inputError}

                </Fragment>
            )
            break;

        case "date":
            input = (
                <Fragment>
                    <InputGroup size={args.size ?? "md"} style={{ flexWrap: "nowrap", width: "100%" }}>

                        {tooltip &&
                            <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                        }

                        {args?.selectsStart &&
                            <DatePicker
                                id={id}
                                name={name}
                                className={controlClass}
                                placeholderText='mm/dd/yyyy'
                                selected={value}
                                dateFormat="EEE, d MMM yyyy"
                                locale="enAU"
                                required={args?.mandatory ? true : false}
                                style={{ zIndex: "100" }}
                                onFocus={(evt) => onFocus && onFocus(evt)}
                                onChange={(date) => onChange(date, id)}
                                minDate={minDate}
                                startDate={startDate}
                                endDate={endDate}
                                selectsStart />
                        }


                        {args?.selectsEnd &&
                            <DatePicker
                                id={id}
                                name={name}
                                className={controlClass}
                                placeholderText='mm/dd/yyyy'
                                selected={value}
                                dateFormat="EEE, d MMM yyyy"
                                locale="enAU"
                                required={args?.mandatory ? true : false}
                                style={{ zIndex: "100" }}
                                onFocus={(evt) => onFocus && onFocus(evt)}
                                onChange={(date) => onChange(date, id)}
                                minDate={minDate}
                                startDate={startDate}
                                endDate={endDate}
                                selectsEnd />
                        }

                        {!args?.selectsStart && !args?.selectsEnd &&
                            <DatePicker
                                id={id}
                                name={name}
                                className={controlClass}
                                placeholderText='mm/dd/yyyy'
                                selected={value}
                                dateFormat="EEE, d MMM yyyy"
                                locale="enAU"
                                required={args?.mandatory ? true : false}
                                style={{ zIndex: "100" }}
                                onFocus={(evt) => onFocus && onFocus(evt)}
                                onChange={(date) => onChange(date, id)}
                                minDate={minDate}
                                startDate={startDate} />
                        }

                        <InputGroupText><FontAwesomeIcon icon="calendar-alt" /></InputGroupText>
                    </InputGroup>
                    {inputError}
                </Fragment>
            )
            break;

        case "time":
            input = (
                <Fragment>
                    <InputGroup size={args.size ?? "md"} style={{ flexWrap: "nowrap", width: "100%", zIndex: "100" }}>
                        <InputGroupText><FontAwesomeIcon icon="calendar-alt" /></InputGroupText>

                        {tooltip &&
                            <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                        }

                        <DatePicker
                            id={id}
                            name={name}
                            className={controlClass}
                            selected={value}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Open"
                            required={args?.mandatory ? true : false}
                            style={{zIndex: "100"}}
                            onFocus={(evt) => onFocus && onFocus(evt)}
                            onChange={(date) => onChange(date, id)} />
                    </InputGroup>
                    {inputError}
                </Fragment>
            )
            break;

        case "masked":
            input = (
                <Fragment>
                    {tooltip &&
                        <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                    }

                    <MaskedInput
                        id={id}
                        name={name}
                        className={controlClass}
                        placeholder={placeholder}
                        mask={args.mask}
                        readOnly={readOnly}
                        value={value}
                        required={args?.mandatory ? true : false}
                        style={{zIndex: "100"}}
                        onFocus={(evt) => onFocus && onFocus(evt)}
                        onChange={(evt) => onChange(evt)}
                        onBlur={args?.onBlur} />
                    {inputError}
                </Fragment>
            )
            break;

        case "textarea":
            input = (
                <Fragment>
                    {tooltip &&
                        <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                    }

                    <textarea
                        id={id}
                        name={name}
                        className={controlClass}
                        rows={args.rows}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        value={value}
                        required={args?.mandatory ? true : false}
                        style={{ zIndex: "100", position: "relative" }}
                        onFocus={(evt) => onFocus && onFocus(evt)}
                        onChange={(evt) => onChange(evt)}
                        onBlur={args?.onBlur}
                    />
                    {inputError}
                </Fragment>
            )
            break;

        case "select":
            input = (
                <Fragment>
                    {tooltip &&
                        <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                    }

                    <select
                        id={id}
                        name={name}
                        className={controlClass}
                        placeholder={placeholder}
                        disabled={readOnly}
                        value={value}
                        required={args?.mandatory ? true : false}
                        style={{zIndex: "100"}}
                        onFocus={(evt) => onFocus && onFocus(evt)}
                        onChange={(evt) => onChange(evt)}
                        onBlur={args?.onBlur} >
                        {args.children}
                    </select>
                    {inputError}
                </Fragment>
            )
            break;

        default:
            input = (
                <Fragment>
                    {tooltip &&
                        <Tooltip placement={ttPlacement} isOpen={tooltipOpen} target={id} toggle={() => tooltipOpenSet(!tooltipOpen)}>{tooltip}</Tooltip>
                    }

                    <input
                        type={type}
                        id={id}
                        name={name}
                        className={controlClass}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        value={value}
                        required={args?.mandatory ? true : false}
                        style={{zIndex: "100"}}
                        onFocus={(evt) => onFocus && onFocus(evt)}
                        onChange={(evt) => onChange(evt)}
                        onBlur={args?.onBlur} />

                    {inputError}
                    {args.children}
                </Fragment>
            )
            break;
    }

    if (args?.prepend || args?.append) {
        input = (
            <InputGroup size={args.size ?? "md"}>
                {args?.prepend && args.prepend}
                {input}
                {args?.append && args.append}
                {inputGroupError}
            </InputGroup>
        )
    }

    return (
        <FormGroup className={groupClass}>
            {label}
            {input}
        </FormGroup>
    );
}

export default InputComponent;
