import React from "react";
import logo from "../../Images/SDX-banner-logo.png";
import "../../assets/PageLoading.scss";

const PageLoading = ({ message }) => {
    return (
        <section className="container-fluid centred">
            <div className="page-loading">
                <div className="content text-center">
                    <img
                        src={logo}
                        style={{ width: "200px" }}
                        alt="Shred-X Logo" />

                    <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: "100%", backgroundColor: "var(--branding-sunburst-orange)" }}
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100" />
                    </div>

                    <div>
                        <em>{message}</em>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageLoading;