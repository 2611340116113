import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/base.scss";

import "jquery/dist/jquery.js"
import 'bootstrap/dist/js/bootstrap';

import { App } from './App';

import * as serviceWorker from './serviceWorker';
import * as authHelper from "./Authentication/authenticationHelper";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = authHelper.getMsalInstance();

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance} >
            <Router>
                <App />
            </Router>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
