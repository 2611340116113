import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader } from "reactstrap";

import Input from "../common/Input.Component";
import useInputValidator, { isDate } from "../../Hooks/useInputValidator";

import styles from "./JobReportDates.module.scss";

const JobReportDates = ({ report, isOpen, onOkClick, onCloseClick, ...args }) => {
    // -- Page State -----------------------------------------------------------------------------------------------------------------------
    const [formIsValid, formIsValidSet] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const { value: dateFromValue, setValue: dateFromSet, hasError: dateFromHasError, errorText: dateFromErrorText, valueChangedHandler: dateFromChangedHandler, inputBlurHandler: dateFromBlurHandler, reset: dateFromReset } = useInputValidator(isDate, "From Date must not be empty.");
    // eslint-disable-next-line no-unused-vars
    const { value: dateToValue, setValue: dateToSet, hasError: dateToHasError, errorText: dateToErrorText, valueChangedHandler: dateToChangedHandler, inputBlurHandler: dateToBlurHandler, reset: dateToReset } = useInputValidator(() => true);

    useEffect(() => {
        formIsValidSet(!dateFromHasError && !dateToHasError);
    }, [dateFromHasError, dateToHasError]);

    useEffect(() => {
        if (isOpen) {
            dateFromReset();
            dateToReset();
        }
    }, [dateFromReset, isOpen, dateToReset]);

    const handleCloseClick = () => {
        onCloseClick();
    };

    const handleOkClick = () => {
        const request = {
            dateFrom: dateFromValue,
            dateTo: dateToValue,
        };
        onOkClick(request);
    }

    const [controlSize] = useState(args?.controlSize ?? "md")

    let reportName;

    switch (report) {
        case "JobDocketReport":
            reportName = "Job Dockets";
            break;

        case "CodReport":
            reportName = "Certificates of Destruction";
            break;

        case "EcoReport":
            reportName = "Eco Statement";
            break;

        //no default
    }

    return (
        <Modal className="modal-lg" backdrop="static" isOpen={isOpen} toggle={handleCloseClick}>
            <ModalHeader>{reportName}</ModalHeader>

            <ModalBody>
                <Card className="mb-0">
                    <CardHeader>Report Dates</CardHeader>

                    <CardBody className={styles["card-body"]}>
                        <div className={styles.jobDates}>
                            <label className={styles.label1}>Include jobs from</label>
                            <Input type="date" id="DateFrom" controlClass={styles.date1} size={controlSize} value={dateFromValue} onChange={(date) => dateFromSet(date)}></Input>

                            <label className={styles.label2}>to</label>
                            <Input type="date" id="DateTo" controlClass={styles.date2} size={controlSize} value={dateToValue} onChange={(date) => dateToSet(date)}></Input>

                            <em className={styles.comment}><strong>Note:</strong> Leave the 2nd date blank to include all invoiced/completed jobs until today.</em>
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>

            <ModalFooter className={styles["service-button-group"]}>
                <button
                    type="button"
                    className="btn btn-success btn-sm"
                    title="Submit the closed dates"
                    disabled={!formIsValid}
                    onClick={handleOkClick} >
                    Submit
                </button>

                <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    title="Return to the Service Schedule"
                    onClick={handleCloseClick}>
                    Cancel
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default JobReportDates;