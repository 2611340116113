import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./routes/privateRoute";

import { RecoilRoot } from "recoil";

import { PublicPage } from "./components/Registration/Login.Page";
import { RegisterPage } from "./components/Registration/Register.Page";
import { DashboardPage } from "./components/Dashboard/Dashboard.Page";
import ProfilePage from "./components/Profile/Profile.Page";
import { BillingPage } from "./components/Billing/Billing.Page";
import { ServicePage } from "./components/Service/Service.Page";
import { ServiceCalendarPage } from "./components/Service/ServiceSchedule.Page";
import ContactUsPage from "./components/ContactUs/ContactUs.Page";
import PrivacyPolicyPage from "./components/General/PrivacyPolicy.Page";
import TermsOfUsePage from "./components/General/TermsofUse.Page";
import GiveAway from "./components/General/GiveAway.Page";

// Use ToastContainer in conjunction with message.js to display toasts...
// Documentation: https://github.com/fkhadra/react-toastify
import { ToastContainer } from 'react-toastify';

import './index.css';
import "./assets/scss/base.scss";

import config from "../src/app.config"

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import {
    // General 
    faAngleDoubleLeft, faAngleDoubleRight, faCheckSquare, faCog, faDownload, faEnvelopeOpenText, faTrash, faSearch, faFilter, faSortUp, faSortDown,
    faWindowClose, faDoorOpen, faDoorClosed, faChevronCircleDown, faChevronCircleUp, faEdit, faSave, faClock, faCreditCard, faChevronRight, faChevronDown, faPlus, faEye,
    // Navigation
    faBook, faCalendarAlt, faCommentAlt, faCommentDots, faDollarSign, faTachometerAlt, faUser,
    // Notifications
    faCheckCircle, faInfoCircle, faExclamationCircle, faMinusCircle, faPlusCircle, faQuestionCircle, faTimesCircle,
    // Reports
    faFileAlt,
    // Service
    faCertificate, faClipboard, faFileExcel, faLeaf,
    // Site Header
    faBars, faRandom
} from '@fortawesome/free-solid-svg-icons';

library.add(
    // General 
    faAngleDoubleLeft, faAngleDoubleRight, faCheckSquare, faCog, faDownload, faEnvelopeOpenText, faTrash, faSearch, faFilter, faSortUp, faSortDown,
    faWindowClose, faDoorOpen, faDoorClosed, faChevronCircleDown, faChevronCircleUp, faEdit, faSave, faClock, faCreditCard, faChevronRight, faChevronDown, faPlus, faEye,
    // Navigation
    faBook, faCalendarAlt, faCommentAlt, faCommentDots, faDollarSign, faTachometerAlt, faUser,
    // Notifications
    faCheckCircle, faInfoCircle, faExclamationCircle, faMinusCircle, faPlusCircle, faQuestionCircle, faTimesCircle,
    // Reports
    faFileAlt,
    // Service
    faCertificate, faClipboard, faFileExcel, faLeaf,
    // Site Header
    faBars, faRandom,
    // Brand Icons
    fab,
);

console.log("%c=============================================", 'color: #008000')
console.log(`%cApplication: %cShred-X Customer Portal Client`, "color: #ff0000", "color: #e68a00");
console.log(`%cEnvironment: %c${config.environment}`, "color: #ff0000", "color: #e68a00");
console.log(`%cVersion    : %c${config.version}`, "color: #ff0000", "color: #e68a00");
console.log("%c=============================================", 'color: #008000')

export const App = () => {
    return (
        <RecoilRoot>
            <Switch>
                <PrivateRoute path="/dashboard" component={DashboardPage} />
                <PrivateRoute path="/profile" component={ProfilePage} />
                <PrivateRoute path="/account" component={BillingPage} />
                <PrivateRoute path="/service" component={ServicePage} />
                <PrivateRoute path="/scheduled-jobs" component={ServiceCalendarPage} />
                <PrivateRoute path="/contact" component={ContactUsPage} />
                <PrivateRoute path="/logout" />

                <Route path="/register/:registrationId" component={RegisterPage} />
                <Route path="/privacypolicy" component={PrivacyPolicyPage} />
                <Route path="/termsofuse" component={TermsOfUsePage} />
                <Route path="/giveaway" component={GiveAway} />
                <Route path="/" component={PublicPage} />
            </Switch>

            <ToastContainer />
        </RecoilRoot>
    );
}
