import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../common/Input.Component";

import styles from "./ServiceFilter.module.scss";

const ServiceFilter = ({ isOpen, filterDateFrom, filterDateTo, filterStatus, applyFilter, closeFilter, ...args }) => {
   const [dateFrom, dateFromSet] = useState(filterDateFrom)
   const [dateTo, dateToSet] = useState(filterDateTo)
   const [status, statusSet] = useState(filterStatus)

   const [controlSize] = useState(args?.controlSize ?? "md")

   const handleClearFilter = () => {
      dateFromSet(null)
      dateToSet(null)
      statusSet(null)
   };

   // Set the size of the various controls used on the page...
   const labelClass = `${styles["filter-label"]}`
   const groupClass = "d-flex align-items-center"
   const controlClass = "form-control w-100"

   return (
      <Modal size="lg" backdrop="static" isOpen={isOpen} toggle={closeFilter}>
         <ModalHeader>Filter Service Records</ModalHeader>

         <ModalBody>
            <Input id="DateFrom" type="date" groupClass={groupClass} label="From" labelClass={labelClass} size={controlSize} controlClass={controlClass} value={dateFrom} onChange={(date) => dateFromSet(date)}></Input>
            <Input id="DateTo" type="date" groupClass={groupClass} label="To" labelClass={labelClass} size={controlSize} controlClass={controlClass} value={dateTo} onChange={(date) => dateToSet(date)}></Input>
            <Input type="select" groupClass={groupClass} label="Status" labelClass={labelClass} value={status} size={controlSize} onChange={(e) => statusSet(e.target.value)}>
               <option value="">Choose...</option>
               <option value="Completed">Completed</option>
               <option value="Invoiced">Invoiced</option>
               <option value="Scheduled">Scheduled</option>
            </Input>
         </ModalBody>

         <ModalFooter>
            <button type="button" className="btn btn-sx" title="Filter service records" onClick={() => applyFilter({ dateFrom, dateTo, status })}>
               <FontAwesomeIcon icon="filter" title="Filter service records" />
               &nbsp;Apply
            </button>
            <Button color="secondary" onClick={closeFilter}>
               <FontAwesomeIcon icon="door-closed" title="Discard any changes and close the filter" />
               &nbsp;Cancel
            </Button>
            <button type="button" className="btn btn-danger" title="Clear all filters" onClick={() => handleClearFilter()}>
               <FontAwesomeIcon icon="trash" title="Clear all filters" />
               &nbsp;Reset
            </button>
         </ModalFooter>
      </Modal>
   );
};

export default ServiceFilter;
